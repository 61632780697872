import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    //分页获取产品列表
    getArticlePage({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/home/article/page/search", params, this);
        return promise;
    },
    getHomeArticle({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/home/article", params, this);
        return promise;
    },
    homeArticleEdit({ state, commit }, params) {
        let promise = httpV2.put("/b2c/v1.0/home/article", params, this);
        return promise;
    },
    deleteArticle({ state, commit }, id) {
        let promise = httpV2.delete("/b2c/v1.0/home/article/" + id, {}, this);
        return promise;
    },
};

export default {
    getters,
    state,
    mutations,
    actions
};