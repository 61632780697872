import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
  //分页获取列表
  getSourceUrlPage({ state, commit }, params) {
    let promise = httpV2.get2("/b2c/v1.0/source/url/page/search", params, this);
    return promise;
  },

  createSourceUrl({ state, commit }, params) {
    let promise = httpV2.post("/b2c/v1.0/source/url/add", params, this);
    return promise;
  },

  updateSourceUrl({ state, commit }, params) {
    let promise = httpV2.put("/b2c/v1.0/source/url/update/"+params.sourceUrlId, params, this);
    return promise;
  },
  
  deleteSourceUrl({ state, commit }, id) {
    let promise = httpV2.delete("/b2c/v1.0/source/url/delete/"+id, {}, this);
    return promise;
  },
  getSourceUrl({ state, commit }, id) {
    let promise = httpV2.get2("/b2c/v1.0/source/url/"+id, {}, this);
    return promise;
  },

  getFixedUrl({ state, commit }, params) {
    let promise = httpV2.get2("/b2c/v1.0/source/url/getFixedUrl", params, this);
    return promise;
  }

};

export default {
  getters,
  state,
  mutations,
  actions
};
