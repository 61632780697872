import httpV2 from '@/utils/httpV2';

const state = {};
const getters = {};

const mutations = {};

const actions = {
    //帮助中心Plus删除
    deleteHelpCenterPlus({ state, commit }, helpCenterTypeId) {
        return httpV2.delete('/b2c/v1.0/help_center_type/' + helpCenterTypeId, {}, this);
    },
    //帮助中心Plus添加
    addOrUpdateHelpCenterPlus({ state, commit }, params) {
        return httpV2.post('/b2c/v1.0/help_center_type/plusAddOrUpdate', params, {}, this);
    },
    //帮助中心plus查询
    getTreeLevelByParams({ state, commit }, params) {
        return httpV2.get('/b2c/v1.0/help_center_type/tree/param', params, {}, this);
    },
    //获取帮助中心Plus菜单
    getTreeLevel({ state, commit }, params) {
        return httpV2.get('/b2c/v1.0/help_center_type/tree/level', params, this);
    },

    //获取下拉框菜单
    getHelpCenterCollectTree({ state, commit }, helpCenterTypeId) {
        return httpV2.get('/b2c/v1.0/help_center_type/collect/tree/' + helpCenterTypeId, {}, this);
    },
    //获取BannerList
    getHelpCenterTypes({ state, commit }, params) {
        return httpV2.get('/b2c/v1.0/help_center_type/list/tree', {}, this);
    },
    //获取帮助中心list
    getHelpCenter({ state, commit }, params) {
        return httpV2.get('/b2c/v1.0/help_center/list', params, this);
    },
    //新增或编辑帮助中心类别
    addOrUpdateHelpCenterType({ state, commit }, params) {
        return httpV2.post('/b2c/v1.0/help_center_type/addOrUpdate', params, this);
    },
    getHelpCenterType({ state, commit }, helpCenterTypeId) {
        return httpV2.get('/b2c/v1.0/help_center_type/' + helpCenterTypeId, {}, this);
    },
    //删除帮助中心类别
    deleteHelpCenterType({ state, commit }, helpCenterTypeId) {
        return httpV2.delete('/b2c/v1.0/help_center_type/' + helpCenterTypeId, {}, this);
    },
    //新增或编辑帮助中心
    addOrUpdateHelpCenter({ state, commit }, params) {
        return httpV2.post('/b2c/v1.0/help_center/addOrUpdate', params, this);
    },
    //获取帮助中心详情
    getHelpCenterDetail({ state, commit }, params) {
        return httpV2.get('/b2c/v1.0/help_center', params, this);
    },
    //删除帮助中心
    deleteHelpCenter({ state, commit }, helpCenterId) {
        return httpV2.delete('/b2c/v1.0/help_center/' + helpCenterId, {}, this);
    }
};

export default {
    getters,
    state,
    mutations,
    actions
};