import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import VueI18n from 'vue-i18n';
import i18n from './i18n/i18n';
import { messages } from './components/common/i18n';
import 'element-ui/lib/theme-chalk/index.css'; // 默认主题
// import './assets/css/theme-green/index.css'; // 浅绿色主题
import './assets/css/icon.css';
import './components/common/directives';
import 'babel-polyfill';
import Config from '@/utils/config';
import Cache from '@/utils/cache';
import md5 from 'js-md5';
//import oidcVarible from '@/api/oidc_varible.js'
import oidcVarible from '@/store/oidc_varible.js';
import common from '@/utils/common';
import dict from '@/utils/dictionaryUtil';
import Bus from '@/utils/bus'
import TenantBrand from '@/utils/tenantBrand'
import * as filters from '@/filters/index';
import './assets/icon/iconfont.css';

Vue.prototype.$bus = Bus
Vue.prototype.$tenantBrand = TenantBrand
Vue.prototype.oidcVarible = oidcVarible;
Vue.prototype.$common = common;
Vue.prototype.$dict = dict;
Vue.prototype.$md5 = md5;
Vue.config.productionTip = false;
// Vue.use(VueI18n);
Vue.use(ElementUI, {
    size: 'small',
    i18n: (key, value) => i18n.t(key, value)
});
// const i18n = new VueI18n({
//     locale: 'zh',
//     messages
// });

//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
    // 验证拦截是否有用户信息
    if (to.name != 'login' && to.name != 'signin-oidc' && to.name != 'signin') {
        oidcVarible.mgr.getUser().then(function(user) {
            if (user) {
                Cache.set(Config.userInfo, user.profile);
                next();
            } else {
                Cache.setSession('query', to.query);
                window.location = '/';
            }
        });
    } else {
        next();
    }
});

Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
});

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');

/**
 * 生成随机文件名
 * @param len
 * @returns {string}
 */
Vue.prototype.randomString = function(len) {
    len = len || 32;
    let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
    let maxPos = chars.length;
    let pwd = '';
    for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
};
/**
 * 禁止输入特殊符号
 * @param filename
 * @returns {string}
 */
Vue.prototype.validForbid = function(value, number = 255) {
    value = value.replace(/[`$%^&:"|/;%……&|：“”、；‘’、]/g, '');
    if (value.length >= number) {
        this.$message({
            type: "warning",
            message: `输入内容不能超过${number}个字符`
        });
    }
    return value;
}

/**
 * 获取文件后缀名
 * @param filename
 * @returns {string}
 */
Vue.prototype.getSuffix = function(filename) {
    let pos = filename.lastIndexOf('.');
    let suffix = '';
    if (pos != -1) {
        suffix = filename.substring(pos);
    }
    return suffix;
};

/**
 * 解决Number toFixed四舍五入问题
 * @param n
 * @returns {string}
 */
Number.prototype.toFixed = function(n) {
    if (n > 20 || n < 0) {
        throw new RangeError('toFixed() digits argument must be between 0 and 20');
    }
    const number = this;
    if (isNaN(number) || number >= Math.pow(10, 21)) {
        return number.toString();
    }
    if (typeof(n) == 'undefined' || n == 0) {
        return (Math.round(number)).toString();
    }
    let result = number.toString();
    const arr = result.split('.');
    // 整数的情况
    if (arr.length < 2) {
        result += '.';
        for (let i = 0; i < n; i += 1) {
            result += '0';
        }
        return result;
    }
    const integer = arr[0];
    const decimal = arr[1];
    if (decimal.length == n) {
        return result;
    }
    if (decimal.length < n) {
        for (let i = 0; i < n - decimal.length; i += 1) {
            result += '0';
        }
        return result;
    }
    result = integer + '.' + decimal.substr(0, n);
    const last = decimal.substr(n, 1);
    // 四舍五入，转换为整数再处理，避免浮点数精度的损失  正数+1 负数-1
    if (parseInt(last, 10) >= 5) {
        const x = Math.pow(10, n);
        result = (Math.round((parseFloat(result) * x)) + (parseFloat(result) > 0 ? 1 : -1)) / x;
        result = result.toFixed(n);
    }
    return result;
};