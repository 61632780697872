import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
  //分页获取产品列表
  getLoginLogPage({ state, commit }, params) {
    let promise = httpV2.get2("/b2c/v1.0/login/log/page/search", params, this);
    return promise;
  },

};

export default {
  getters,
  state,
  mutations,
  actions
};
