import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
	//获取List
	getCategoryAssociation({ state, commit }, params) {
	    let res = httpV2.get2('/arc/v1.0/goods/classify/related/privately/page/search', params, this);
	    return res;
	},
	//获取tree
	getClassTree({ state, commit }, params) {
	    let res = httpV2.get('/arc/v1.0/goods/classify/tree', params, this);
	    return res;
	},
	//新增关联类目关联表
	postBatch({ state, commit }, params) {
	    let res = httpV2.post('/arc/v1.0/goods/classify/related/privately/insert/batch', params, this);
	    return res;
	},
	//新增主类目关联表
	postPrivately({ state, commit }, params) {
	    let res = httpV2.post('/arc/v1.0/goods/classify/related/privately', params, this);
	    return res;
	},
	//修改状态
	statusPUT({ state, commit }, params) {
	    let res = httpV2.put(`/arc/v1.0/goods/classify/related/privately/${params.id}/status/${params.status}`, params, this);
	    return res;
	},
	//删除
	deletePrivately({ state, commit }, params) {
	    let res = httpV2.delete(`/arc/v1.0/goods/classify/related/privately/${params.id}`, params, this);
	    return res;
	},
		
};

export default {
    getters,
    state,
    mutations,
    actions
};
