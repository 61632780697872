import en from './en';
import cn from './cn';
import es from './es';
import it from './it';
import pl from './pl';
import de from './de';
import nl from './nl';
import fr from './fr';
import pt from './pt';
import da from './da';
import nb from './nb';
const message = {
    'zh-CN': cn,    //中
    'en': en,       //英
    'es': es,       //西
    'it': it,       //意
    'pl': pl,       //波
    'de': de,       //德
    'nl': nl,       //荷
    'fr': fr,       //法
    'pt': pt,       //葡
    'da':da,        //丹
    'nb':nb,    //挪
}

export default message;


