export default {

	//成功码
	SUCCESS_CODE: 0,
	localLangage: 'Aigo_APP_backOffice_language', //本地化语言 
	ossBaseUrl: 'https://aigo-oss-euapp-test.oss-cn-shenzhen.aliyuncs.com/',
	accessToken: "b2b_access_token",
	currentTenant: "currentTenant", //当前租户,
	tenantList: "tenantList", // 租户列表
	tanantMenu: "tanantMenu", //租户菜单
	ossSignature: "ossSignature", // oss签名
	appId: 7, //应用id
	userInfo: "userInfo",
	priceUnit: 'priceUnit', //价格单位
	permission:"permission",//按钮权限
	lang: "lang",
	tenant_idMall:'1000701002',
	AigoStar:'1000701000',
	Nobleza:'1000701001',
	AigoSmart:'1000701002',
 	TaylorSwoden:'1000701003',
	AigoSmartNonSelf:'1000701005',
	module_code:{//页面sdp编码
		goods:'b2c.goods.index',
		classAdmin:'b2c.classAdmin.index',
		categoryAssociation:'b2c.categoryAssociation.index',
		banners:'b2c.banners.index',
		variant:'b2c.variant.index',
		coupon:'b2c.coupon.index',
		promotion:'b2c.promotion.index',
		distribution:'b2c.distribution.index',
		bannerAdmin:'b2c.bannerAdmin.index'
	},
	copyIP: [{
			code: "CN",
			name: "中国",
			val: "aigostarshop.es"
		},
		{
			code: "GB",
			name: "英国",
			val: "aigostarshop.uk"
		},
		{
			code: "DE",
			name: "德国",
			val: "aigostarshop.de"
		},
		{
			code: "PL",
			name: "波兰",
			val: "aigostarshop.pl"
		},
		{
			code: "IT",
			name: "意大利",
			val: "aigostarshop.it"
		},
		{
			code: "PT",
			name: "葡萄牙",
			val: ""
		},
		{
			code: "FR",
			name: "法国",
			val: "aigostarshop.fr"
		},
		{
			code: "NL",
			name: "荷兰",
			val: "aigostar-shop.nl"
		},
		{
			code: "ES",
			name: "西班牙",
			val: "aigostarshop.es"
		},
		{
			code: "US",
			name: "美国",
			val: "aigostarstore.com"
		}
	],
	copyNIP: [{
			code: "CN",
			name: "中国",
			val: ""
		},
		{
			code: "GB",
			name: "英国",
			val: "noblezashop.uk"
		},
		{
			code: "DE",
			name: "德国",
			val: "noblezashop.de"
		},
		{
			code: "PL",
			name: "波兰",
			val: "noblezashop.pl"
		},
		{
			code: "IT",
			name: "意大利",
			val: "noblezashop.it"
		},
		{
			code: "PT",
			name: "葡萄牙",
			val: "noblezashop.pt"
		},
		{
			code: "FR",
			name: "法国",
			val: "noblezashop.fr"
		},
		{
			code: "NL",
			name: "荷兰",
			val: "noblezashop.nl"
		},
		{
			code: "ES",
			name: "西班牙",
			val: "noblezashop.es"
		},
		{
			code: "US",
			name: "美国",
			val: "noblezashop.us"
		}
	],
	copyTSIP: [{
			code: "CN",
			name: "中国",
			val: ""
		},
		{
			code: "GB",
			name: "英国",
			val: "taylorswoden.uk"
		},
		{
			code: "DE",
			name: "德国",
			val: "taylorswoden.de"
		},
		{
			code: "PL",
			name: "波兰",
			val: "taylorswoden.pl"
		},
		{
			code: "IT",
			name: "意大利",
			val: "taylorswoden.it"
		},
		{
			code: "PT",
			name: "葡萄牙",
			val: "taylorswoden.pt"
		},
		{
			code: "FR",
			name: "法国",
			val: "taylorswoden.fr"
		},
		{
			code: "NL",
			name: "荷兰",
			val: "taylorswoden.nl"
		},
		{
			code: "ES",
			name: "西班牙",
			val: "taylorswoden.es"
		},
		{
			code: "US",
			name: "美国",
			val: "taylorswoden.us"
		}
	],
	languageList: [{
		id: "1",
		name: '英语',
		code: "gb",
		value: '',
	}, {
		id: "2",
		name: '西班牙语',
		code: "es",
		value: '',
	}, {
		id: "3",
		name: '意大利语',
		code: "it",
		value: '',
	}, {
		id: "4",
		name: '波兰语',
		code: "pl",
		value: '',
	}, {
		id: "5",
		name: '荷兰语',
		code: "nl",
		value: '',
	}, {
		id: "6",
		name: '葡萄牙语',
		code: "pt",
		value: '',
	}, {
		id: "7",
		name: '法语',
		code: "fr",
		value: '',
	}, {
		id: "8",
		name: '德语',
		code: "de",
		value: '',
	}, {
		id: "9",
		name: '中文',
		code: "cn",
		value: '',
	}],
	countryList: [{
		id: "1",
		name: '英语',
		code: "GB",
		value: '',
	}, {
		id: "2",
		name: '西班牙语',
		code: "ES",
		value: '',
	}, {
		id: "3",
		name: '意大利语',
		code: "IT",
		value: '',
	}, {
		id: "4",
		name: '波兰语',
		code: "PL",
		value: '',
	}, {
		id: "5",
		name: '荷兰语',
		code: "NL",
		value: '',
	}, {
		id: "6",
		name: '葡萄牙语',
		code: "PT",
		value: '',
	}, {
		id: "7",
		name: '法语',
		code: "FR",
		value: '',
	}, {
		id: "8",
		name: '德语',
		code: "DE",
		value: '',
	}, {
		id: "9",
		name: '中文',
		code: "CN",
		value: '',
	}]
}

//    export  const  SUCCESS_CODE = 0
