import httpV2 from '@/utils/httpV2';

const state = {};
const getters = {};
const mutations = {};
const goodstUrl = '/b2c/v1.0/goods';
const palletUrl = '/b2c/v1.0/pallet';
const actions = {
    //获取List
    getPalletPage({ state, commit }, params) {
        let res = httpV2.get2( palletUrl + `/page/search`, params, this);
        return res;
    },
    //获取List
    addPallet({ state, commit }, params) {
        let res = httpV2.post(palletUrl + `/add`, params, this);
        return res;
    },
    //获取List
    putPallet({ state, commit }, params) {
        let res = httpV2.put(palletUrl + `/${params.palletId}`, params, this);
        return res;
    }, 
    //删除
    deletePallet({ state, commit }, params) {
        let res = httpV2.delete(palletUrl + `/${params.palletId}`, params, this);
        return res;
    },
    //多语言查询
    getPalletTranslate({ state, commit }, params) {
        let res = httpV2.get2(palletUrl + `/translate/${params.palletId}`, '', this);
        return res;
    },    
    //多语言保存
    addPalletTranslate({ state, commit }, params) {
        let res = httpV2.post(palletUrl + `/translate/save/${params.palletId}`, params.languages, this);
        return res;
    },
    //获取不分页
    postGoodsIdPallet({ state, commit }, params) {
        let res = httpV2.post(goodstUrl+ `/list/ids`, params, this);
        return res;
    },
    //检验是否有预售商品
    postCheckPallet({ state, commit }, params) {
        let res = httpV2.post(palletUrl+ `/check`, params, this);
        return res;
    },
};

export default {
    getters,
    state,
    mutations,
    actions
};
