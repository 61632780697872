import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
	//获取List
	getClassify({ state, commit }, params) {
	    let list = httpV2.get('/arc/v1.0/goods/classify/tree/search', params, this);
	    return list;
	},
	//新增类目
	addAdClassify({ state, commit }, params) {
	    let list = httpV2.post('/arc/v1.0/goods/classify', params, this);
	    return list;
	},
	upClassify({ state, commit }, params) {
	    let list = httpV2.put(`/arc/v1.0/goods/classify/${params.goodsClassifyId}`, params, this);
	    return list;
	},
	//获取List
	uodateStatus({ state, commit }, params) {
	    let list = httpV2.put(`/arc/v1.0/goods/classify/${params.id}/status/${params.status}`, params, this);
	    return list;
	},
	getTranslateLang({ state, commit }, params) {
	    let list = httpV2.get(`/arc/v1.0/goods/classify/translate/${params.id}`, params, this);
	    return list;
	},
	updateTranslate({ state, commit }, params) {
		let goodsClassifyId=params[0].goodsClassifyId
	    let list = httpV2.put(`/arc/v1.0/goods/classify/translate/update/batch/${goodsClassifyId}`, params, this);
	    return list;
	},
	//获取选中的搜索参数
	getTranslateRelation({ state, commit }, params) {
	    let list = httpV2.get(`/arc/v1.0/goods/classify/parameter/${params.goodsClassifyId}/${params.parameterType}`, params, this);
	    return list;
	},
	getVarAdGroupItems({ state, commit }, params) {
	    let list = httpV2.get(`/arc/v1.0/parameter/group/list/items`, params, this);
	    return list;
	},
	updateTranslatePara({ state, commit }, params) {
	    let list = httpV2.put(`/arc/v1.0/goods/classify/parameter/insert/batch`, params, this);
	    return list;
	},
	updateHomeShow({ state, commit }, params) {
	    let list = httpV2.put(`/arc/v1.0/goods/classify/${params.id}/appStatus/${params.status}`, params, this);
	    return list;
	},
	delClassify({ state, commit }, params) {
        let promise = httpV2.delete(`/arc/v1.0/goods/classify/${params.goodsClassifyId}`, params, this);
        return promise;
    },	
};

export default {
    getters,
    state,
    mutations,
    actions
};
