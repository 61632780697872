import httpV2 from '@/utils/httpV2';

const state = {};
const getters = {};
const mutations = {};

 
const homePageCopyWriterUrl = '/b2c/v1.0/homepagecopywriter';

const actions = {
    //获取List
    getHomePageCopyWriterPage({ state, commit }, params) {
        let res = httpV2.post( homePageCopyWriterUrl + `/page/search?page=${params.page}&limit=${params.limit}`, params, this);
        return res;
    },
    //删除
    deleteHomePageCopyWriter({ state, commit }, params) {
        let res = httpV2.delete(homePageCopyWriterUrl + `/${params.homePageCopyWriterId}`, params, this);
        return res;
    },
    // 新增
    addHomePageCopyWriter({ state, commit }, params) {
        let res = httpV2.post(homePageCopyWriterUrl , params, this);
        return res;
    },
    // 编辑
    putHomePageCopyWriter({ state, commit }, params) {
        let res = httpV2.put(homePageCopyWriterUrl + `/${params.homePageCopyWriterId}`, params, this);
        return res;
    }, 
};

export default {
    getters,
    state,
    mutations,
    actions
};
