import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    getActivityPage({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/activity/page/search", params, this);
        return promise;
    },
    getActivityFreeFreightPage({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/activity/free-freight/page/search", params, this);
        return promise;
    },
    getActivityFreeFreightById({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/activity/free-freight", params, this);
        return promise;
    },
    getAgbsActivityList({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/activity_agbs/effect_activity_list", params, this);
        return promise;
    },
    agbsActivityFormInit({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/activity/forminit", params, this);
        return promise;
    },
    saveActivity({ state, commit }, params) {
        let promise = httpV2.put("/b2c/v1.0/activity/save", params, this);
        return promise;
    },
    delActivityFreightPage({ state, commit }, params) {
        let promise = httpV2.delete("/b2c/v1.0/activity/free-freight", params, this);
        return promise;
    },
    getImportBarCodes({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/activity/import_barcodes", params, this);
        return promise;
    },
    addActivity({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/activity", params, this);
        return promise;
    },
    addFreeFreight({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/activity/free-freight", params, this);
        return promise;
    },
    updateActivity({ state, commit }, params) {
        let promise = httpV2.putfull("/b2c/v1.0/activity", { id: params.activityId }, params, this);
        return promise;
    },
    updateActivitySort({ state, commit }, params) {
        let promise = httpV2.putfull(`/b2c/v1.0/activity/good/${params.id}/sort/${params.sort}`, { id: params.id }, params, this);
        return promise;
    },
    updateActivityStatus({ state, commit }, params) {
        let promise = httpV2.putfull(`/b2c/v1.0/activity/good/${params.id}/status/${params.status}`, { id: params.id }, params, this);
        return promise;
    },
    updateFreeFreight({ state, commit }, params) {
        let promise = httpV2.putfull("/b2c/v1.0/activity/free-freight", { id: params.activityId }, params, this);
        return promise;
    },
    getActivity({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/activity", params, this);
        return promise;
    },
    delActivity({ state, commit }, params) {
        let promise = httpV2.delete("/b2c/v1.0/activity", params, this);
        return promise;
    },
    getActivitySeo({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/activity/expand/activityId/" + params.activityId, params, this);
        return promise;
    },
    updateActivitySeo({ state, commit }, params) {
        let promise = httpV2.putfull("/b2c/v1.0/activity/expand/activityId/" + params.activityId, {}, params, this);
        return promise;
    },
    getActivityGoods({ state, commit }, params) {
        let promise = httpV2.get2(`/b2c/v1.0/activity/good/page/search`, params, this);
        return promise;
    },
    addActivityGoods({ state, commit }, params) {
        let promise = httpV2.post(`/b2c/v1.0/activity/good/`, params, this);
        return promise;
    },
    delActivityGoods({ state, commit }, params) {
        let promise = httpV2.delete("/b2c/v1.0/activity/good/", params, this);
        return promise;
    },
    delAllActivityGoods({ state, commit }, params) {
        let promise = httpV2.deleteList("/b2c/v1.0/activity/good/all", params, this);
        return promise;
    },
};

export default {
    getters,
    state,
    mutations,
    actions
};