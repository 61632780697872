import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
	//获取列表
	getGoodsVarList({ state, commit }, params) {
	    let promise = httpV2.get2("/b2c/v1.0/goods/variant/page/search", params, this);
	    return promise;
	},
	//新增
	postGoodsVarList({ state, commit }, params) {
	    let promise = httpV2.post("/b2c/v1.0/goods/variant", params, this);
	    return promise;
	},
	//获取商品类目树
	getGoodsVarClassifyTree({ state, commit }, params) {
	    let promise = httpV2.get("/b2c/v1.0/variant/list", params, this);
	    return promise;
	},
	//新增
	postGoodsAdVariant({ state, commit }, params) {
	    let promise = httpV2.post("/b2c/v1.0/goods/variant", params, this);
	    return promise;
	},
	getGoodsVarClassifyItem({ state, commit }, params) {
	    let promise = httpV2.get(`/b2c/v1.0/goods/variant/${params.goodsVariantId}/parameter/list`, params, this);
	    return promise;
	},
	
	getGoodsItem({ state, commit }, params) {
	    let promise = httpV2.get(`/b2c/v1.0/goods/variant/${params.goodsVariantId}/relation/list`, params, this);
	    return promise;
	},
	putGoodsAdVariant({ state, commit }, params) {
	    let promise = httpV2.put(`/b2c/v1.0/goods/variant/${params.goodsVariantId}`, params, this);
	    return promise;
	},
	deleteGoodsAdVariant({ state, commit }, params) {
	    let promise = httpV2.delete(`/b2c/v1.0/goods/variant/${params.id}`, params, this);
	    return promise;
	},
	
	
	//根据ID获取编辑列表
	getGoodsbjSpecial({ state, commit }, params) {
	    let promise = httpV2.get(`/b2c/v1.0/goods/${params.goodsId}/special`, params, this);
	    return promise;
	},
	//根据ID获取编辑列表
	putGoodsbjSpecial({ state, commit }, params) {
	    let promise = httpV2.put(`/b2c/v1.0/goods`, params, this);
	    return promise;
	},
	
	//更新图片
	putGoodsbjimage({ state, commit }, params) {
		let goodsId=params[0].goodsId
	    let promise = httpV2.put(`/b2c/v1.0/goods/${goodsId}/image`, params, this);
	    return promise;
	},
	//获取大图列表
	getGoodsbjimage({ state, commit }, params) {
	    let promise = httpV2.get(`/b2c/v1.0/goods/${params.goodsId}/image`, params, this);
	    return promise;
	},
	//修改图片
	putGoodsbjMainimage({ state, commit }, params) {
		let goodsId=params.goodsId
	    let promise = httpV2.put(`/b2c/v1.0/goods/${params.goodsId}/image/main`, params, this);
	    return promise;
	},
	//获取
	getGoodWangEditer({ state, commit }, params) {
	    let promise = httpV2.get(`/b2c/v1.0/goods/${params.goodsId}/detail`, params, this);
	    return promise;
	},
	putGoodWangEditer({ state, commit }, params) {
		let goodsId=params[0].goodsId
	    let promise = httpV2.put(`/b2c/v1.0/goods/${goodsId}/detail`, params, this);
	    return promise;
	},
	
	
	//根据产品ID获取seo
	getGoodsSeo({ state, commit }, params) {
	    let promise = httpV2.get(`/b2c/v1.0/goods/${params.goodsId}/seo`, params, this);
	    return promise;
	},
	putGoodsSeo({ state, commit }, params) {
		let goodsId=params[0].goodsId
	    let promise = httpV2.put(`/b2c/v1.0/goods/${goodsId}/seo`, params, this);
	    return promise;
	},
	//根据参数组Item获取参数值
	postGoodsParameter({ state, commit }, params) {
	    let promise = httpV2.post(`/b2c/v1.0/goods/parameter/list/appoint`, params, this);
	    return promise;
	},
	

};

export default {
    getters,
    state,
    mutations,
    actions
};
