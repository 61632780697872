import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
  //分页获取产品列表
  getInformationPage({ state, commit }, params) {
    let promise = httpV2.get2("/b2c/v1.0/information/page/search", params, this);
    return promise;
  },
  getInformationList({ state, commit }, params) {
    let promise = httpV2.get2("/b2c/v1.0/information/list", params, this);
    return promise;
  },

  createInformation({ state, commit }, params) {
    let promise = httpV2.post("/b2c/v1.0/information", params, this);
    return promise;
  },

  updateInformation({ state, commit }, params) {
    let promise = httpV2.put("/b2c/v1.0/information?id="+params.informationId, params, this);
    return promise;
  },
  
  deleteInformation({ state, commit }, id) {
    let promise = httpV2.delete("/b2c/v1.0/information/"+id, {}, this);
    return promise;
  },
  getInformationById({ state, commit }, id) {
    let promise = httpV2.get2("/b2c/v1.0/information/"+id, {}, this);
    return promise;
  },
  getTree({ state, commit }, params) {
    let promise = httpV2.get2("/b2c/v1.0/information/tree", params, this);
    return promise;
  },
  getCatalogue({ state, commit }, id) {
    let promise = httpV2.get2("/b2c/v1.0/information/catalogue", {}, this);
    return promise;
  }



};

export default {
  getters,
  state,
  mutations,
  actions
};
