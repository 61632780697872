import Oidc from "oidc-client";
// 登录验证  
   //     authority:"http://10.0.0.40:5008",
var config = {

    //dev
    authority:  process.env.VUE_APP_AUTHORITY, 
    client_id:  process.env.VUE_APP_CLIENT_ID,
    redirect_uri: process.env.VUE_APP_REDIRECT_URI,
    response_type: "id_token token",
    scope: "openid profile api-admin api-sdp-admin",
    post_logout_redirect_uri:  process.env.VUE_APP_REDIRECT_OUT_URI
    //测试
    // authority:  process.env.VUE_APP_AUTHORITY, 
    // client_id: "aigo.community.h5.test",
    // redirect_uri: "postListsignin",
    // response_type: "id_token token",
    // scope: "openid profile api-uc api-community api-smartlife",
    // post_logout_redirect_uri: "http://test.celebrityposts.aigostar.com:8503/signout"

    //生产
    // authority:  process.env.VUE_APP_AUTHORITY, 
    // client_id: "aigo.community.h5",
    // redirect_uri: "http://celebrityposts.aigostar.com/signin",
    // response_type: "id_token token",
    // scope: "openid profile api-uc api-community api-smartlife",
    // post_logout_redirect_uri: "http://celebrityposts.aigostar.com/signout"
};
var mgr = new Oidc.UserManager(config);

function requestBefore(callback,param1,param2){
  mgr.getUser().then((user) =>{
    callback(user,param1,param2)
  });
}

export default{
    mgr,
    requestBefore
}