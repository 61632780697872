import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    //分页获取产品列表
    getSubscribePage({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/subscribe/page/search", params, this);
        return promise;
    },
    subscribeEdit({ state, commit }, params) {
        let promise = httpV2.put("/b2c/v1.0/subscribe/" + params.id, {}, this);
        return promise;
    },
    /**
     * 导出【SEO管理】excel
     */
    exportSubscribeExcel({ state, commit }, params) {
        let promise = httpV2.getFile("/b2c/v1.0/subscribe/export", params, this);
        return promise;
    },
};

export default {
    getters,
    state,
    mutations,
    actions
};