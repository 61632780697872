import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    getUserInfoPage({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/user/info/page/search", params, this);
        return promise;
    },
    getUserDetailPage({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/user/detail/statistic/page/search", params, this);
        return promise;
    },
    updateUserInfoStatus({ state, commit }, params) {
        let promise = httpV2.put("/b2c/v1.0/user/info/" + params.userId, {}, this);
        return promise;
    },
    getAddressPage({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/user/address/page", params, this);
        return promise;
    },
    getCollectionPage({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/goods/collection/page", params, this);
        return promise;
    },
    getUserAccess({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/user/access/statistic/page/search", params, this);
        return promise;
    },
    getShoppingCar({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/user/info/cart", params, this);
        return promise;
    },
    exportUserInfoExcel({ state, commit }, params) {
        let promise = httpV2.getFile("/b2c/v1.0/user/info/export", params, this);
        return promise;
    },

    // 下载导入模板
    downloadBatchRechargeExcelTemplate({ state, commit }, params) {
        let promise = httpV2.getFile("/b2c/v1.0/user/info/batch/recharge/template", params, this);
        return promise;
    },

    //充值爱国币
    rechargeCurrency({ state, commit }, params) {
        let promise = httpV2.put("/b2c/v1.0/user/info/currency", params, this);
        return promise;
    },
    //批量充值爱国币
    batchRechargeCurrency({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/user/info/batch/recharge", params, this);
        return promise;
    },
    //批量充值信息下载
    importInfoDownload({ state, commit }, params) {
        let promise = httpV2.getFile(`/b2c/v1.0/user/info/importInfo/export/${params.batchId}`, params, this);
        return promise;
    },
    //用户爱国币信息
    listUserCurrency({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/user/currency/expire/list", params, this);
        return promise;
    }
};

export default {
    getters,
    state,
    mutations,
    actions
};