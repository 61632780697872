import httpV2 from '@/utils/httpV2';
import cache from '../utils/cache';
import Config from '@/utils/config';
import dictUtil from '@/utils/dictionaryUtil';

const state = {
    customerValue: [1] // 客户价值
};
const getters = {
    getCustomerValue: state => {
        return state.customerValue;
    }
};

const mutations = {
    customerValue(state, list) {
        state.customerValue = list;
    }
};

const actions = {
    customerValue({ commit }) {
        commit('customerValue', []);
        // api.customerValueList()
        //   .then(list => (commit('customerValue', list)))
    },
    //获取所有的字典
    async getDictList({ state, commit }, params) {
        var dict = cache.get(dictUtil.getDictSummaryKey());
        let tenant = cache.get(Config.currentTenant);
        if (tenant == null) {
            var arr = await httpV2.get(process.env.VUE_APP_SDP + '/v1.0/tenants/byuser', {}, this);
            var tenantList = [];
            arr.forEach(element => {
                if (element.app_id == Config.appId && element.permission) {
                    tenantList.push(element);
                }
            });
            var currentTenant;
            let tenant = cache.get(Config.currentTenant);
            if (tenant) {
                //没有租户默认选第一个
                currentTenant = tenant;
            } else {
                currentTenant = tenantList[0];
            }
            dict = await httpV2.get(process.env.VUE_APP_SDP + '/v1.0/dicts/summary', {}, this);
            cache.set(dictUtil.getDictSummaryKey(), dict);
        } else {
            if (dict == undefined || dict == null || dict.length == 0) {
                dict = await httpV2.get(process.env.VUE_APP_SDP + '/v1.0/dicts/summary', {}, this);
                cache.set(dictUtil.getDictSummaryKey(), dict);
            }
        }
        return dict;
    },

    getDicItems({ dispatch, state, commit }, params) {
        var that = this;
        return new Promise(async(resolve, reject) => {
            var dictItems = dictUtil.getDict(params.tag);
            if (dictItems && dictItems != null) {
                resolve(dictItems);
            } else {
                var dictSummary = cache.get(dictUtil.getDictSummaryKey());
                if (!dictSummary || dictSummary == null) {
                    // dictSummary =await that.getDictList;
                    dispatch('getDictList', {}).then(res => {
                        dictSummary = res;
                        var element = dictSummary.find(x => x.dict_code == params.tag);
                        if (element && element.api_url != '') {
                            httpV2.get(process.env.VUE_APP_SDP + element.api_url, {}, this).then(data => {
                                dictUtil.setDict(params.tag, data);
                                resolve(data);
                            });
                        }
                    });
                } else {
                    var element = dictSummary.find(x => x.dict_code == params.tag);
                    if (element && element.api_url != '') {
                        httpV2.get(process.env.VUE_APP_SDP + element.api_url, {}, this).then(data => {
                            dictUtil.setDict(params.tag, data);
                            resolve(data);
                        });
                    }
                }
            }
        });
    }
};

export default {
    getters,
    state,
    mutations,
    actions
};