import httpV2 from '@/utils/httpV2';

const state = {};
const getters = {};
const mutations = {};

const fullDiscountUrl = '/b2c/v1.0/full/discount';
 
const actions = {
    // 满减活动 列表
    getFullDiscountPage({ state, commit }, params) {
        let res = httpV2.post( fullDiscountUrl + `/page/search?page=${params.page}&limit=${params.limit}`, params, this);
        return res;
    },
    //满减活动 删除
    deleteFullDiscount({ state, commit }, params) {
        let res = httpV2.delete(fullDiscountUrl + `/${params.fullDiscountId}`, params, this);
        return res;
    },
    //满减活动 新增
    addFullDiscount({ state, commit }, params) {
        let res = httpV2.post(fullDiscountUrl + `/add`, params, this);
        return res;
    },
    //满减活动 修改
    putFullDiscount({ state, commit }, params) {
        let res = httpV2.put(fullDiscountUrl + `/${params.fullDiscountId}`, params, this);
        return res;
    }, 
    //满减活动 检验
    postCheckFullDiscount({ state, commit }, params) {
        let res = httpV2.post(fullDiscountUrl+ `/check`, params, this);
        return res;
    },
    //满减活动信息
    getFullDiscountInfo({ state, commit }, params) {
        let res = httpV2.get2(fullDiscountUrl + `/${params.fullDiscountId}`, params, this);
        return res;
    }
};

export default {
    getters,
    state,
    mutations,
    actions
};
