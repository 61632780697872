import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
	//获取List
	getVariant({ state, commit }, params) {
	    let res = httpV2.get2('/b2c/v1.0/variant/page/search', params, this);
	    return res;
	},
	getTinyVariant({ state, commit }, params) {
	    let res = httpV2.post(`/b2c/v1.0/goods/page/search/tiny?page=${params.page}`, params, this);
	    return res;
	}
};

export default {
    getters,
    state,
    mutations,
    actions
};
