import cache from "@/utils/cache";
import Config from "@/utils/config";

const tenantMap = new Map([
    [Config.AigoStar, 'AigoStar'],
    [Config.Nobleza, 'Nobleza'],
    [Config.AigoSmart, 'AigoSmart'],
    [Config.TaylorSwoden, 'TaylorSwoden'],
    [Config.AigoSmartNonSelf, 'AigoSmartNonSelf'],
]);

function getBrand() {
    const tenantId = cache.get(Config.currentTenant) == null ? Config.AigoStar : cache.get(Config.currentTenant).tenant_id;
    return tenantMap.get(tenantId);
}

export default {
    getBrand
}