/**
 * http.js
 * 封装axios，    通过store 定义全局loading状态
 * 调用方法:
 * http.get('/api/enquiry/web/query',{id:1}).then((res)=>{你的操作})
 * http.post('/api/enquiry/web/update',{id:1}).then((res)=>{你的操作})
 * http.postFormData('/api/enquiry/web/update',{id:1,file:file}).then((res)=>{你的操作})
 */
import axios from 'axios'
import cache from '@/utils/cache'
import { Message } from 'element-ui';
axios.defaults.baseURL = process.env.VUE_APP_URL
import Config from '@/utils/config'
import fileDownload from 'js-file-download'
import qs from 'qs'
import Scene from '@/utils/scene';
var lang = cache.getStr(Config.localLangage)? cache.getStr(Config.localLangage):window.navigator.language

function to401(err) {
    console.log('err', err.response)
    if (err.response.status == 401) {
        setTimeout(() => {
            window.location = "/signin";
        }, 1000)
    }
}

function errF(err) {
    console.log('err', err.response)
    if (err.response.status != 401) {
        Message({
            type: "error",
            message: err.response.data.message
        });
    }
}
export default {
    /**
     * get方法，对应get请求
     * @param {String} url [请求的url地址]
     * @param {Object} params [请求时携带的参数]
     */
    get(url, params, context, token) {
        return new Promise((resolve, reject) => {
            axios.get(url, {
                params: params,
                headers: this.getHeader({})
            }).then(res => {
                resolve(res.data)
            }).catch(err => {
                errF(err)
                to401(err)
                reject(err)
            })
        })
    },



    /**
     * get方法，对应get请求
     * @param {String} url [请求的url地址]
     * @param {Object} params [请求时携带的参数]
     */
    get2(url, params, context, token) {
        return new Promise((resolve, reject) => {

            if (params) {
                var p = '';
                for (var key in params) {
                    if (typeof(params[key]) == 'object') {
                        if (params[key] != null) {
                            if (params[key].length && params[key].length > 0) {
                                for (var i = 0; i < params[key].length; i++) {
                                    p += key + "=" + params[key][i] + "&";
                                }
                            } else {
                                p += key + "=" + params[key] + "&"
                            }
                        }
                    } else {
                        if (params[key] != null && params[key] != '')
                            p += key + "=" + params[key] + "&"
                    }
                }

                if (p != '' && p.length > 0) {
                    p = p.substr(0, p.length - 1);

                    url += "?" + p
                }
            }


            axios.get(url, {
                headers: this.getHeader({}),
            }).then(res => {
                resolve(res.data)
            }).catch(err => {

                errF(err)
                to401(err)


                reject(err)
            })
        })
    },

    patch(url, params, context, token) {
        return new Promise((resolve, reject) => {
            axios.patch(url, params, {
                headers: this.getHeader({})
            }).then(res => {
                resolve(res.data)
            }).catch(err => {

                errF(err)
                to401(err)
                reject(err)
            })
        })
    },
    deleteList(url, params, context, token) {
        return new Promise((resolve, reject) => {
            axios.delete(url, {
                params: params,
                headers: this.getHeader({}),
                paramsSerializer: params => {
                    return qs.stringify(params, { indices: false })
                }
            }).then(res => {
                resolve(res.data)
            }).catch(err => {

                errF(err)
                to401(err)
                reject(err)
            })
        })
    },
    delete(url, params, context, token) {
        return new Promise((resolve, reject) => {
            axios.delete(url, {
                params: params,
                headers: this.getHeader({})
            }).then(res => {
                resolve(res.data)
            }).catch(err => {

                errF(err)
                to401(err)
                reject(err)
            })
        })
    },
    /**
     * post方法，对应post请求
     * @param {String} url [请求的url地址]
     * @param {Object} params [请求时携带的参数]
     */
    post(url, params, context) {
        return new Promise((resolve, reject) => {
            axios.post(url, params, {
                    headers: this.getHeader({})
                })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    errF(err)
                    to401(err)
                    if (err.response.data.message != '资源已存在') {
                        reject(err)
                    }

                })
        })
    },
    /**
     * put方法，对应put请求
     * @param {String} url [请求的url地址]
     * @param {Object} params [请求时携带的参数]
     */
    put(url, params, context) {
        return new Promise((resolve, reject) => {
            axios.put(url, params, {
                    headers: this.getHeader({})
                })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    errF(err)
                    to401(err)
                    reject(err)
                })
        })
    },
    /**
     * put方法，对应put请求
     * @param {String} url [请求的url地址]
     * @param {Object} params [请求时携带的参数]
     */
    putfull(url, params, data, context) {
        return new Promise((resolve, reject) => {
            axios.put(url, data, {
                    params: params,
                    headers: this.getHeader({})
                })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    errF(err)
                    to401(err)
                    reject(err)
                })
        })
    },

    postFormDataOss(url, params) {
        return new Promise((resolve, reject) => {
            axios({
                    headers: {
                        "Content-Type": "multipart/form-data",
                        'Accept-Language': lang,
                        'lang': lang,
                    },
                    transformRequest: [
                        function(data) {
                            // 在请求之前对data传参进行格式转换
                            const formData = new FormData();
                            Object.keys(data).forEach((key) => {
                                formData.append(key, data[key]);
                            });
                            console.log('formData', formData)
                            return formData;
                        },
                    ],
                    url,
                    method: "post",
                    data: params,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    console.error(err.response);

                    reject(err);
                });
        })
    },
    postExportFileData(url, data) {
        return axios.request({ // axios.request 方法是简单的对axios的封装，看这配置也应该不需要多介绍
            url,
            method: 'post',
            headers: this.getHeader({
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'X-Requested-With': 'XMLHttpRequest'
            }),
            responseType: "blob"
        }).then(res => {
            fileDownload(res.data, data.fileName)
            resolve(res)
        }).catch(err => {
            console.info(err)
            reject(err)
        })

    },
    //POST导出
    postExportData(url, params, context) {
        return new Promise((resolve, reject) => {
            axios.post(url, params, {
                    headers: this.getHeader({}),
                    responseType: "blob"
                })
                .then(res => {
                    fileDownload(res.data, params.fileName)
                    resolve(res)
                }).catch(err => {
                    console.info(err)
                    reject(err)
                })
        })
    },
    /**
     * get方法，对应get请求
     * @param {String} url [请求的url地址]
     * @param {Object} params [请求时携带的参数]
     */
    getFile(url, params, context, token) {
        return new Promise((resolve, reject) => {

            if (params) {
                var p = '';
                for (var key in params) {
                    if (typeof(params[key]) == 'object') {
                        if (params[key] != null) {
                            if (params[key].length && params[key].length > 0) {
                                for (var i = 0; i < params[key].length; i++) {
                                    p += key + "=" + params[key][i] + "&";
                                }
                            } else {
                                p += key + "=" + params[key] + "&"
                            }
                        }
                    } else {
                        if (params[key] != null && params[key] != '')
                            p += key + "=" + params[key] + "&"
                    }
                }

                if (p != '' && p.length > 0) {
                    p = p.substr(0, p.length - 1);

                    url += "?" + p
                }
            }



            axios.get(url, {
                headers: this.getHeader({}),
                responseType: "blob"
            }).then(res => {
                fileDownload(res.data, params.fileName)
                resolve(res)
            }).catch(err => {
                console.info(err)
                reject(err)
            })

        })
    },

    getJsDown(url, fileName, params) {
        var promise = new Promise(function(resolve, reject) {
            axios.get(url, {
                params: params,
                headers: this.getHeader({}),
                responseType: "blob"
            }).then(res => {
                fileDownload(res.data, fileName);
                resolve(res)
            }).catch(err => {
                console.info(err)
                reject(err)
            })
        })

        return {
            promise
        }
    },
    /**
     * 获取请求头
     */
    getHeader(append = {}) {
        const headers = {
            Authorization: cache.getStr(Config.accessToken),
            tenantid: Scene.getTenantId(),
            'X-User': 'ew0KICAiaXNzIjogImh0dHBzOi8vdGVzdC5hY2NvdW50LmFpZ29zdGFyLmNvbTozNDQzIiwNCiAgInVzZXJfaWQiOiAiMTAwMDAwMDAxMDEyOTYiLA0KICAib3JnX2lkIjogIjEwMDAiLA0KICAib3JnX3VzZXJfaWQiOiAiMTAxMjk2IiwNCiAgInVzZXJuYW1lIjogImJvYm8iLA0KICAibGVnYWxfbmFtZSI6ICLms6Lms6IiLA0KICAicGhvbmVfbnVtYmVyIjogIiIsDQogICJlbWFpbCI6ICIiLA0KICAiY291bnRyeV9jb2RlIjogIkNOIg0KfQ',
            'X-Iss': 'https://test.account.aigostar.com:3443',
            'Accept-Language': lang,
            'lang': lang
        };
        Object.assign(headers, append)
        return headers
    }

}