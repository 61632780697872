import httpV2 from '@/utils/httpV2';

const state = {};
const getters = {};
const mutations = {};

const rebateGoodsPoolUrl = '/b2c/v1.0/shopping/rebate/goods/pool';
const rebateUrl = '/b2c/v1.0/shopping/rebate';
 
const actions = {
    
    // 购物返利商品池 列表
    getRebateGoodsPoolPage({ state, commit }, params) {
        let res = httpV2.post( rebateGoodsPoolUrl + `/page/search?page=${params.page}&limit=${params.limit}`, params, this);
        return res;
    },
    // 购物返利商品池 删除
    deleteRebateGoodsPool({ state, commit }, params) {
        let res = httpV2.delete(rebateGoodsPoolUrl + `/${params.id}`, params, this);
        return res;
    },
    // 新增购物返利商品池
    addRebateGoodsPool({ state, commit }, params) {
        let res = httpV2.post(rebateGoodsPoolUrl, params, this);
        return res;
    },
    // 修改购物返利商品池
    putRebateGoodsPool({ state, commit }, params) {
        let res = httpV2.put(rebateGoodsPoolUrl + `/${params.shoppingRebateGoodsPoolId}`, params, this);
        return res;
    }, 
    // 查询 返利商品池的操作人信息
    getOperatorList({ state, commit }, params) {
        let res = httpV2.get2(rebateGoodsPoolUrl+ `/operator/list`, params, this);
        return res;
    },
    // 获取返利比例集合 拥有 返利商品池查询条件 返利比例业务字段
    getRatesList({ state, commit }, params) {
        let res = httpV2.get2( rebateUrl+`/rates/list`, params, this);
        return res;
    },
    // 根据国家获取  返利比例管理 返利信息
    getListByCountryCode({ state, commit }, params) {
        let res = httpV2.get2( rebateUrl+`/listByCountryCode`, params, this);
        return res;
    }

};

export default {
    getters,
    state,
    mutations,
    actions
};
