import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    //分页获取产品列表
    getGiftPage({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/gift/page", params, this);
        return promise;
    },

    getByGoodsId({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/gift" + `/${params.goodsId}`, params, this);
        return promise;
    },
     // 删除
    deleteGift({ state, commit }, params) {
        let promise = httpV2.deleteList("/b2c/v1.0/gift" + `/${params.giftId}` , params, this);
        return promise;
    },

    saveGift({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/gift", params, this);
        return promise;
    },

    updateEnableStatus({ state, commit }, params) {
        let promise = httpV2.put("/b2c/v1.0/gift/updateEnableStatus" + `/${params.giftId}` + `/${params.enableStatus}`, params, this);
        return promise;
    },

    getGoodsInfo({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/goods/list/ids", params, this);
        return promise;
    },

};

export default {
    getters,
    state,
    mutations,
    actions
};
