import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    //分页获取产品列表
    getRestrictedPage({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/restricted/area/page/search", params, this);
        return promise;
    },
    addRestricted({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/restricted/area", params, this);
        return promise;
    },
    editRestricted({ state, commit }, params) {
        let promise = httpV2.put("/b2c/v1.0/restricted/area", params, this);
        return promise;
    },
    deleteRestricted({ state, commit }, params) {
        let promise = httpV2.delete("/b2c/v1.0/restricted/area", params, this);
        return promise;
    },
};

export default {
    getters,
    state,
    mutations,
    actions
};