//i18n.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './langs'
import Config from '@/utils/config'
import Cache from '@/utils/cache'
import locale from 'element-ui/lib/locale'



Vue.use(VueI18n)

console.info(window.navigator.language)
// Vue.locale = () => {}
const i18n = new VueI18n({
  locale:Cache.getStr(Config.localLangage) ?Cache.getStr(Config.localLangage) : window.navigator.language,
  messages:messages
})



export default i18n