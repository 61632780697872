import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    //分页获取咨询列表
    getInvoicePage({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/order/invoice/page/list", params, this);
        return promise;
    },
    getInvoiceDetails({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/order/invoice", params, this);
        return promise;
    },
    examine({ state, commit }, params) {
        let promise = httpV2.put("/b2c/v1.0/order/invoice", params, this);
        return promise;
    },
};

export default {
    getters,
    state,
    mutations,
    actions
};