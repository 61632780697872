import Vue from 'vue';
import Router from 'vue-router';
import i18n from '../i18n/i18n';
Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [{
            path: '/signin',
            name: 'signin',
            component: resolve => require(['../views/oicd/Signin.vue'], resolve),
            meta: {
                keepAlive: false //此组件不需要被缓存
            }
        },
        {
            path: '/signout',
            name: 'SiginOut',
            component: resolve => require(['../views/oicd/SiginOut.vue'], resolve),
            meta: {
                keepAlive: false //此组件不需要被缓存
            }
        },
        {
            path: '/signin-oidc',
            name: 'login',
            component: resolve => require(['../views/oicd/Login.vue'], resolve),
            meta: {
                keepAlive: false //此组件不需要被缓存
            }
        },

        {
            path: '/',
            redirect: '/signin-oidc'
        },
        {
            path: '/',
            component: () =>
                import ( /* webpackChunkName: "home" */ '../components/common/Home.vue'),
            meta: {
                title: '自述文件'
            },
            children: [
                //后台路由配置
                {
                    path: '/b2c/recommend/index',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ '../views/recommend/index.vue'),
                    meta: { title: i18n.t("common.Productrecommendations") }
                },
                {
                    name: 'order_list',
                    path: '/b2c/order/index',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ '../views/order/index.vue'),
                    meta: { title: i18n.t("common.OrderList") }
                },
                {
                    name: 'postSale_list',
                    path: '/b2c/postSale/index',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ '../views/postSale/index.vue'),
                    meta: { title: i18n.t("common.Returnandexchangeapplication") }
                },
                {
                    path: '/b2c/distribution/index',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ '../views/distribution/index.vue'),
                    meta: { title: i18n.t("common.Productdistribution") }
                },
                {
                    path: '/b2c/promotion/index',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ '../views/promotion/index.vue'),
                    meta: { title: i18n.t("common.ProductPromotion") }
                },
                {
                    path: '/b2c/firstOrder/index',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ '../views/firstOrder/index.vue'),
                    meta: { title: i18n.t("common.Freeshippingonfirstorder") }
                },
                {
                    path: '/b2c/special/index',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ '../views/special/index.vue'),
                    meta: { title: i18n.t("common.ActivityTopic") }
                },
                {
                    path: '/b2c/bannerAdmin/index',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ '../views/bannerAdmin/index'),
                    meta: { title: i18n.t("common.BannerManagement") }
                },
                {
                    path: '/b2c/banners/index',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ '../views/banner/index.vue'),
                    meta: { title: i18n.t("common.VariantManagement") }
                },
                {
                    path: '/b2c/categoryAssociation/index',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ '../views/categoryAssociation/index.vue'),
                    meta: { title: i18n.t("common.Categoryassociation") }
                },
                {
                    path: '/b2c/classAdmin/index',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ '../views/classAdmin/index.vue'),
                    meta: { title: i18n.t("common.Categorymanagement") }
                },
                {
                    path: '/b2c/coupon/index',
                    component: () =>
                        import ( /* webpackChunkName: "table" */ '../views/coupon/index.vue'),
                    meta: { title: i18n.t("common.coupon") }
                },
                {
                    path: '/b2c/rebate/index',
                    component: () =>
                        import ( /* webpackChunkName: "table" */ '../views/rebate/index.vue'),
                    meta: { title: i18n.t("common.RebateRatioManagement") }
                },
                {
                    path: '/b2c/navi/index',
                    component: () =>
                        import ( /* webpackChunkName: "tabs" */ '../views/navi/index.vue'),
                    meta: { title: i18n.t("common.WebsiteNavigation") }
                },
                {
                    // 图片上传组件
                    path: '/upload',
                    component: () =>
                        import ( /* webpackChunkName: "upload" */ '../components/page/Upload.vue'),
                    meta: { title: '文件上传' }
                },
                {
                    // 国际化组件
                    path: '/i18n',
                    component: () =>
                        import ( /* webpackChunkName: "i18n" */ '../components/page/I18n.vue'),
                    meta: { title: '国际化' }
                },
                {
                    path: '/404',
                    component: () =>
                        import ( /* webpackChunkName: "404" */ '../components/page/404.vue'),
                    meta: { title: '404' }
                },
                {
                    path: '/403',
                    component: () =>
                        import ( /* webpackChunkName: "403" */ '../components/page/403.vue'),
                    meta: { title: '403' }
                },
                {
                    path: '/b2b/home_product_manager',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/goods/variant.vue'),
                    meta: { title: '' }
                },
                {
                    name: 'goods_list',
                    path: '/b2c/goods/index',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/goods/index.vue'),
                    meta: { title: i18n.t("common.ProductManagement") }
                },
                {
                    name: 'gift_list',
                    path: '/b2c/gift/list',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/gift/index.vue'),
                    meta: { title: i18n.t("common.GiftManagement")}
                },
                {
                    path: '/b2c/variant/index',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/variant/index.vue'),
                    meta: { title: i18n.t("common.Productvariants") }
                },
                {
                    path: '/b2c/country/list',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/country/index.vue'),
                    meta: { title: i18n.t("common.Nationalconfiguration") }
                },
                {
                    path: '/b2c/information/list',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/information/index.vue'),
                    meta: { title: i18n.t("common.informationservice") }
                },
                {
                    path: '/b2c/seo/list',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/seo/index.vue'),
                    meta: { title: i18n.t("common.SEOManagement") }
                },
                {
                    name: 'comment_list',
                    path: '/b2c/comment/list',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/comment/index.vue'),
                    meta: { title: i18n.t("common.CommentManagement") }
                },
                {
                    name: 'login_list',
                    path: '/b2c/loginlog/list',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/loginLog/index.vue'),
                    meta: { title: i18n.t("common.LoginLog") }
                },
                {
                    path: '/b2c/article/list',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/homeArticle/index.vue'),
                    meta: { title: i18n.t("common.Homepagearticle") }
                },
                {
                    path: '/b2c/subscribe/list',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/subscribe/index.vue'),
                    meta: { title: i18n.t("common.subscriptionmanagement") }
                },
                {
                    name: 'consultation_list',
                    path: '/b2c/consultation/index',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/consultation/index.vue'),
                    meta: { title: i18n.t("common.UserConsultation") }
                },
                {
                    name: 'invoice_list',
                    path: '/b2c/invoice/index',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/invoice/index.vue'),
                    meta: { title: i18n.t("common.InvoiceManagement") }
                },
                {
                    name: 'currency_list',
                    path: '/b2c/currency/list',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/userCurrencyRecord/index.vue'),
                    meta: { title: i18n.t("common.Patrioticcoinusagelog") }
                },
                {
                    path: '/b2c/user/index',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/userManagement/index.vue'),
                    meta: { title: i18n.t("common.usermanagement") }
                },
                // {
                //     path: '/home',
                //     component: () =>
                //         import ( /* webpackChunkName: "donate" */ '../views/orderSalesStatistic/index.vue'),
                //     meta: { title: i18n.t("common.Transactionstatistics")}
                // },
                {
                    path: '/home',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/orderState/index.vue'),
                    meta: { title: i18n.t("common.OrderStatistics")}
                },
                {
                    path: '/b2c/statistics/fullGift/index',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/fullGiftOrderSalesStatistic/index.vue'),
                    meta: { title: i18n.t("common.AnalysisOfGiveawayActivities") }
                },
                {
                    path: '/b2c/searchRecord/index',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/searchRecord/index.vue'),
                    meta: { title: i18n.t("common.SearchKeywords") }
                },
                {
                    path: '/b2c/logistics/index',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/logistics/index.vue'),
                    meta: { title: i18n.t("common.LogisticsPricing") }
                },
                {
                    path: '/b2c/restricted/index',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/restrictedArea/index.vue'),
                    meta: { title: i18n.t("common.Prohibitedsalesarea") }
                },
                {
                    path: '/b2c/recipes/index',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/recipes/index.vue'),
                    // meta: { title: i18n.t("common.Prohibitedsalesarea") }
                    meta: { title: i18n.t("common.RecipeManagement") }
                },
                {
                    path: '/b2c/aigoconfig/index',
                    component: () =>
                        import ( /* webpackChunkName: "donate" */ '../views/currencyConfig/index.vue'),
                    meta: { title: i18n.t("common.Allocationofpatrioticcoins") }
                },
                {
                    path: '/b2c/pallet/index',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ '../views/pallet/index.vue'),
                    meta: { title: i18n.t("common.PortfolioManagement") }
                },
                {
                    name: 'goodspresale',
                    path: '/b2c/goodspresale/index',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ '../views/goodsPreSaleConfig/index.vue'),
                    meta: { title: i18n.t("common.PreSaleEvent") }
                },
                {
                    path: '/b2c/sourceUrl/index',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ '../views/sourceUrl/index.vue'),
                    meta: { title: i18n.t("common.URLSourceManagement") }
                },
                {
                    path: '/b2c/userAccess/index',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ '../views/userAccess/index.vue'),
                    meta: { title: i18n.t("common.UserAccess") }
                },
                {
                    path: '/b2c/orderState/index',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ '../views/orderState/index.vue'),
                    meta: { title: i18n.t("common.OrderStatistics") }
                },
                {
                    path: '/b2c/userActivity/index',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ '../views/userActivity/index.vue'),
                    meta: { title: i18n.t("common.UserBehavior") }
                },
                {
                    path: '/b2c/fulldiscount/index',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ '../views/fullDiscount/index.vue'),
                    meta: { title: i18n.t("common.EventManagement") }
                },
                {
                    path: '/b2c/rebateGoodsPool/index',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ '../views/rebateGoodsPool/index.vue'),
                    meta: { title: i18n.t("common.RebateProducts") }
                },
                {
                    path: '/b2c/giftActivity/index',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ '../views/giftActivity/index.vue'),
                    meta: {title: i18n.t("common.GiveawayEvent")  }
                },
                {
                    path: '/b2c/fullSync/index',
                    component: () =>
                        import ('../views/sync/fullsync.vue'),
                    meta: { title: '全量同步' }
                },
                {
                    path: '/b2c/homePageCopyWriter/index',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ '../views/homePageCopyWriter/index.vue'),
                    meta: {title: i18n.t("common.HomepageCopywriting")   }  
                },
            ]
        },
        {
            path: '*',
            redirect: '/404'
        },
        {
            name: 'blank',
            path: '/waiting/blank',
            component: () =>
                import ( /* webpackChunkName: "donate" */ '../views/waiting/blank.vue'),
            meta: { title: '空白页' }
        }
    ]
});