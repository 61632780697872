import httpV2 from '@/utils/httpV2';

const state = {};
const getters = {};

const mutations = {};

const classSetUrl = '/v1.0/class_set';
const classSetParamterUrl = '/v1.0/goods_class_set_paramter';
const paramterItemUrl = '/v1.0/paramter_item';

const actions = {
    getClassSetById({ state, commit }, params) {
        let promise = httpV2.get2(classSetUrl + '/' + params.goodsClassSetId + `/detail/`, params, this);
        return promise;
    },
    getMainTree({ state, commit }, params) {
        let promise = httpV2.get(classSetUrl + `/main/tree`, params, this);
        return promise;
    },
    getMainTreeLevel({ state, commit }, params) {
        let promise = httpV2.get(classSetUrl + `/main/tree/level`, params, this);
        return promise;
    },
    updateData({ state, commit }, params) {
        let promise = httpV2.put(classSetUrl, params, this);
        return promise;
    },
    getClassSetParam({ state, commit }, params) {
        let promise = httpV2.get(paramterItemUrl + `/goods_class_set/` + params.goodsClassSetId, params, this);
        return promise;
    },

    classSetBatchSync({ state, commit }, params) {
        let promise = httpV2.post(classSetUrl + `/batch_sync`, params, this);
        return promise;
    },
    getClassSetExcel({ state, commit }, params) {
        let promise = httpV2.getJsDown(classSetUrl + `/download`, params.fileName, params);
        return promise;
    },
    addOrUpdateClassRelatedClass({ state, commit }, params) {
        let promise = httpV2.post(`/v1.0/goods_class_set_related_class/addOrUpdate`, params, this);
        return promise;
    },
    updateClassSetIco({ state, commit }, params) {
        let promise = httpV2.put(classSetUrl + `/${params.classSetId}/icon?icon=${params.icon}`, params, this);
        return promise;
    },
    //获取关联类目的主表列表
    getClassRelatedClassPage({ state, commit }, params) {
        let promise = httpV2.get(`/v1.0/goods_class_set_related_class/page`, params, this);
        return promise;
    },
    //获取关联类目的子表列表
    getClassRelatedClassByClassSetId({ state, commit }, classSetId) {
        return httpV2.get('/v1.0/goods_class_set_related_class/list/' + classSetId, {}, this);
    },
    //增加关联的子表
    addClassRelatedClasses({ state, commit }, params) {
        let promise = httpV2.post(`/v1.0/goods_class_set_related_class/relatedClass`, params, this);
        return promise;
    },
    //获取关联类目的子表列表
    deleteRelatedClassByRelatedClassSetId({ state, commit }, classSetId) {
        return httpV2.delete('/v1.0/goods_class_set_related_class/' + classSetId, {}, this);
    }
};

export default {
    getters,
    state,
    mutations,
    actions
};
