import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
  //分页获取列表
  getOrderStatePage({ state, commit }, params) {
    let promise = httpV2.get("/b2c/v1.0/order/state/page/search", params, this);
    return promise;
  },

  //不分页获取列表
  listOrderState({ state, commit }, params) {
    let promise = httpV2.get("/b2c/v1.0/order/state/list", params, this);
    return promise;
  },

  //导出
  exportOrderStateExcel({ state, commit }, params) {
    let promise = httpV2.getFile("/b2c/v1.0/order/state/export", params, this);
    return promise;
  },
};

export default {
  getters,
  state,
  mutations,
  actions
};
