import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    //分页获取产品列表
    getRecipesPage({ state, commit }, params) {
        let promise = httpV2.get2("/b2c//v1.0/mgr/recipes/page", params, this);
        return promise;
    },

    getRecipesCustomInfoByRecipesId({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/mgr/recipes" + `/${params.recipesId}`, params, this);
        return promise;
    },

    saveRecipesLang({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/mgr/recipesLang" + `/${params.recipesId}`, params.recipesLangDtoList, this);
        return promise;
    },

    saveRecipesCustomInfo({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/mgr/recipesCustomInfo", params, this);
        return promise;
    },

    saveRecipesShowImage({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/mgr/recipesShowImage", params, this);
        return promise;
    },

    getRecipesShowImageVo({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/mgr/recipesShowImage", params, this);
        return promise;
    },

};

export default {
    getters,
    state,
    mutations,
    actions
};
