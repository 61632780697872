import httpV2 from '@/utils/httpV2';

const state = {};
const getters = {};

const mutations = {};

const actions = {
    //获取用户统计列表
    getUserStatistic({ state, commit }, params) {
        return httpV2.get('/b2c/v1.0/user_statistic/page',params, this);
    },
    //获取商品统计
    getGoodsStatistic({ state, commit }, params) {
        return httpV2.get('/b2c/v1.0/goods_statistic/page',params, this);
    },
};

export default {
    getters,
    state,
    mutations,
    actions
};
