import httpV2 from '@/utils/httpV2';

const state = {};
const getters = {};

const mutations = {};

const actions = {
    //获取订单列表
    getOrderList({ state, commit }, params) {
        return httpV2.get('b2c/v1.0/mgr/order/page/search', params, this);
    },
    //获取订单详情
    getOrderDetailForBg({ state, commit }, params) {
        return httpV2.get(`/b2c/v1.0/mgr/order/${params.orderId}`, {}, this);
    },
    //手动发货
    manualDeliver({ state, commit }, params) {
        return httpV2.post(`/b2c/v1.0/mgr/order/deliver`, params, this);
    },
    //汇款确认
    transferConfirm({ state, commit }, params) {
        return httpV2.post(`/b2c/v1.0/mgr/order/transfer/confirm`, params, this);
    },
    //保存订单设置
    saveOrderSetting({ state, commit }, params) {
        return httpV2.put(`/b2c/v1.0/order/setting`, params, this);
    },
    //获取订单设置
    getOrderSetting({ state, commit }, params) {
        return httpV2.get(`/b2c/v1.0/order/setting`, params, this);
    },
    //物流公司
    getLogisticsCompany({ state, commit }, countryCode) {
        return httpV2.get(`/b2c/v1.0/logistics/company/list/countryCode?countryCode=${countryCode}`, {}, this);
    },
    getorderSalesStatistic({ state, commit }, params) {
        return httpV2.get('/b2c/v1.0/order/sales/statistic/page/search', params, this);
    },
    // 满赠订单统计
    getFullGiftOrderSalesStatistic({ state, commit }, params) {
        return httpV2.get('/b2c/v1.0/full/gift/order/sales/statistic/page/search', params, this);
    },
    //获取订单列表
    cancelOrder({ state, commit }, params) {
        return httpV2.post(`b2c/v1.0/mgr/order/cancel?id=${params.id}`, params, this);
    },

    getPayment({ state, commit }, params) {
        return httpV2.get('/b2c/v1.0/mgr/order/payment', params, this);
    },
    exportOrderInfoExcel({ state, commit }, params) {
        let promise = httpV2.getFile("/b2c/v1.0/mgr/order/export", params, this);
        return promise;
    },
};

export default {
    getters,
    state,
    mutations,
    actions
};
