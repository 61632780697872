import httpV2 from "@/utils/httpV2";
import cache from "../utils/cache";
import Config from '@/utils/config';

const state = {
    token: localStorage.getItem('token') || '',
    permission:[],
    loading: true,

}
const getters = {

    getLoading: state => {
        return state.loading
    }

}

const mutations = {
    /**
     * 设置进度条
     * @param state 
     * @param status 
     */
    setLoading(state, status) {
        state.loading = status
    },
    setPermission(state,permission){//permission 为传入的权限标识集合
        // 传入的权限集合 赋值给状态中的 permission
        state.permission = permission;
    }
}

const actions = {
    //oss签名
    async getSignature({
        state,
        commit
    }, params) {
        var ossSignature = cache.get(Config.ossSignature);
        var times = new Date().getTime() - 1000;
        if (ossSignature == undefined || ossSignature == null || ossSignature.expire < times) {
            var result = await httpV2.get(process.env.VUE_APP_OSSURL + "/v1.0/system/oss/signature", params, this);
            ossSignature = result;
            cache.set(Config.ossSignature, result);
        }
        return ossSignature;
    },
	//oss签名
	// getSignature({ state, commit }, params) {
	//   // let promise = httpV2.get(process.env.VUE_APP_OSSURL+"/v1.0/system/oss/signature/anonymous", params,this);
	//   let promise = httpV2.get(
	//     process.env.VUE_APP_OSSURL + "/community/v1.0/system/oss/signature/anonymous",
	//     params,
	//     this
	//   );
	//   return promise;
	// },
    //按钮权限
    setPermission({ state, commit },params) {
		console.log('params',params)
        let promise = httpV2.get(process.env.VUE_APP_SDP + '/v1.0/users/roles/permissions', params, this);
        return promise;
    },
    //oss签名
    postOss({ state, commit }, { params, url }) {
		console.log('params',params)
        let promise = httpV2.post(process.env.VUE_APP_OSS_Head, params, this);
        return promise;
    },
    // getBsCountry({ state, commit }, params) {
    //     let list = httpV2.get(process.env.VUE_APP_SDP + '/v1.0/dicts/bs_countrys/items',{},this);
    //     return list;
    // },
    addToFileLibrary({ state, commit }, params) {
        let promise = httpV2.post("/v1.0/file_library/add", params, this);
        return promise;
    },
    //获取租户列表
    getbyUser({
        state,
        commit
    }, params) {
        let promise = httpV2.get(process.env.VUE_APP_SDP + "/v1.0/tenants/byuser", {}, this);
        return promise

    },
    //获取租户列表
    getMenu({
        state,
        commit
    }, params) {
        let promise = httpV2.get2(process.env.VUE_APP_SDP + "/v1.0/system/module/tree", params, this);
        return promise

    },
    setLoading({
        state,
        commit
    }, status) {
        commit("setLoading", status)
    },
    fullsync({ state, commit }, params) {
        let promise = httpV2.get2("/v1.0/goods/full_sync", params, this);
        return promise;
    },
}

export default {
    getters,
    state,
    mutations,
    actions
}