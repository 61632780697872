import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const goodsHomeUrl = "/v1.0/goods_home_recomand";

const actions = {
	
    getGoodsHomeRecomandPage({ state, commit }, params) {
        let promise = httpV2.get(goodsHomeUrl+`/page`, params, this);
        return promise;
    },

    insertGoodsHomeRecomand({ state, commit }, params) {
        let promise = httpV2.post(goodsHomeUrl, params, this);
        return promise;
    },

    updateGoodsHomeRecomand({ state, commit }, params) {
        let promise = httpV2.put(goodsHomeUrl, params, this);
        return promise;
    },
    deleteGoodsHomeRecomand({ state, commit }, params) {
        let promise = httpV2.delete(goodsHomeUrl+"/"+params.recomandId, params, this);
        return promise;
    },
    

};

export default {
    getters,
    state,
    mutations,
    actions
};
