
import Moment from 'moment'

const formatDate = function (value){
    // 获取单元格数据
    let data = value;
    if (!data) {
        return '';
    } else {
        return Moment(data).format('YYYY-MM-DD HH:mm:ss');
    }
}
export {
    formatDate
}