import httpV2 from '@/utils/httpV2';
import dictUtil from '@/utils/dictionaryUtil';

const state = {

};
const getters = {};

const mutations = {};

const actions = {    
    async getDict({ state, commit }, dictName) {
        let list = await httpV2.get(process.env.VUE_APP_SDP + '/v1.0/dicts/'+dictName+'/items', {}, this);
        state[dictName]=list;
        dictUtil.setDict(dictName, list);
    }
};
export default {
    getters,
    state,
    mutations,
    actions
};
