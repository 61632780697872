import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    //分页获取产品列表
    getGiftActivityPage({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/gift/activity/page/search", params, this);
        return promise;
    },
    addGiftActivity({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/gift/activity", params, this);
        return promise;
    },
    editGiftActivity({ state, commit }, params) {
        let promise = httpV2.put(`/b2c/v1.0/gift/activity/${params.giftActivityId}`, params, this);
        return promise;
    },
    deleteGiftActivity({ state, commit }, params) {
        let promise = httpV2.delete("/b2c/v1.0/gift/activity", params, this);
        return promise;
    },
    getGiftSort({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/gift/activity/sort/list", params, this);
        return promise;
    },
    updateGiftSort({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/gift/activity/sort", params, this);
        return promise;
    },

    listGiftActivitySortVO({ state, commit }, params) {
        let promise = httpV2.get2(`/b2c/v1.0/gift/activity/listGiftActivitySortVO/${params.activityType}`, params, this);
        return promise;
    },

    updateGiftActivitySort({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/gift/activity/updateSort", params, this);
        return promise;
    },
    updateGiftActivityPageSetting({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/gift/activity/page/setting", params, this);
        return promise;
    },

};

export default {
    getters,
    state,
    mutations,
    actions
};