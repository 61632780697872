import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
	//获取List
	getDistribution({ state, commit }, params) {
	    let res = httpV2.get2('/b2c/v1.0/distribution/page/search', params, this);
	    return res;
	},
	//获取不分页
	getPromotionId({ state, commit }, params) {
	    let res = httpV2.post('/b2c/v1.0/goods/list/ids', params, this);
	    return res;
	},
	//新增促销
	addDistribution({ state, commit }, params) {
	    let res = httpV2.post("/b2c/v1.0/distribution", params, this);
	    return res;
	},
	//删除
	deleteDistribution({ state, commit }, params) {
		let id=params.id
	    let res = httpV2.delete(`/b2c/v1.0/distribution/${id}`, params, this);
	    return res;
	},
	//修改促销
	putDistribution({ state, commit }, params) {
		let id=params.distributionId
	    let res = httpV2.put(`/b2c/v1.0/distribution/${id}`, params, this);
	    return res;
	},
	//启用禁用
	putDisEnable({ state, commit }, params) {
	    let res = httpV2.put(`/b2c/v1.0/distribution/${params.id}/Status/${params.status}`, params, this);
	    return res;
	},
	getPricing({ state, commit }, params) {
	    let res = httpV2.post(`/b2c/v1.0/logistics/pricing/countrys`, params, this);
	    return res;
	},
	//获取List
	getDistributionlist({ state, commit }, params) {
		let res = httpV2.get2('/b2c/v1.0/distribution/list', params, this);
		return res;
	},
	//获取sku
	getDistributionSku({ state, commit }, params) {
		let res = httpV2.get(`/b2c/v1.0/goods/${params.historyGoodsId}/sku`, params, this);
		return res;
	},	
};

export default {
    getters,
    state,
    mutations,
    actions
};
