import cache from "@/utils/cache";
import Config from "@/utils/config";


function getTenant() {
    return cache.get(Config.currentTenant) == null ? {} : cache.get(Config.currentTenant);
}

function getTenantId() {
    return cache.get(Config.currentTenant) == null ? Config.AigoStar : cache.get(Config.currentTenant).tenant_id;
}
/*AigoStar*/
const isAigoStar = getTenantId() === Config.AigoStar
/*Nobleza*/
const isNobleza = getTenantId() === Config.Nobleza
/*AigoStar*/
const isAigoSmart = getTenantId() === Config.AigoSmart
/*TaylorSwoden*/
const isTaylorSwoden = getTenantId() === Config.TaylorSwoden
/*AigoSmartNonSelf 非自营移动端*/
const isAigoSmartNonSelf = getTenantId() === Config.AigoSmartNonSelf

/*非自营（现在只有AigoSmartNonSelf）*/
const isNonSelf = getTenantId() === Config.AigoSmartNonSelf
/*移动端(包括自营和非自营)*/
const isAigoSmartApp = getTenantId() === Config.AigoSmart || getTenantId() === Config.AigoSmartNonSelf
/*自营品牌网*/
const isBrandSelf = getTenantId() === Config.AigoStar || getTenantId() === Config.Nobleza
    || getTenantId() === Config.TaylorSwoden

export default {
    getTenant, getTenantId, isAigoStar, isNobleza, isAigoSmart, isTaylorSwoden, isAigoSmartNonSelf, isAigoSmartApp, isNonSelf, isBrandSelf
}