import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    //修改无库存显示
    putOutStock({ state, commit }, params) {
        let promise = httpV2.put("/b2c/v1.0/goods/multiple/noStockShow", params, this);
        return promise;
    },
    //同步数量装
    postPackageQty({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/goods/packageQty/sync", params, this);
        return promise;
    },
    //修改底线库存
    putBatchUpdate({ state, commit }, params) {
        let promise = httpV2.put("/b2c/v1.0/stockLimitConfig/batchUpdate", params, this);
        return promise;
    },
    //获取列表
    postGoodsSearch({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/goods/page/search", params, this);
        return promise;
    },
    //新增标签
    postGoodsTag({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/goods/tag/multiple", params, this);
        return promise;
    },
    //编辑批量类目
    putGoodsMultipleClassify({ state, commit }, params) {
        let promise = httpV2.put("/b2c/v1.0/goods/classify/multiple", params, this);
        return promise;
    },

    //
    putGoodsSku({ state, commit }, params) {
        let promise = httpV2.put("/b2c/v1.0/goods/sku/batch", params, this);
        return promise;
    },
    // 同步子条码
    syncSubBarCode({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/goods/sync/subBarCode", params, this);
        return promise;
    },
    // 同步ebp价格
    syncEbpPrice({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/goods/ebp/price/sync", params, this);
        return promise;
    },
    //批量编辑上架国家
    putGoodsShelves({ state, commit }, params) {
        let promise = httpV2.put("/b2c/v1.0/goods/multiple/country", params, this);
        return promise;
    },

    //更新记录
    getGoodsImport({ state, commit }, params) {
        let promise = httpV2.get("/b2c/v1.0/goods/import/page/search", params, this);
        return promise;
    },

    //商品导入
    postGoodsImport({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/goods/import", params, this);
        return promise;
    },

    //获取商品类目树
    getGoodsClassifyTree({ state, commit }, params) {
        let promise = httpV2.get("/arc/v1.0/goods/classify/tree", params, this);
        return promise;
    },
    //校验上架
    getGoodsClassifycheck({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/goods/check/form", params, this);
        return promise;
    },

    //根据ID获取编辑列表
    getGoodsbjSpecial({ state, commit }, params) {
        let promise = httpV2.get(`/b2c/v1.0/goods/${params.goodsId}/special`, params, this);
        return promise;
    },
    //获取产品标题翻译
    getGoodsTranslate({ state, commit }, params) {
        let promise = httpV2.get(`/b2c/v1.0/goods/${params.goodsId}/translate`, params, this);
        return promise;
    },
    //put产品标题翻译
    putGoodsTranslate({ state, commit }, params) {
        let goodsId = params[0].goodsId
        console.log('params[0]', params[0].goodsId)
        let promise = httpV2.put(`/b2c/v1.0/goods/${goodsId}/translate`, params, this);
        return promise;
    },
    putGoodsbShelf({ state, commit }, params) {
        let promise = httpV2.put(`/b2c/v1.0/goods/shelf`, params, this);
        return promise;
    },

    //  添加预售、取消预售 
    putPreSale({ state, commit }, params) {
        let promise = httpV2.put(`/b2c/v1.0/goods/preSale`, params, this);
        return promise;
    },
    getCheckPreSale({ state, commit }, params) {
        let promise = httpV2.get(`/b2c/v1.0/goods/check/preSale/${params.goodsId}`, params, this);
        return promise;
    },

    //更新图片
    putGoodsbjAdimage({ state, commit }, params) {
        let goodsId = params[0].goodsId
        let promise = httpV2.put(`/b2c/v1.0/goods/${goodsId}/image`, params, this);
        return promise;
    },
    //同步到其他语言
    synchroLang({ state, commit }, params) {
        let goodsId = params.images[0].goodsId
        let promise = httpV2.put(`/b2c/v1.0/goods/${goodsId}/image/sync`, params, this);
        return promise;
    },
    //是否显示
    putGoodsbjshow({ state, commit }, params) {
        let promise = httpV2.put(`/b2c/v1.0/goods/${params.goodsId}/image/show`, params, this);
        return promise;
    },
    //图片排序
    putGoodsbjSort({ state, commit }, params) {
        let promise = httpV2.put(`/b2c/v1.0/goods/${params.goodsId}/image/sort`, params, this);
        return promise;
    },
    getOperator({ state, commit }, params) {
        let promise = httpV2.get(process.env.VUE_APP_OSSURL + `/v1.0/staffs/list/search`, params, this);
        return promise;
    },

    // //获取大图列表
    // getGoodsbjimage({ state, commit }, params) {
    //     let promise = httpV2.get(`/b2c/v1.0/goods/${params.goodsId}/image`, params, this);
    //     return promise;
    // },
    //修改图片
    // putGoodsbjMainimage({ state, commit }, params) {
    // 	let goodsId=params.goodsId
    //     let promise = httpV2.put(`/b2c/v1.0/goods/${goodsId}/image/main`, params, this);
    //     return promise;
    // },
    // //获取
    // getGoodWangEditer({ state, commit }, params) {
    //     let promise = httpV2.get(`/b2c/v1.0/goods/${params.goodsId}/detail`, params, this);
    //     return promise;
    // },
    // putGoodWangEditer({ state, commit }, params) {
    // 	let goodsId=params[0].goodsId
    //     let promise = httpV2.put(`/b2c/v1.0/goods/${goodsId}/detail`, params, this);
    //     return promise;
    // },




    //分页获取产品列表
    getGoodsPage({ state, commit }, params) {
        let promise = httpV2.get2("/v1.0/goods/pagelist", params, this);
        return promise;
    },
    getGoodsClassTotal({ state, commit }, params) {
        let promise = httpV2.get("/v1.0/class_set/list/tree", params, this);
        return promise;
    },
    getGoodsDetailFull({ state, commit }, params) {
        let promise = httpV2.get("/v1.0/goods_detail/full", params, this);
        return promise;
    },
    checkFileExists({ state, commit }, params) {
        let promise = httpV2.post("/v1.0/goods_file/checkExists", params, this);
        return promise;
    },
    goodsEdit({ state, commit }, params) {
        let promise = httpV2.post("/v1.0/goods/edit", params, this);
        return promise;
    },
    goodsSync({ state, commit }, params) {
        let promise = httpV2.get2("/v1.0/goods/" + params.tag, params, this);
        return promise;
    },
    goodsSearchByBarCode({ state, commit }, params) {
        let promise = httpV2.get2("/v1.0/goods/list/bar_code", params, this);
        return promise;
    },
    getGoodsById({ state, commit }, params) {
        let promise = httpV2.get("/v1.0/goods/" + params.id, params, this);
        return promise;
    },


    getGalleryList({ state, commit }, params) {
        let promise = httpV2.get(`/b2c/v1.0/goods/image/lang`, params, this);
        return promise;
    },
    postImagecheck({ state, commit }, params) {
        let promise = httpV2.post(`/b2c/v1.0/goods/image/check`, params, this);
        return promise;
    },
    putImageDel({ state, commit }, params) {
        let promise = httpV2.put(`/b2c/v1.0/goods/${params.goodsId}/image/batch/del`, params.list, this);
        return promise;
    },
    postSyncEbp({ state, commit }, params) {
        let promise = httpV2.post(`/b2c/v1.0/goods/ebp/product/sync`, params, this);
        return promise;
    },
    putFixedPrice({ state, commit }, params) {
        let promise = httpV2.put(`/b2c/v1.0/goods/multiple/fixedPrice`, params, this);
        return promise;
    },
    putOrderRemark({ state, commit }, params) {
        let promise = httpV2.put(`/b2c/v1.0/mgr/order/orderRemark/save`, params, this);
        return promise;
    },
    vipMember({ state, commit }, params) {
        let promise = httpV2.put(`/b2c/v1.0/user/member/time/save`, params, this);
        return promise;
    },
    downloadFileByDkAndNo({ state, commit }, params) {
        let promise = httpV2.getFile("/arc/v1.0/goods/downloadFileByDkAndNo", params, this);
        return promise;
    },
    exportGoodsInfoExcel({ state, commit }, params) {
        let promise = httpV2.postExportData("/b2c/v1.0/goods/export", params, this);
        return promise;
    },
    getTableColumn({ state, commit }, params) {
        let promise = httpV2.get(`/b2c/v1.0/goods/table/column/setting/list`, params, this);
        return promise;
    },
    updateTableColumn({ state, commit }, params) {
        let promise = httpV2.put(`/b2c/v1.0/goods/table/column/setting`, params, this);
        return promise;
    },

    // 下载导入模板
    downloadTemplateGoods({ state, commit }, params) {
        let promise = httpV2.getFile("/b2c/v1.0/goods/template", params, this);
        return promise;
    },
};

export default {
    getters,
    state,
    mutations,
    actions
};