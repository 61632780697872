import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    //分页获取产品列表
    getRecommendPage({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/home/module/page/search", params, this);
        return promise;
    },
    //分页获取产品列表
    getRecommend({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/home/module", params, this);
        return promise;
    },
    //新增推荐模快
    addRecommendModule({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/home/module", params, this);
        return promise;
    },
    //新增推荐模快
    delRecommendModule({ state, commit }, params) {
        let promise = httpV2.delete("/b2c/v1.0/home/module", params, this);
        return promise;
    },
    //新增推荐模快
    updateRecommendModule({ state, commit }, params) {
        let promise = httpV2.putfull("/b2c/v1.0/home/module",{id:params.homeModuleId}, params,  this);
        return promise;
    },
    //分页获取产品列表
    getRecommendGoodsPage({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/goods/home/recommend/page/search", params, this);
        return promise;
    },
    //分页获取产品列表
    addRecommendGoods({ state, commit }, params) {
        console.log(params,1231232)
        let promise = httpV2.post("/b2c/v1.0/goods/home/recommend", params, this);
        return promise;
    },
    //删除推荐商品
    delRecommendGoods({ state, commit }, params) {
        let promise = httpV2.delete("/b2c/v1.0/goods/home/recommend", params, this);
        return promise;
    },
    //批量删除推荐商品
    batchDelRecommendGoods({ state, commit }, params) {
        let promise = httpV2.deleteList(`/b2c/v1.0/goods/home/recommend/all/${params.homeModuleId}`, params, this);
        return promise;
    },

    //删除
	deleteGoodsPage({ state, commit }, params) {
        let promise = httpV2.put(`/b2c/v1.0/goods/${params.goodsId}/image/del`, params, this);
        return promise;
    },
    //banner排序
    updateRecommendSort({ state, commit }, params) {
        let promise = httpV2.put(`/b2c/v1.0/goods/home/recommend/${params.id}/sort/${params.sort}`, params, this);
        return promise;
    },
    updateRecommendStatus({ state, commit }, params) {
        let promise = httpV2.put(`/b2c/v1.0/goods/home/recommend/${params.id}/status/${params.status}`, params, this);
        return promise;
    },

};

export default {
    getters,
    state,
    mutations,
    actions
};