import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
  //分页获取列表
  getUserActivityPage({ state, commit }, params) {
    let promise = httpV2.get2("/b2c/v1.0/user/activity/page/search", params, this);
    return promise;
  },

  //导出
  exportUserActivityExcel({ state, commit }, params) {
    let promise = httpV2.getFile("/b2c/v1.0/user/activity/export", params, this);
    return promise;
  },
};

export default {
  getters,
  state,
  mutations,
  actions
};
