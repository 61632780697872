import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
	 
	// 保存预售配置 作废
	postPresalesConfig({ state, commit }, params) {
        let promise = httpV2.post(`/b2c/v1.0/goods/presale/config/presales/${params.goodsId}`, params, this);
        return promise;
    },
	 
	// 商品管理 点击预售配置回显数据
	getPresaleConfigList({ state, commit }, params) {
        let promise = httpV2.get(`/b2c/v1.0/goods/presale/config/list/${params.goodsId}`, params, this);
        return promise;
    },	 

    // 预售商品 分页查询
	getPresalePage({ state, commit }, params) {
        let promise = httpV2.get(`/b2c/v1.0/goods/presale/config/page/search`, params, this);
        return promise;
    }, 
	 
    // 保存预售配置
    addPresalesConfig({ state, commit }, params) {
        let promise = httpV2.post(`/b2c/v1.0/goods/presale/config/`, params, this);
        return promise;
    },
    // 更新预售配置
	putPresalesConfig({ state, commit }, params) {
        let promise = httpV2.put(`/b2c/v1.0/goods/presale/config/${params.goodsPreSaleConfigId}`, params, this);
        return promise;
    },	
    
    // 删除预售配置
    deletePresalesConfig({ state, commit }, params) {
        let promise = httpV2.delete(`/b2c/v1.0/goods/presale/config/${params.goodsPreSaleConfigId}`, params, this);
        return promise;
    },	

    // 
    postGoodsIdPresalesConfig({ state, commit }, params) {
        let res = httpV2.post(`/b2c/v1.0/goods/list/ids`, params, this);
        return res;
    },
    
};

export default {
    getters,
    state,
    mutations,
    actions
};
