import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
	putNaviSort({ state, commit }, params) {
        let promise = httpV2.put(`/b2c/v1.0/site/navigation/${params.id}/sort/${params.sort}`, params, this);
        return promise;
    },	
    getNaviSearch({ state, commit }, params) {
        let promise = httpV2.get2(`/b2c/v1.0/site/navigation/page/search`, params, this);
        return promise;
    },
	// getNaviConfig({ state, commit }, params) {
	//     let promise = httpV2.get(`/b2c/v1.0/home/text/config/${params.id}`, params, this);
	//     return promise;
	// },
	postNaviConfig({ state, commit }, params) {
	    let promise = httpV2.put(`/b2c/v1.0/home/text/config`, params, this);
	    return promise;
	},
	getNaviConfig({ state, commit }, params) {
	    let promise = httpV2.get(`/b2c/v1.0/home/text/config`, params, this);
	    return promise;
	},
	getNaviClass({ state, commit }, params) {
	    let promise = httpV2.get(`/b2c/v1.0/site/navigation/${params.id}`, params, this);
	    return promise;
	},
	putNaviClass({ state, commit }, params) {
	    let promise = httpV2.put(`/b2c/v1.0/site/navigation/${params.id}`, params, this);
	    return promise;
	},
	deleteNaviClass({ state, commit }, params) {
	    let promise = httpV2.delete(`/b2c/v1.0/site/navigation/${params.id}`, params, this);
	    return promise;
	},
	addNavi({ state, commit }, params) {
	    let promise = httpV2.post(`/b2c/v1.0/site/navigation/add`, params, this);
	    return promise;
	},
	
};

export default {
    getters,
    state,
    mutations,
    actions
};
