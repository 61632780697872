
import cache from "./cache";
import i18n from '../i18n/i18n';

const split="_"
const application_name='b2c_admin' // 区分b2c和b2b的字典
const dict_summary='dictSummary'
const dict_items='dictItems'
const dict_summary_pre=application_name + split + dict_summary
const dict_item_pre=application_name + split + dict_items

function getDictItemsKey(...keys){
    let res = dict_item_pre
    for (let key of keys) {
        res = res + split + key
    }
    return res;
}
function getDictSummaryKey(...keys){
    let res = dict_summary_pre
    for (let key of keys) {
        res = res + split + key
    }
    return res;
}

//存储字典 自动拼接前缀
function setDict(key, value) {
    key = getDictItemsKey(key)
    cache.set(key, value)
}
// 获取字典 自动拼接前缀
function getDict(key) {
    key = getDictItemsKey(key)
    return cache.get(key);
}

export default {
    getDictSummaryKey,
    setDict,
    getDict,
    // 返回SDP字典编码对应的名称
    getDictValue(tag, code) {
        var dict = getDict(tag);
        if (dict == undefined || dict == null || dict.length == 0) {
            return code;
        }
        var code = dict.find(x => x.code == code);
        if (code) {
            return code.name;
        }
    },
    //获取国家币种
    getDictCurrencyValue(code) {
        var dict = getDict('country_currency');
        if (dict == undefined || dict == null || dict.length == 0) {
            return false;
        }
        var dictItems = dict.find(x => x.code == code);
        if (dictItems) {
            return dictItems.name;
        }
    },
    //订单列表的预售支付类型 定金、尾款
    getPreSalePayType(type){
        if(type=='DEPOSIT_AND_FINAL_PAYMENT'){
            return i18n.t("common.Finaldeposit");
        }else if(type=='FULL_PAYMENT'){
            return i18n.t("common.Fullpayment");
        }else if(type=='DEPOSIT'){
            return i18n.t("common.Deposit");
        }else if(type=='FINAL_PAYMENT'){
            return i18n.t("common.BalancePayment");
        }
    },
    //订单列表的预售单状态
    getOrderType(type){
        if(type=='PRE_SALE_ORDER'){
            return i18n.t("common.Presaleorder");
        }else if(type=='SALE_ORDER'){
            return i18n.t("common.Officialorder");
        }
    },
}