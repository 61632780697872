import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    //分页获取产品列表
    getCruuencyRecordPage({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/user/currency/record/page/search", params, this);
        return promise;
    },

    getCruuencyConfig({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/user/currency/config/main/list", params, this);
        return promise;
    },

    ruleEdit({ state, commit }, params) {
        let promise = httpV2.put("/b2c/v1.0/user/currency/config/main/rule", params, this);
        return promise;
    },

    langEdit({ state, commit }, params) {
        let promise = httpV2.put("/b2c/v1.0/user/currency/config/main/lang", params, this);
        return promise;
    },
};

export default {
    getters,
    state,
    mutations,
    actions
};