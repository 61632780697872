import Vue from 'vue';
import Vuex from 'vuex';
import dictionary from './dictionary';
import api from './api';
import banner from './banner';
import coupon from './coupon';
import goods from './goods';
import common from './common';
import worker from './worker';
import activity from './activity';
import help_center from './help_center';
import users from './users';
import goodsClassSet from './goodsClassSet';
import homeGoods from './homeGoods';
import order from './order';
import snsSetting from './sns_setting';
import statistic from './statistic';
import countryConfig from '@/store/countryConfig';
import homeArticle from '@/store/homeArticle';
import seoConfig from '@/store/seoConfig';
import subscribe from '@/store/subscribe';
import consultation from '@/store/consultation';
import informationConfig from '@/store/informationConfig';
import invoice from '@/store/invoice';
import loginLog from '@/store/loginLog';
import CurrencyRecord from '@/store/CurrencyRecord';
import promotion from '@/store/promotion';
import comment from '@/store/comment';
import userManagement from '@/store/userManagement';
import categoryAssociation from '@/store/categoryAssociation';
import variant from '@/store/variant';
import variantAdmin from '@/store/variantAdmin';
import classAdmin from '@/store/classAdmin';
import goodsRecmmend from '@/store/goodsRecmmend';
import logistics from '@/store/logistics';
import goodsVariant from '@/store/goodsVariant';
import distribution from '@/store/distribution';
import postsale from '@/store/postSale';
import navi from '@/store/navi';
import restrictedArea from '@/store/restrictedArea';
import pallet from '@/store/pallet';
import goodsPreSaleConfig from '@/store/goodsPreSaleConfig';
import homePageCopyWriter from '@/store/homePageCopyWriter';
import goodsPreSaleCountry from '@/store/goodsPreSaleCountry';
import sourceUrl from '@/store/sourceUrl';
import userAccess from '@/store/userAccess';
import userActivity from '@/store/userActivity';
import fullDiscount from '@/store/fullDiscount';
import orderState from "@/store/orderState";
import sendSyncMessage from './sendSyncMessage';
import rebateGoodsPool from "@/store/rebateGoodsPool";
import giftActivity from '@/store/giftActivity'
import gift from "./gift";
import giftActivityPublicityChart from "./giftActivityPublicityChart";
import recipes from "./recipes";
import searchRecord from "./searchRecord";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        dictionary,
        api,
        banner,
        coupon,
        goods,
        common,
        worker,
        activity,
        help_center,
        users,
        goodsClassSet,
        order,
        homeGoods,
        snsSetting,
        statistic,
        countryConfig,
        homeArticle,
        seoConfig,
        subscribe,
        consultation,
        informationConfig,
        invoice,
        loginLog,
        CurrencyRecord,
        promotion,
        comment,
        userManagement,
        categoryAssociation,
        variant,
        variantAdmin,
        classAdmin,
        goodsRecmmend,
        logistics,
        goodsVariant,
        distribution,
        postsale,
        navi,
        restrictedArea,
        pallet,
        goodsPreSaleConfig,
        goodsPreSaleCountry,
        sourceUrl,
        userAccess,
        userActivity,
        fullDiscount,
        orderState,
        sendSyncMessage,
        rebateGoodsPool,
        giftActivity,
        gift,
        giftActivityPublicityChart,
        recipes,
        searchRecord,
        homePageCopyWriter,
    }
});