import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    //分页获取产品列表
    getCommentPage({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/comment/page/search", params, this);
        return promise;
    },
    
    commentBatchAudit({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/comment/batchAudit", params, this);
        return promise;
    },

    // 下载导入模板
    downloadTemplateComment({ state, commit }, params) {
        let promise = httpV2.getFile("/b2c/v1.0/comment/template", params, this);
        return promise;
    },

     // 批量删除
    deleteBatchComment({ state, commit }, params) {
        let promise = httpV2.deleteList("/b2c/v1.0/comment/all", params, this);
        return promise;
    },

    commentBatchImport({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/comment/batchImport", params, this);
        return promise;
    },
 

    // 获取评论图片视频文件
    getCommentFile({ state, commit }, params) {
        let promise = httpV2.get2(`/b2c/v1.0/comment/file/${params.commentId}`, params, this);
        return promise;
    },
    // 更新评论图片视频文件
    putCommentFile({ state, commit }, params) {
        let res = httpV2.put("/b2c/v1.0/comment/file", params, this);
        return res;
    },
 

};

export default {
    getters,
    state,
    mutations,
    actions
};
