import httpV2 from '@/utils/httpV2';

const state = {};
const getters = {};

const mutations = {};

const actions = {
    //获取List
    getPromotion({ state, commit }, params) {
        let res = httpV2.get2('/b2c/v1.0/promotion/page/search', params, this);
        return res;
    },
    //获取不分页
    postPromotionId({ state, commit }, params) {
        let res = httpV2.post('/b2c/v1.0/goods/list/ids', params, this);
        return res;
    },
    //新增促销
    addPromotion({ state, commit }, params) {
        let res = httpV2.post('/b2c/v1.0/promotion', params, this);
        return res;
    },
    //删除
    deletePromotion({ state, commit }, params) {
        let res = httpV2.delete(`/b2c/v1.0/promotion`, params, this);
        return res;
    },
	putPromotionStatus({ state, commit }, params) {
	    let res = httpV2.put(`/b2c/v1.0/promotion/${params.id}/status/${params.status}`, params, this);
	    return res;
	},
	
    //修改促销
    putPromotion({ state, commit }, params) {
        let res = httpV2.put(`/b2c/v1.0/promotion/id/${params.promotionId}`, params, this);
        return res;
    },
    //批量新增促销
    addPromotions({ state, commit }, params) {
        let res = httpV2.post('/b2c/v1.0/promotion/List', params, this);
        return res;
    },
    //最低价格check
    postCheckLowestPrice({ state, commit }, params) {
        let res = httpV2.post('/b2c/v1.0/goods/lowestPrice/check', params, this);
        return res;
    },
    // 下载导入模板
    downloadTemplatePromotion({ state, commit }, params) {
        let promise = httpV2.getFile("/b2c/v1.0/promotion/template", params, this);
        return promise;
    },
	
};

export default {
    getters,
    state,
    mutations,
    actions
};
