import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    //分页获取产品列表
    getcountryPage({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/countryconfig/page/search", params, this);
        return promise;
    },
    getcountryList({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/country/list", params, this);
        return promise;
    },
    changeCountryStatus({ state, commit }, params) {
        let promise = httpV2.get("/b2c/v1.0/country/changeStatus", params, this);
        return promise;
    },
    addCountry({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/country", params, this);
        return promise;
    },
    updateCountry({ state, commit }, params) {
        let promise = httpV2.put("/b2c/v1.0/country?id=" + params.countryId, params, this);
        return promise;
    },


    getCountryInfo({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/country/" + params, {}, this);
        return promise;
    },


    updateCountryParams({ state, commit }, params) {
        let promise = httpV2.put("/b2c/v1.0/country/parameter", params, this);
        return promise;
    },
    addCountryParamSocial({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/country/parameter/social", params, this);
        return promise;
    },


    listCountryParamSocial({ state, commit }, params) {
        let promise = httpV2.get(`/b2c/v1.0/country/parameter/listByCountryParamId/${params.id}`, {}, this);
        return promise;
    },


    deleteCountryParameterSocial({ state, commit }, params) {
        let promise = httpV2.delete(`/b2c/v1.0/country/parameter/social/${params.id}`, params, this);
        return promise;
    },

    deleteCountry({ state, commit }, id) {
        let promise = httpV2.delete("/b2c/v1.0/country/" + id, {}, this);
        return promise;
    },


    updateCountryParameterSocial({ state, commit }, params) {
        let promise = httpV2.put("/b2c/v1.0/country/parameter/social?id=" + params.countryParameterSocialId, params, this);
        return promise;
    },

    moveUp({ state, commit }, params) {
        let promise = httpV2.put(`/b2c/v1.0/country/parameter/sortup/${params.id}`, params, this);
        return promise;
    },

    moveDown({ state, commit }, params) {
        let promise = httpV2.put(`/b2c/v1.0/country/parameter/sortdown/${params.id}`, params, this);
        return promise;
    },





};

export default {
    getters,
    state,
    mutations,
    actions
};