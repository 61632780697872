import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const commonUrl = "/v1.0/sns_setting";

const actions = {
	
    getSnsSettingPage({ state, commit }, params) {
        let promise = httpV2.get(commonUrl+`/page/search`, params, this);
        return promise;
    },

    insertSnsSetting({ state, commit }, params) {
        let promise = httpV2.post(commonUrl, params, this);
        return promise;
    },

    updateSnsSetting({ state, commit }, params) {
        let promise = httpV2.put(commonUrl+"/"+params.snsSettingId, params, this);
        return promise;
    },
    deleteSnsSetting({ state, commit }, params) {
        let promise = httpV2.delete(commonUrl+"/"+params.snsSettingId, params, this);
        return promise;
    },
    

};

export default {
    getters,
    state,
    mutations,
    actions
};
