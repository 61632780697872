import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
	//获取List
	getCoupon({ state, commit }, params) {
	    let list = httpV2.get2('/b2c/v1/coupon/page/search', params, this);
	    return list;
	},
	addCoupon({ state, commit }, params) {
	    let list = httpV2.post('/b2c/v1/coupon', params, this);
	    return list;
	},
	//获取类目和商品反显
	getFxCoupon({ state, commit }, params) {
	    let list = httpV2.get(`/b2c/v1/coupon/${params.id}`, params, this);
	    return list;
	},
	
	deleteCoupon({ state, commit }, params) {
	    let list = httpV2.delete(`/b2c/v1/coupon/${params.id}`, params, this);
	    return list;
	},
	updateCoupon({ state, commit }, params) {
		console.log('params.couponId',params.couponId)
		let couponId=params.couponId
	    let list = httpV2.put(`/b2c/v1/coupon/${couponId}`, params, this);
	    return list;
	},
	getCouponCodes({ state, commit }, params) {
	    let list = httpV2.get(`/b2c/v1/coupon/${params.couponId}/coupon-code/list`, params, this);
	    return list;
	},
	exportCoupon({ state, commit }, params) {
	    let promise = httpV2.getFile(`/b2c/v1/coupon/${params.couponId}/coupon-code/export`, params, this);
	    return promise;
	},
	enableCoupon({ state, commit }, params) {
	    let promise = httpV2.post(`/b2c/v1/coupon/enable`, params, this);
	    return promise;
	},
};

export default {
    getters,
    state,
    mutations,
    actions
};
