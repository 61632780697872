import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
  
    // 保存预售国家信息
    checkPresalesCountry({ state, commit }, params) {
        let promise = httpV2.post(`/b2c/v1.0/goods/presale/country/check/${params.goodsId}`, params, this);
        return promise;
    }, 

    // 保存预售国家信息
    updatePresalesCountry({ state, commit }, params) {
        let promise = httpV2.post(`/b2c/v1.0/goods/presale/country/`, params, this);
        return promise;
    },
    // 获取 商品 预售国家信息
    getPresalesCountry({ state, commit }, params) {
        let promise = httpV2.get(`/b2c/v1.0/goods/presale/country/${params.goodsId}`, params, this);
        return promise;
    },	
    
 
    
};

export default {
    getters,
    state,
    mutations,
    actions
};
