import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    //获取BannerList

    getLogistics({ state, commit }, params) {
        let list = httpV2.get2('/b2c/v1.0/logistics/pricing/page/search', params, this);
        return list;
    },
    addLogistics({ state, commit }, params) {
        let list = httpV2.post('/b2c/v1.0/logistics/pricing', params, this);
        return list;
    },
    updateLogistics({ state, commit }, params) {
        let promise = httpV2.put(`/b2c/v1.0/logistics/pricing/${params.logisticsPricingId}`, params, this);
        return promise;
    },
    deleteLogistics({ state, commit }, params) {
        let promise = httpV2.delete(`/b2c/v1.0/logistics/pricing/${params}`, params, this);
        return promise;
    },
    getPriceSetting({ state, commit }, params) {
        let list = httpV2.get(`/b2c/v1.0/pricing/setting/logisticsPricingId/${params}`, params, this);
        return list;
    },
    updatePriceSetting({ state, commit }, params) {
        let promise = httpV2.put(`/b2c/v1.0/pricing/setting/${params.pricingSettingId}`, params, this);
        return promise;
    },
    listPriceSettings({ state, commit }, params) {
        let list = httpV2.get(`/b2c/v1.0/pricing/setting`, params, this);
        return list;
    },
    getFreight({ state, commit }, params) {
        let list = httpV2.get(`/b2c/v1.0/freight/fee/standard/logisticsPricingId/${params}`, params, this);
        return list;
    },
    addFreight({ state, commit }, params) {
        let list = httpV2.post('/b2c/v1.0/freight/fee/standard', params, this);
        return list;
    },
    updateFreight({ state, commit }, params) {
        let promise = httpV2.put(`/b2c/v1.0/freight/fee/standard/${params.freightFeeStandardId}`, params, this);
        return promise;
    },
    deleteFreight({ state, commit }, params) {
        let promise = httpV2.delete(`/b2c/v1.0/freight/fee/standard`, params, this);
        return promise;
    },
};

export default {
    getters,
    state,
    mutations,
    actions
};