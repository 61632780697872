//翻译国家枚举
export function enumeration(code) {
	let res = ''
	switch (code) {
		case 'CN':
			res = '中国'
			break;
		case 'GB':
			res = '英国'
			break;
		case 'DE':
			res = "德国"
			break;
		case 'PL':
			res = "波兰"
			break;
		case 'IT':
			res = '意大利'
			break;
		case 'PT':
			res = '葡萄牙'
			break;
		case 'FR':
			res = '法国'
			break;
		case 'NL':
			res = '荷兰'
			break;
		case 'ES':
			res = '西班牙'
			break;
	}
	return res;
}
export function enumerationLang(code) {
	let res = ''
	switch (code) {
		case 'zh':
			res = '汉语'
			break;
		case 'en':
			res = '英语'
			break;
		case 'de':
			res = "德国"
			break;
		case 'pl':
			res = "波兰"
			break;
		case 'it':
			res = '意大利'
			break;
		case 'pt':
			res = '葡萄牙'
			break;
		case 'fr':
			res = '法国'
			break;
		case 'nl':
			res = '荷兰'
			break;
		case 'es':
			res = '西班牙'
			break;
	}
	return res;
}
/**
 * 获取文件后缀名
 * @param filename
 * @returns {string}
 */
export function getSuffix(filename) {
  console.info(filename);
  let pos = filename.lastIndexOf(".");
  let suffix = "";
  if (pos != -1) {
    suffix = filename.substring(pos);
  }
  return suffix;
}
export function randomString(len=32) {
  let chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  let maxPos = chars.length;
  let pwd = "";
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}
export function blobToFile(blob, fileName) {
  blob.lastModifiedDate = new Date();
  blob.name = fileName;
  return blob;
}
export function hasPermissionMove(type) {
	switch (type) {
		/// 查看
		case 'View': return 1 << 0;
			break;
		/// 添加
		case 'Create': return 1 << 1;
			break;
		/// 编辑
		case 'Update': return 1 << 2;
			break;
		/// 删除	
		case 'Delete': return 1 << 3;
			break;
		/// 禁用	
		case 'Disabled': return 1 << 4;
			break;
		/// 上架	
		case 'PutAway': return 1 << 5;
			break;
		/// 下架	
		case 'SoldOut': return 1 << 6;
			break;
		/// 审核	
		case 'Approve': return 1 << 7;
			break;
		/// 导入	
		case 'Import': return 1 << 8;
			break;
		/// 导出	
		case 'Export': return 1 << 9;
			break;
		/// 处理	
		case 'Process': return 1 << 10;
			break;
		/// 回复	
		case 'Reply': return 1 << 11;
			break;
		/// 打印	
		case 'Print': return 1 << 12;
			break;
		/// 重试
		case 'Retry': return 1 << 13;
			break;
		/// 发布		
		case 'Publish': return 1 << 58;
			break;
		/// 回滚	
		case 'Rollback': return 1 << 59;
			break;
		/// 重启 	
		case 'Restart': return 1 << 60;
			break;
		/// 设置权限	
		case 'SetPermission': return 1 << 61;
			break;
		/// 绑定角色	
		case 'BindRole': return 1 << 62;
			break;
	}
}