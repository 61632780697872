import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {

    listGiftActivityPublicityChar({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/giftActivityPublicityChart", params, this);
        return promise;
    },

    saveGiftActivityPublicityChart({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/giftActivityPublicityChart", params, this);
        return promise;
    }

};

export default {
    getters,
    state,
    mutations,
    actions
};
