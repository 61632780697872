import httpV2 from '@/utils/httpV2';

const state = {
	
};
const getters = {};

const mutations = {};

const actions = {
	//获取BannerList
	
	getBanner({ state, commit }, params) {
	    let list = httpV2.get2('/b2c/v1.0/banner/page/search', params, this);
	    return list;
	},
	//banner排序
	putSortBanner({ state, commit }, params) {
	    let promise = httpV2.put(`/b2c/v1.0/banner/${params.id}/sort/${params.sort}`, params, this);
	    return promise;
	},
	//删除banner
	deleteBanner({ state, commit }, params) {
	    let promise = httpV2.delete(`/b2c/v1.0/banner/${params.id}`, params, this);
	    return promise;
	},
	addBanner({ state, commit }, params) {
	    let promise = httpV2.post('/b2c/v1.0/banner', params, this);
	    return promise;
	},
	updateBanner({ state, commit }, params) {
	    let promise = httpV2.put(`/b2c/v1.0/banner/${params.bannerId}`, params, this);
	    return promise;
	},
    //获取BannerList
    // getBannerPage({ state, commit }, params) {
    //     let list = httpV2.get2('/v1.0/banner_theme/pagelist', params, this);
    //     console.info(list);
    //     return list;
    // },
    getBannerDetail({ state, commit }, params) {
        let list = httpV2.get('/b2c/v1.0/banner_theme/get', params, this);
        return list;
    },
    bannerSave({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/banner_theme/save", params, this);
        return promise;
    },
    bannerDel({ state, commit }, params) {
        let promise = httpV2.delete("/b2c/v1.0/banner_theme/del", params, this);
        return promise;
    },
	postFormDataOss({ state, commit },{ params, url }) {
	    let promise = httpV2.postFormDataOss(url, params, this);
	    return promise;
	},

	getRebateList({ state, commit }, params) {
	    let promise = httpV2.get('/b2c/v1.0/shopping/rebate/page/search', params, this);
	    return promise;
	},
	postAddRebate({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/shopping/rebate", params, this);
        return promise;
    },
	delRebate({ state, commit }, params) {
        let promise = httpV2.delete(`/b2c/v1.0/shopping/rebate/${params.id}`, params, this);
        return promise;
    },
	getRebate({ state, commit }, params) {
	    let promise = httpV2.get(`/b2c/v1.0/shopping/rebate/${params.id}`, params, this);
	    return promise;
	},
	postUpdateRebate({ state, commit }, params) {
        let promise = httpV2.put(`/b2c/v1.0/shopping/rebate/${params.shoppingRebateId}`, params, this);
        return promise;
    },
	putTurntoFormal({ state, commit }, params) {
        let promise = httpV2.put(`/b2c/v1.0/mgr/order/turnto/formal/batch`, params, this);
        return promise;
    },
	
};

export default {
    getters,
    state,
    mutations,
    actions
};
