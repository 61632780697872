import plLocal from 'element-ui/lib/locale/lang/pl';
const pl = {
    ...plLocal,
    common: {
        datastatistics: 'Statystyki',
        Transactionstatistics: 'Statystyki transakcji',
        LabelOptions: 'Opcje etykiet',
        Orderdatastatistics: 'Statystyki zamówień',
        Hourlystatistics: 'według godziny',
        Countbyday: 'według dnia',
        Spain: 'Hiszpania',
        Italy: 'Włochy',
        Germany: 'Niemcy',
        France: 'Francja',
        poland: 'Polska',
        Netherlands: 'Holandia',
        Britain: 'Wielka Brytania',
        UnitedStates: 'USA',
        Portugal: 'Portugalia',
        SalesCountry: 'Kraj sprzedaży',
        query: 'Zapytanie',
        Placinganorder: 'Ilość w zamówieniu',
        Orderamount: 'Kwota zamówienia',
        Numberofusersplacingorders: 'Liczba użytkowników składających zamówienia',
        Ordertime: 'Czas złożenia zamówienia',
        time: 'Czas',
        usermanagement: 'Zarządzanie użytkownikami',
        UserID_loginemail_registrationinviterID_phonenumber: 'Identyfikator użytkownika / e-mail logowania / zarejestrowany identyfikator zaproszonego / numer telefonu komórkowego',
        country: 'Kraj',
        Whethertosubscribe: 'Subskrybować, czy nie?',
        Loginstatus: 'Status logowania',
        normal: 'Normalny',
        Disabled: 'Wyłącz',
        Createdon: 'data utworzenia',
        Lastlogintime: 'Czas ostatniego logowania',
        Reset: 'Reset',
        export: 'Eksportuj',
        UserID: 'Identyfikator użytkownika',
        Registeredcountry: 'Kraj rejestracji',
        mailbox: 'E-mail',
        Mobilephonenumber: 'Numer telefonu komórkowego',
        Patrioticcoin: 'Aigo Coins',
        RegistrationInviterID: 'Zarejestrowany identyfikator zapraszającego',
        brand: 'Marka',
        Creationtime: 'Czas utworzenia',
        operation: 'Operacja',
        check: 'Wyświetl',
        orderform: 'Zamówienia',
        consultingservice: 'Zapytanie',
        address: 'Adres',
        LoginLog: 'Dziennik logowania',
        Rechargepatrioticcoins: 'Doładuj Aigo Coins',
        Returnsandexchanges: 'Zwroty i wymiany',
        evaluate: 'Oceń',
        Shippingaddress: 'Adres odbioru',
        shoppingcart: 'Koszyk',
        collection: 'kolekcja',
        invoice: 'Faktura',
        nickname: 'Pseudonim',
        nothing: 'Brak',
        close: 'Zamknij',
        source: 'Źródło',
        Subscriptiontime: 'Czas subskrypcji',
        Thirdpartylogin: 'Login za pośrednictwem strony trzeciej',
        Bound: 'Powiązany',
        Unbound: 'Bez ograniczeń',
        Invitationcode: 'Kod zaproszenia',
        Inviteduser: 'Zaproszeni użytkownicy',
        Invitetoreceivepatrioticcoins: 'Zaproś, aby otrzymać Aigo Coins',
        Isthefirstorderfreeofshipping: 'Czy chcesz skorzystać z darmowej przesyłkim przy swoim pierwszym zamówieniu?',
        shopping: 'Zakupy',
        Collectproducts: 'Ulubione przedmioty',
        ShoppingCartItems: 'Produkty z koszyka',
        PaymentOrder: 'Opłacone zamówienia',
        Ordercumulativeamount: 'Całkowita kwota zamówienia',
        Lastordertime: 'Czas ostatniego zamówienia',
        Returnandexchangeorders: 'Zwroty i wymiany zamówień',
        Consultationfrequency: 'częstotliwość zapytań',
        Numberofevaluations: 'liczba recenzji',
        Numberofapprovedevaluations: 'liczba zatwierdzonych recenzji',
        ShowCountry: 'Pokaż kraj',
        name1: 'Nazwa',
        TaxID: 'Kod podatkowy',
        province: 'Prowincja',
        city: 'Miasto',
        Detailedaddress: 'szczegółowy adres',
        Housenumber: 'numer domu',
        AddressAdditionalContent: 'Dodatkowe informacje adresowe',
        RecipientCompany: 'Firma odbiorcy',
        Defaultshippingaddress: 'Domyślny adres wysyłki',
        Defaultinvoiceaddress: 'Domyślny adres do faktury',
        ProductID1: 'Identyfikator produktu',
        Productname1: 'Nazwa produktu',
        CollectionPrice: 'Cena kolekcji',
        Currentprice: 'Aktualna cena',
        Collectiontime1: 'Czas odbioru',
        Additionalpurchaseprice: 'cena produktów dodanych do listy życzeń',
        Additionalpurchasetime: 'czas dodania produktów do listy życzeń',
        Listingstatus: 'status półki',
        Listed: 'umieszczony na półce',
        Notlisted: 'nie na półce',
        Numberofpatrioticcoins: 'Liczba Aigo Coins',
        Enteraquantitybetween0and1000: 'Wprowadź liczbę od 0 do 1000',
        Remarks: 'Uwagi',
        cancel: 'Anuluj',
        UserDetails: 'Dane użytkownika',
        Areyousuretoexportthefile: 'Czy chcesz potwierdzić eksport pliku, czy chcesz kontynuować?',
        prompt: 'Podpowiedź',
        Exportsuccessful: 'Eksport powiódł się',
        today: 'dzisiaj',
        Lastweek: 'Ostatni tydzień',
        Thismonth: 'Ten miesiąc',
        Lastmonth1: 'Ostatni miesiąc',
        thisyear: 'Ten rok',
        Inputcannotbeempty: 'Pole nie może być puste',
        Pleaseenterapositiveinteger: 'Wprowadź dodatnią liczbę całkowitą',
        Pleaseenterthequantityofpatrioticcoins: 'Wprowadź liczbę Aigo Coins',
        Pleaseenteracomment: 'Wprowadź komentarz',
        Pleaseselectacountry: 'Wybierz kraj',
        Successfullysubmitted: 'Przesłano pomyślnie',
        Nopatrioticcoinrulesconfigured: 'Nie skonfigurowano reguł Aigo Coins',
        Rechargefailed: 'doładowanie nie powiodło się',
        Savefailed: 'zapisanie nie powiodło się',
        ordermanagement: 'Zarządzanie zamówieniami',
        OrderList: 'Lista zamówień',
        Ordernumber: 'Numer zamówienia',
        Paymentcode: 'Kod płatności',
        barcode: 'Kod kreskowy',
        Pleaseselectasource: 'Wybierz źródło',
        OrderStatus: 'Status zamówienia',
        Unpaid: 'nieopłacone',
        Paid: 'opłacone',
        Shippable: 'Dostępne do dostawy',
        Shipped: 'Wysłane',
        complete: 'Zakończone',
        Pleaseselectpaymentmethod: 'Wybierz sposób płatności',
        Shipmentstatus: 'Status wysyłki',
        Notshipped: 'Nie wysłano',
        Partialshipment: 'Częściowo wysłano',
        Returned: 'Zwrócono',
        Remittanceconfirmation: 'Przelew potwierdzony',
        Notremitted: 'Brak przelewu',
        Unconfirmed: 'niepotwierdzony',
        adopt: 'Zatwierdzono',
        Notpassed: 'nie zatwierdzono',
        Whethertomanuallyship: 'Dostawa ręczna, czy nie',
        yes: 'Tak',
        no: 'Nie',
        Originalordertype: 'Typ oryginalnego zamówienia',
        Presaleorder: 'Zamówienie w przedsprzedaży',
        Officialorder: 'Oficjalne zamówienie',
        Preorderstatus: 'status zamówienia w przedsprzedaży',
        Deposittobepaid: 'Depozyt do wpłacenia',
        Depositpaid: 'Wpłacony depozyt',
        Finalpaymenttobemade: 'saldo do zapłaty',
        Finalpaymentpaid: 'saldo zapłacone',
        Presalepaymentmethod: 'sposób płatności za produkt w przedsprzedaży',
        Finaldeposit: 'Końcowa płatność depozytu',
        Fullpayment: 'Pełna płatność',
        Whetherthepresaleorderisconvertedtoformal: 'Czy przenieść zamówienie w przedsprzedaży do oficjalnego zamówienia?',
        Combinedorders: 'Zamówienie łączone',
        to: 'Do',
        Startdate: 'Data rozpoczęcia',
        Enddate: 'Data zakończenia',
        Paymenttime: 'czas płatności',
        Pleaseenterthesource: 'Wprowadź źródło',
        platform: 'Platforma',
        Positiveconversionsingle: 'Przenieś do oficjalnego zamówienia',
        Numberofproductmodels: 'Liczba przedmiotów',
        activity: 'Aktywność',
        money: 'Kwota',
        Paymentmethod: 'metoda płatności',
        Deliverytime: 'Czas wysyłki',
        Whethertotransfertoformal: 'czy przenieść do oficjalnego?',
        Tobereplied_consulted: 'do odpowiedzi/konsultacji',
        detailed: 'Szczegóły',
        Manualshipping: 'Dostawa ręczna',
        Manuallogisticsshipment: 'Ręczna dostawa logistyczna',
        logisticscompany: 'Firma logistyczna',
        Pleaseselectalogisticscompany: 'Wybierz firmę logistyczną',
        Logisticstrackingnumber: 'Numer przesyłki logistycznej',
        SelectDateTime: 'Wybierz datę i godzinę',
        Shippingremarks: 'Uwagi dotyczące wysyłki',
        Pleasenote: 'Uwaga',
        confirm1: 'Potwierdź',
        state: 'Status',
        Pleaseselect: 'Wybierz',
        Pleaseselectastatus: 'Wybierz status',
        Homepagerecommendation: 'Zalecana strona główna',
        Pleaseenterthelogisticstrackingnumber: 'Wprowadź numer przesyłki',
        Pleaseselecttheshippingtime: 'Wybierz czas wysyłki',
        edit: 'Edytuj',
        Successfullyshippedmanually: 'Ręczna dostawa powiodła się',
        Remittanceconfirmationsuccessful: 'Potwierdzenie przelewu powiodło się',
        confirmdeletion: 'Potwierdź usunięcie',
        Successfullydeleted: 'Usunięto pomyślnie',
        Pleaseselectapresaleorder: 'Wybierz zamówienie przedsprzedażowe',
        Confirmtoconverttheorderintoaformalorder: 'Potwierdzenie zmiany zamówienia na oficjalne zamówienie',
        success: 'Sukces',
        OrderData: 'Dane zamówienia',
        Areyousuretoexportthefileandcontinue: 'Potwierdź, czy eksportować plik i czy kontynuować',
        Basicinformationoftheorder: 'Podstawowe informacje o zamówieniu',
        Useremail: 'E-mail użytkownika',
        Documentremarks: 'Uwagi dotyczące zamówienia',
        combination: 'Kombinacja',
        PostalCode: 'Kod pocztowy',
        RecipientCompanyAddressAdditionalContent: 'Firma odbiorcy, adres, treść dodatkowa',
        Invoiceaddress: 'Adres faktury',
        InvoiceStatus: 'Status faktury',
        Paymentinformation: 'Informacje o płatności',
        Paymentstatus: 'Status płatności',
        coupon: 'Kupon',
        freight: 'fracht',
        CouponName: 'Nazwa kuponu',
        Couponcode: 'Kod kuponu',
        Paymentamount: 'Kwota płatności',
        Trackingnumber: 'Numer śledzenia zamówienia',
        Manualshippingoperator: 'Operator wysyłki ręcznej',
        Manualshippingoperationtime: 'Czas operacji dostawy ręcznej',
        Bankremittance: 'Przelew bankowy',
        Remittancestatus: 'Status przelewu',
        Remittanceconfirmationoperator: 'Operator potwierdzenia przelewu',
        Remittanceconfirmationoperationtime: 'Czas potwierdzenia przelewu',
        Preorderinformation: 'Informacje o zamówieniu przedsprzedażowym',
        Converttoformaldocument: 'czy przenieść do oficjalnego zamówienia?',
        Positiveconversionsinglemode: 'sposób przeniesienia do oficjalnego zamówienia',
        automatic: 'automatyczny',
        Manual: 'ręczny',
        Positiveconversionsingletime: 'czas przeniesienia do oficjalnego zamówienia',
        category1: 'kategoria',
        Subordernumber: 'numer pod-zamówienia',
        Paymentdeadline: 'termin płatności',
        Addremarks: 'dodaj uwagi',
        ProductInformation: 'informacje o produkcie',
        Serialnumber: 'numer seryjny',
        name: 'nazwa',
        VariantInformation: 'Informacje o wariancie',
        Pricing_unitprice: 'Cena (cena jednostkowa)',
        Sellingprice_unitprice: 'Cena sprzedaży (cena jednostkowa)',
        quantity: 'Ilość',
        Totalprice: 'Cena całkowita',
        MerchandiseSubtotal: 'Suma częściowa towarów',
        total: 'Razem',
        CancelOrder: 'anuluj zamówienie',
        Areyousuretocancelthisorder: 'Czy na pewno chcesz anulować zamówienie?',
        reason: 'Powód',
        Pleaseselectabrand: 'Wybierz markę',
        Pleaseselectacategory: 'Wybierz kategorię',
        Successfullyadded1: 'Dodano pomyślnie',
        Addfailed: 'Nie udało się dodać',
        Whethertosubmitdata: 'Czy przesłać dane?',
        Canceledsuccessfully: 'Anulowano pomyślnie',
        Onlypendingorderscanbeshipped: 'Tylko oczekujące zamówienia mogą zostać wysłane',
        Existinglogisticstrackingnumber: 'Zamówienie ma numer śledzenia logistycznego i nie można go przesłać.',
        Thereareonlytworemittancestatuses: 'Status przelewu może być tylko zatwierdzony lub niezatwierdzony.',
        Onlyordersthathavebeenremittedcanbeconfirmed: 'Potwierdzić można tylko zamówienia za które wykonano przelew',
        Orderdoesnotexist: 'Zamówienie nie istnieje',
        Thisordercannotbecancelled: 'Zamówienie nie może zostać anulowane',
        Norulesconfigured: 'Bieżący najemca nie ma skonfigurowanych reguł Aigo Coins.',
        Parametererror: 'błąd parametrów',
        Countrycodecannotbeempty1: 'Kod kraju nie może być pusty',
        Thecurrentcountrypricingisnotconfigured: 'Ceny w bieżącym kraju nie są skonfigurowane',
        Thepriceconfigurationdoesnotexist: 'Konfiguracja ceny nie istnieje, skonfiguruj cenę',
        Reasonforcancellationoforderisrequired: 'Wymagany powód anulowania zamówienia',
        Exportorderinformation: 'eksportuj informacje o zamówieniu',
        OrderIDmustbepassed: 'Identyfikator zamówienia musi zostać przekazany',
        Youcanonlybatchprocessupto100itemsatatime: 'Przetwarzanie grupowe do 100 zamówień jednocześnie',
        Notapresaleorder: 'Zamówienie numer 101 nie jest zamówieniem w przedsprzedaży, odznacz je najpierw.',
        Transferredtoofficial: 'Zamówienie numer 101 zostało przeniesione do oficjalnego zamówienia, odznacz je najpierw',
        Ordernotpaid: 'Zamówienie numer 101 nie jest zamówieniem opłaconym, anuluj je najpierw',
        Nonpresaleorderscannotbeoperated: 'Zamówienia niebędące w przedsprzedaży nie są dostępne',
        Transferredtoformalformandcannotbeoperated: 'Zamówienia przeniesione nie są dostępne',
        Nonpaidorderscannotbeoperated: 'Niedostępne dla zamówień nieopłaconych',
        Orderremarksarerequired: 'Wymagane są uwagi dotyczące zamówienia',
        Upto500wordscanbeentered: 'Maksymalnie 500 znaków',
        common: 'w sumie',
        strip: 'rekordy',
        goto: 'Przejdź do',
        page: 'Strona',
        Returnandexchangeapplication: 'Żądanie zwrotu',
        type: 'Typ',
        Return: 'Zwrot',
        Exchange: 'Wymiana',
        Auditstatus: 'Status przeglądu',
        Approved: 'Zatwierdzony',
        Auditfailed: 'Nieudany',
        Inprogress: 'w toku',
        Auditended: 'Koniec audytu',
        Processingstatus: 'Status przetwarzania',
        Completed: 'Zakończono',
        Applicationtime: 'Czas aplikacji',
        Aftersalestype: 'Typ posprzedaży',
        ApplicationDescription: 'Opis aplikacji',
        Numberofexplanatoryimages: 'Opis liczby zdjęć',
        Illustrativeimage: 'Opis zdjęć',
        Returnlogisticstrackingcode: 'Kod śledzenia logistyki zwrotów',
        CanIcontinuetoapply: 'Czy kontynuować aplikację',
        Auditing: 'Audyt',
        details: 'Szczegóły',
        handle: 'Przetwarzaj',
        Add1: 'Dodaj',
        ApplicationDetails: 'Złóż wniosek o szczegóły',
        Exchangeitem: 'Wymień towary',
        Returnedgoods: 'Zwróć towary',
        Audittime: 'Czas przeglądu',
        Reviewedby: 'Sprawdzone przez',
        result: 'Wynik',
        Failed: 'Nie zatwierdzono',
        Thereplycontentwillbedisplayedtotheuser: 'Odpowiedź zostanie wyświetlona użytkownikom',
        Submit: 'Prześlij',
        Replycontent: 'treść odpowiedzi',
        Operator: 'Operatorzy',
        Pleaseenterthereason: 'Wprowadź powód',
        Pleasechoosewhethertocontinueapplying: 'Wybierz, czy możesz kontynuować składanie wniosku',
        Pleaseselectaresult: 'Wybierz wynik',
        Successfullymodified: 'Modyfikacja powiodła się',
        Verificationfailed: 'Weryfikacja nie powiodła się',
        Datadoesnotexist: 'Dane nie istnieją',
        OrderNumber_UserID: 'Numer zamówienia/identyfikator użytkownika',
        ReplyStatus: 'Status odpowiedzi',
        Replied: 'Odpowiedziano',
        Noresponse: 'Brak odpowiedzi',
        MyOrder: 'Moje zamówienie',
        refund: 'Zwrot pieniędzy',
        Canwedeliverontheagreeddate: 'Czy możesz dostarczyć w uzgodnionym terminie?',
        Modifyshippingaddress: 'Zmień adres wysyłki',
        Otherissuesrelatedtotransportation: 'Inne pytania dotyczące wysyłki',
        Receivedincorrectproduct: 'Otrzymałem niewłaściwy produkt',
        Receiveddamagedproductsandproductswithqualityissues: 'Otrzymałem uszkodzone produkty lub produkty z problemami jakościowymi',
        RegulationsonReturnsandRefunds: 'Przepisy dotyczące zwrotów i refundacji',
        Applyforinvoice: 'Poproś o fakturę',
        productdetails1: 'Szczegóły o produkcie',
        Thankyouletter: 'list z podziękowaniami',
        Otherissues: 'Inne pytania',
        Respondent: 'Odpowiedz do',
        Consultationdate: 'Data zapytania',
        content: 'Treść',
        explain: 'Opis',
        Consultationtime: 'Czas konsultacji',
        Replytime: 'Czas odpowiedzi',
        reply: 'Odpowiedź',
        OrderInformation: 'Informacje o zamówieniu',
        Consultationcontent: 'Treść zapytania',
        Consultationinstructions: 'Instrukcje dotyczące zapytania',
        EnterReply: 'Wprowadź odpowiedź',
        Consultationrecords: 'Historia zapytań',
        Replycontentisrequired: 'Treść odpowiedzi jest wymagana',
        Replysuccessful: 'Odpowiedz została wysłana pomyślnie',
        Successfullyedited: 'Edytowano pomyślnie',
        Ordernumber_UserID_Taxnumber: 'Numer zamówienia/identyfikator użytkownika/kod podatkowy',
        Unaudited: 'Niezatwierdzone',
        Passed: 'Zatwierdzone',
        Notuploaded: 'Nie przesłano',
        Uploaded: 'Przesłano',
        AuditDescription: 'Instrukcje dotyczące recenzji',
        download: 'Pobierz',
        Applying: 'Aplikacja w toku',
        Enterthereason: 'Wprowadź powód - musisz podać powód, jeśli wniosek nie został zatwierdzony.',
        Thereasonfornotpassingwillbedisplayedtotheuser: 'Powody niezatwierdzenia zostaną wyświetlone użytkownikowi.',
        Pleaseenterareply: 'Wprowadź odpowiedź',
        Auditsuccessful: 'Recenzja zakończona sukcesem',
        ProductManagement: 'Zarządzanie produktem',
        Categorymanagement: 'Zarządzanie kategoriami',
        Categoryname1: 'Nazwa kategorii',
        Isthehomepagedisplayed: 'Czy wyświetić stronę główną?',
        Enableornot: 'Włącz lub nie',
        search: 'Wyszukaj',
        Directoryhierarchy: 'Poziom katalogu',
        Category: 'Kategoria',
        sort: 'Sortuj według',
        picture: 'Obraz',
        Updatetime: 'Czas aktualizacji',
        Multilingualconfiguration: 'Konfiguracja wielojęzyczna',
        Relatedimportantparameters: 'Powiązane ważne parametry',
        Associatesearchparameters: 'Powiązane parametry wyszukiwania',
        delete: 'Usuń',
        SelectAll: 'Wybierz wszystko',
        Imagesize: 'Rozmiar obrazu',
        icon: 'Ikony',
        intotal25: 'Łącznie 25 elementów',
        Gotopage1: 'Przejdź do strony 1',
        Chinese: 'chiński',
        English: 'angielski',
        German: 'niemiecki',
        Polishlanguage: 'polski',
        Italian: 'włoski',
        French: 'francuski',
        Dutchlanguage: 'niderlandzki',
        Spanish: 'hiszpański',
        Pleaseenteraname: 'Wprowadź nazwę',
        Areyousureyouwanttodeleteit: 'Czy chcesz usunąć?',
        Categoryassociation: 'Powiązanie kategorii',
        PrimaryKeyID: 'Identyfikator klucza głównego',
        CatalogID: 'Identyfikator katalogu',
        Addtime: 'Dodaj czas',
        Relatedcategories: 'Powiązana kategoria',
        Selectmaincategory: 'Wybierz kategorię główną',
        addassociations: 'Dodaj powiązanie',
        Maincategory: 'Kategoria główna',
        ProductCatalogID: 'Identyfikator katalogu produktów',
        RelatedCategoryName: 'Nazwa powiązanej kategorii',
        AddAssociatedCategory: 'Dodaj powiązaną kategorię',
        Pleaseselectacategoryfirst1: 'Najpierw wybierz kategorię',
        Productvariants: 'Wariant produktu',
        ID: 'ID',
        Add: 'Dodaj',
        Productquantity: 'Liczba produktów',
        Selectvariant: 'Wybierz wariant',
        Selectparameters: 'Wybierz parametry',
        commodity: 'Produkt',
        Selectproduct: 'Wybierz produkt',
        Colortemperature: 'Temperatura barwowa',
        frequency: 'Częstotliwość',
        power: 'Moc',
        style: 'Styl',
        MoveDown: 'Przesuń w dół',
        MoveUp: 'Przesuń w górę',
        Selectvariantparameters: 'Wybierz parametry wariantu',
        SKU_EAN_Encoding: 'SKU/EAN/kod',
        Pleaseselectparameters: 'Wybierz parametry',
        Countrieslisted: 'kraje, w których produkty są umieszczane na półce',
        ProductID: 'Identyfikator produktu',
        Productimage1: 'Obraz produktu',
        ProductName: 'Nazwa produktu',
        VariantManagement: 'Zarządzanie wariantami',
        Class: 'Kategoria',
        Selected: 'Wybrano',
        Thereiscurrentlynodataavailable: 'Brak dostępnych danych',
        Variantparameters: 'Parametry wariantu',
        VariantName: 'Nazwa wariantu',
        CustomName: 'Nazwa niestandardowa',
        Batchlisting: 'Umieść partię na półce',
        Pleaseselectaproduct: 'Wybierz produkty',
        Batchdelisting: 'Grupowo wycofaj z listy',
        Batchsearch: 'Wyszukiwanie partii',
        Enterproductbarcodeandsubmitforaddition: 'Wprowadź kod kreskowy produktu, aby przesłać dodatki, wiele kodów kreskowych należy wprowadzić w nowej linii',
        Enterproductbarcode: 'Wprowadź kody kreskowe produktów, wiele kodów kreskowych należy wprowadzić w nowej linii do 500',
        Pleaseenterbarcode: 'Wprowadź kod kreskowy',
        Editlistingcountry: 'edytuj kraje, w których produkty są umieszczane na półce',
        Pleasecompletetheproductinformationbeforelistingit: 'Uzupełnij informacje o produkcie przed umieszczeniem go na półce',
        ProductTitle: 'Tytuł produktu',
        ProductLargePicture: 'Obraz produktu',
        commodityprice: 'Cena produktu',
        Confirmtheexecutionofbatchdelisting: 'Potwierdź masowe usunięcie z listy',
        EditCategory: 'Edytuj kategorię',
        SynchronizeSKU: 'Synchronizuj jednostki SKU',
        ConfirmsynchronizationofSKU: 'Potwierdź synchronizację SKU',
        SynchronizationrequiresdockingwithEBP: 'Przez to, że synchronizacja musi być wykonana z EBP, aby uniknąć błędów, synchronizacja masowa może być wykonywana raz na 30 minut. Prosimy o cierpliwość, ponieważ synchronizacja zostanie przeprowadzona asynchronicznie po potwierdzeniu, a jednostki SKU zostaną wyświetlone, gdy tylko będą dostępne.',
        Synccompleted: 'Synchronizacja zakończona',
        Setlabel: 'Skonfiguruj tagi',
        label: 'Tagi',
        SynchronizeEBPproductinformation: 'Synchronizacja informacji o produktach EBP',
        Afterturningonsynchronization: 'Gdy synchronizacja jest włączona, następujące informacje o produktach EBP są synchronizowane według SKU produktu',
        Synchronizeproducttitlesbylanguage: 'Tytuł produktu: zsynchronizuj tytuł odpowiedniego języka według języka.',
        Synchronizeproductimagesbylanguage: 'Obraz produktu: zsynchronizuj obraz w odpowiednim języku według języka, odczytaj sortowanie obrazów EBP, pierwszy obraz EBP jest automatycznie ustawiany jako obraz główny.',
        Synchronizeproductimagesandtextbylanguage: 'Szczegóły obrazu: zsynchronizuj według języka dla odpowiedniego języka',
        Productkeywords: 'Słowa kluczowe produktu',
        Automaticallysynchronizeornot: 'Czy synchronizować automatycznie',
        Doyouwanttoforcesynchronization: 'Wymuś synchronizację lub nie',
        tenant: 'Najemcy',
        Noinventorydisplay: 'Brak wyświetlania spisu',
        Synchronizetoothertenants: 'Synchronizuj z innymi najemcami',
        UpdateRecords: 'Aktualizuj rejestry',
        Importtime: 'Czas importu',
        number: 'Liczba',
        file: 'Plik',
        UpdateResults: 'Zaktualizuj wynik',
        Downloadfiles: 'Pobierz plik',
        Isthereafixedprice: 'Czy jest ustalona cena?',
        Whethertopromoteornot: 'Czy jest na wyprzedaży?',
        Inpromotion: 'Na wyprzedaży',
        whole: 'Wszystkie',
        Fixedprice1: 'Stała cena',
        Lowestprice: 'Najniższa cena',
        fixedprice: 'Stała cena',
        Promotionprice_unitprice: 'Cena promocyjna (cena jednostkowa)',
        price1: 'Cena sprzedaży',
        inventory: 'inwentaryzacja',
        Inventorybottomline: 'podsumowanie zapasów',
        AutomaticallysynchronizeEBP: 'Czy automatycznie synchronizować EBP',
        ForcesynchronizationofEBP: 'Czy wymusić synchronizację EBP',
        Presaleornot: 'Czy jest w przedsprzedaży?',
        Evaluation_Shown_total: 'Recenzje (wyświetlane/ogółem)',
        coding: 'Kod',
        ProductDetails: 'Szczegóły produktu',
        EditProduct: 'Edytuj produkt',
        Basicinformation: 'Podstawowe informacje',
        price: 'Cena',
        Pleaseentertheproducttitle: 'Wprowadź tytuł produktu',
        Sizeinformation: 'Informacje o rozmiarze',
        weight: 'Waga',
        volume: 'Objętość',
        Salesmultiples: 'Mnożnik sprzedaży',
        Numberofitems: 'Liczba przedmiotów',
        EditFixedPrice: 'Edytuj stałą cenę',
        Largeimage: 'Duży obraz',
        Uploadimages: 'Prześlij zdjęcie',
        UploadVideo: 'Prześlij wideo',
        Batchdisplay: 'Wyświetlanie grupowe',
        BatchCloseDisplay: 'Zamknij wyświetlanie partii',
        Batchdeletion: 'Usuń partię',
        Synchronizetootherlanguages: 'Synchronizuj z innymi językami',
        Selectfrompicturelibrary: 'Wybierz z galerii obrazów',
        Pleaseenterthemaintext: 'Wprowadź tekst',
        Hideemptyparameters: 'Ukryj puste parametry',
        Detailedcontentofgraphicsandtext: 'Najpierw wprowadź szczegóły grafiki w języku angielskim',
        parameter: 'Parametr',
        Parametervalue: 'Wartość parametru',
        ParameterProperties: 'Właściwości parametru',
        Pleaseenteraparametername: 'Wprowadź nazwę parametru',
        Pagekeywords: 'Słowo kluczowe strony',
        PageDescription: 'Opis strony',
        Pleaseentercontent: 'Wprowadź treść',
        PictureLanguage: 'Język obrazu',
        Pictures_Videos: 'Obraz/wideo',
        ImageID: 'Identyfikator obrazu',
        language: 'Język',
        size: 'Rozmiar',
        Selectapictureorvideo: 'Najpierw wybierz zdjęcie lub film w galerii!',
        Selectionsuccessful: 'Wybrano pomyślnie',
        Isitthemainimage: 'Główny obraz, czy nie',
        Displayornot: 'Pokazać?',
        Whethertodisplayall: 'Pokazać wszystko?',
        Areallclosed: 'Czy zamknąć wszystko',
        Confirmdeletionoftheselectedpicture_video: 'Potwierdź, aby usunąć wybrany obraz/wideo',
        ProductReview: 'Komentarze dotyczące produktu',
        PromotionName_ProductID_Barcode: 'Nazwa promocji/identyfikator produktu/kod kreskowy',
        PromotionCountry: 'Kraj promocji',
        Activitystatus: 'Status wydarzenia',
        begininaminute: 'Rozpocznie się wkrótce',
        Ended: 'Zakończone',
        Isitaflashkill: 'Czy jest to oferta ograniczona czasowo?',
        PromotionName: 'Nazwa promocji',
        CommentID: 'Identyfikator komentarza',
        RatingStarRating: 'Ocena gwiazdkowa',
        star0: 'gwiazdka',
        star1: '1 gwiazdka',
        stars2: '2 gwiazdki',
        stars3: '3 gwiazdki',
        stars4: '4 gwiazdki',
        stars5: '5 gwiazdek',
        Commentstartdate: 'data początkowa komentarzy',
        CommentEndDate: 'data zakończenia komentarzy',
        Batchreview: 'Przegląd partii',
        Productimage: 'Obrazy produktów',
        Evaluationcontent: 'treść komentarza',
        video: 'Wideo',
        ReviewerId: 'Identyfikator recenzenta',
        user: 'Użytkownik',
        Pendingreview: 'Do sprawdzenia',
        Evaluationtime: 'czas komentarza',
        ReviewerName: 'Imię i nazwisko recenzenta',
        Numberofselectedcomments: 'liczba wybranych recenzji',
        Picturepreview: 'Podgląd obrazu',
        VideoPreview: 'Podgląd wideo',
        Lastmonth: 'Ostatni miesiąc',
        LastThreeMonths: 'Ostatnie trzy miesiące',
        Areyousuretodelete: 'potwierdzić usunięcie?',
        Pleaseselectacomment: 'Wybierz recenzję',
        Amazon: 'Amazon',
        Aigostarbrandnetwork: 'sieć marki aigostar',
        marketingmanagement: 'Zarządzanie marketingiem',
        Productrecommendations: 'Rekomendacja produktu',
        Recommendedlocation: 'Poleć lokalizację',
        Categoryrecommendation: 'Rekomendacja kategorii',
        Recommendationmodule: 'Poleć moduł',
        NewProductZone: 'Nowości',
        HotSellingZone: 'Gorące produkty',
        Recommendedforyou1: 'Polecane dla Ciebie',
        Numberofproducts: 'Liczba produktów',
        Addproduct: 'Dodaj produkt',
        DirectoryName: 'Nazwa katalogu',
        title: 'Tytuł',
        ContentTitle: 'Tytuł zawartości',
        EnablePlatform: 'Włącz platformę',
        DisplayPlatform: 'wyświetl platformę',
        catalogue: 'Katalog',
        JumpAddress: 'Przejdź do adresu',
        PageTitle: 'Tytuł strony',
        CategoryName: 'Nazwa kategorii',
        Cancelleddeletion: 'anulowano usunięcie',
        Pleaseselectacategoryfirst: 'Najpierw wybierz kategorię',
        TheEnglishcategorynamecannotbeempty: 'Angielska nazwa kategorii nie może być pusta',
        Successfullysaved: 'Zapisano pomyślnie',
        DeleteError: 'Błąd usuwania',
        Apieceofcontentalreadyexists: 'Zawartość już istnieje',
        Pleaseselecttherecommendedlocation: 'Wybierz zalecaną lokalizację',
        Pleaseselecttherecommendedmodule: 'Wybierz moduł rekomendacji',
        remove: 'Usuń',
        Successfullyadded2: 'Dodano pomyślnie',
        Areyousuretoremovethisproduct: 'Czy na pewno chcesz usunąć ten produkt?',
        Successfullyremoved: 'Usunięto pomyślnie',
        Dataalreadyexists: 'Dane już istnieją',
        PortfolioManagement: 'Zarządzanie portfolio',
        Combinationname: 'Nazwa kombinacji',
        Remainingquantity_combinedquantity: 'pozostała ilość / pozostała łączna ilość',
        starttime: 'Czas rozpoczęcia',
        Endtime: 'Czas zakończenia',
        Creator: 'Utworzone przez',
        NationalSDPDictionary: 'krajowy słownik sdp',
        Combinationtime: 'Czas kombinacji',
        Combinationquantity: 'ilość kombinacji',
        CanIusecoupons: 'czy kupony mogą być użyte?',
        Mainimage: 'Główny obraz',
        Setcombinationdiscount: 'Ustaw rabat kombinowany',
        Combinationprice: 'Cena kombinacji',
        Combinedtotalprice: 'Całkowita cena kombinacji',
        Isitontheshelf: 'Czy jest na półce',
        Selectupto5: 'Wybierz do 5',
        Promotionprice1: 'Cena promocyjna',
        Pleaseenterthecombinationquantity: 'Wprowadź liczbę kombinacji',
        Pleaseenteracombinationname: 'Wprowadź nazwę kombinacji',
        Upto100wordscanbeentered: 'Wprowadź do 100 znaków',
        Pleaseselectadaterange: 'Wybierz zakres dat',
        Pleaseselectastartdate: 'Wybierz datę początkową',
        Pleaseselecttheenddate: 'Wybierz datę końcową',
        Pleasechoosewhethertousecoupons: 'Wybierz, czy chcesz użyć kuponu',
        Pleaseselect25items: 'Wybierz 2-5 produktów',
        Pleaseselectaproductthathasbeenlisted: 'Wybierz artykuł, który został umieszczony na półkach.',
        Inventoryofselecteditems: 'Mnożnik zapasów/sprzedaży wybranego artykułu nie może być mniejszy niż ilość aktywności.',
        Combinationpricecannotbeempty: 'Cena kombinacji nie może być pusta',
        Caninput0andpositiveintegers: 'Można wprowadzić 0 i dodatnie liczby całkowite, symbole można wprowadzać tylko jako % i ., Do dwóch miejsc po przecinku',
        Presaleproductscannotbeadded: 'Jest to pozycja przedsprzedażowa i nie może zostać dodana jako kombinacja.',
        Pleaseselectaproductfirst: 'Najpierw wybierz produkt',
        Freeshippingonfirstorder: 'Darmowa wysyłka przy pierwszym zamówieniu',
        Firstfreeshippingitem: 'bezpłatna wysyłka towarów przy pierwszym zamówieniu',
        SelectedProduct: 'Wybrany produkt',
        Between1and50charactersinlength: 'Długość od 1 do 50 znaków',
        Thisoperationwillremovethefile: 'Ta operacja usunie plik, czy chcesz kontynuować?',
        Brandandcountrycannotbeempty: 'Marka i kraj nie mogą być puste',
        Pleaseselectatime: 'Wybierz czas',
        Editedsuccessfully: 'Edytowano pomyślnie!',
        Shoppingrebate: 'Rabaty na zakupy',
        Rebateratio: 'Procent rabatu',
        Rebatestarttime: 'czas rozpoczęcia rabatu',
        Rebateendtime: 'czas zakończenia rabatu',
        Rebatetime: 'Czas rabatu',
        doyouwanttodelete2: 'Usunąć?',
        ActivityTopic: 'Temat zdarzenia',
        ActivityName: 'Nazwa zdarzenia',
        Whethertodisplayonthehomepage: 'Czy wyświetlać na stronie głównej',
        QuerySearch: 'wyszukiwanie',
        TopicName: 'Nazwa tematu',
        Activitytime: 'Czas wydarzenia',
        Richtextornot: 'Tekst sformatowany?',
        ActivityDescription: 'Opis wydarzenia',
        SpecialSEO: 'SEO tematyczne',
        LogisticsPricing: 'Cennik logistyczny',
        Basicfreight: 'Podstawowe stawki wysyłki',
        Freeshippingprice: 'Ceny zamówień dla bezpłatnej wysyłki',
        Deliverytimeprompt: 'Wskazówki dotyczące czasu wysyłki',
        Receivingtimeprompt: 'Podpowiedź dotycząca czasu odbioru',
        PricingSettings: 'Ustawienia cen',
        Freightstandard: 'Standard wysyłki',
        Freightallocation: 'Konfiguracja wysyłki',
        FreightType: 'Typ wysyłki',
        Freeshipping: 'Darmowa wysyłka',
        Configureshippingcosts: 'Skonfiguruj koszty wysyłki',
        Fullreductionoffreight: 'zwrot gotówki za fracht',
        Orderamountfull: 'Kwota zamówienia sięga',
        Receivingandshippingreminder: 'przypomnienie o odbiorze i wysyłce',
        link: 'Link',
        confirm: 'Potwierdź',
        Cannotbe0: 'Nie może być 0',
        AtmosttwodigitscanbeenteredaftertheDecimalseparator: 'Można wprowadzić tylko do dwóch miejsc po przecinku.',
        Pleaseinputthenumbercorrectly: 'Wprowadź poprawną liczbę',
        Pleaseenterthecountry: 'Wprowadź kraj',
        Pleaseselectshippingcost: 'Wybierz koszt wysyłki',
        Pleasecompletetheshippingcostconfiguration: 'Uzupełnij konfigurację wysyłki',
        Pleaseenteradeliveryprompt: 'Wprowadź przypomnienie o paragonie',
        Pleaseenteralink: 'Wprowadź link',
        Addlogisticsconfiguration: 'Dodaj konfigurację logistyki',
        Editlogisticsconfiguration: 'Edytuj konfigurację logistyki',
        Pleaseenterthelogisticscompany: 'Wprowadź firmę logistyczną',
        Editingfailed: 'Edycja nie powiodła się',
        Addlogisticscompany: 'Dodaj firmę logistyczną',
        Pleaseuploadtheicon: 'Prześlij ikonę',
        Pleaseenterthelinkcorrectly: 'Wprowadź poprawnie link',
        Successfullyadded: 'Dodano pomyślnie',
        Editlogisticscompany: 'Edytuj firmę logistyczną',
        Thisisalreadythefirstitemmoveupfailed: 'Już jest pierwsza pozycja, przeniesienie w górę nie powiodło się',
        Thisisalreadythelastitemmovedownfailed: 'Już jest ostatnia pozycja, przesunięcie w dół nie powiodło się',
        Costprice: 'Cena',
        taxrate: 'Stawka podatku',
        commission: 'Prowizje',
        Grossmargin: 'Marża brutto',
        doyouwanttodelete: 'podana liczba jest zbyt duża',
        Pleaseselectapricetype: 'Wybierz typ ceny',
        Pleasecompletethetaxrateconfiguration: 'Uzupełnij konfigurację stawki podatkowej',
        Pleasecompletethecommissionconfiguration: 'Uzupełnij konfigurację prowizji',
        PleasecompleteGrossmarginconfiguration: 'Uzupełnij konfigurację stawki brutto',
        Weightrange_start_kg: 'Zakres wagowy (początek) kg',
        Weightrange_end_kg: 'Zakres wagowy (koniec) kg',
        YoucanonlyenteruptofourdigitsaftertheDecimalseparator: 'Można wprowadzić maksymalnie cztery miejsca po przecinku.',
        Theendvaluemustbegreaterthanorequaltothestartvalue: 'Wartość końcowa musi być większa lub równa wartości początkowej.',
        Pleasecompletetheweightrangeconfiguration: 'Zakończ konfigurację zakresu wagowego',
        Pleaseentertheshippingcost: 'Wprowadź koszt wysyłki',
        Addshippingstandard: 'Dodaj standard wysyłki',
        EditFreightStandard: 'Edytuj standard wysyłki',
        Allocationofpatrioticcoins: 'Konfiguracja Aigo Coins',
        RuleConfiguration: 'Konfiguracja reguł',
        ObtainingPatrioticCoinObject: 'przedmioty dające Aigo Coins',
        Rewardconditionsobtained: 'Warunki otrzymywania nagród',
        Obtainpatrioticcoinallocation: 'Konfiguracja otrzymywania Aigo Coins',
        Inputquantity: 'Wprowadź ilość',
        EnterName: 'Wprowadź nazwę',
        Registrationsuccessfulnot: 'Pomyślna rejestracja (nie używaj kodu zaproszenia)',
        Inviteduserplacesanorder: 'Zaproszony użytkownik składa zamówienie',
        Registrationsuccessful: 'Rejestracja powiodła się (użyj kodu zaproszenia)',
        Registeredaccount: 'Zarejestrowane konto (zarejestrowany kraj)',
        Registrationsuccessfulnot1: 'Pomyślna rejestracja konta bez kodu zaproszenia',
        Numberoflocalcurrencies: 'Ilości skonfigurowane poniżej są w walucie krajowej odpowiedniego kraju',
        Inviteraccount: 'Konto zapraszającego (podaj konto kodu zaproszenia, kraj kodu zaproszenia)',
        Provideinvitationcode: 'Przekaż kod zaproszenia innym użytkownikom, inni użytkownicy użyją kodu zaproszenia do rejestracji, a następnie pomyślnie zrealizują zamówienie (każdy zaproszony użytkownik może otrzymać tylko 1 bonus)',
        Usinganinvitationcode: 'pomyślnie zarejestruj konto za pomocą kodu zaproszenia',
        ProductPromotion: 'Promocje produktów',
        discount: 'Rabaty',
        Freeshippingornot: 'Darmowa wysyłka?',
        Secondkillsorting: 'sortowanie po ofercie ograniczonej czasowo',
        surplus: 'Pozostało',
        Totalnumber: 'Łącznie',
        ImportFile: 'Importuj pliki',
        Selectupto50: 'Wybierz do 50',
        Setuppromotions: 'Ustaw promocję',
        Nocountrieslisted: 'Brak krajów, w których produkty są umieszczane na półce',
        Promotiontime: 'Czas promocji',
        Displaypromotionalprices: 'Czy wyświetlać cenę promocyjną',
        PromotionPrice: 'Cena promocyjna',
        Promotionquantity: 'Ilość promocyjna',
        Doyousupporttheuseofcoupons: 'Czy wspierać korzystanie z kuponów',
        Purchaserestrictionsforthesameuser: 'ograniczenia zakupu dla tego samego użytkownika',
        Singleorderlimit: 'Ograniczenie do jednego zamówienia',
        CurrentactivitypricesinSpain: 'Marża brutto od bieżącej ceny aktywności w {countryCode} wynosi',
        SubmitConfirmation: 'Prześlij potwierdzenie',
        Productdistribution: 'Dystrybucja produktów',
        DistributionName: 'Nazwa dystrybucji',
        DistributionCountry: 'Kraj dystrybucji',
        Distributionprice1: 'Cena dystrybucji',
        Distributiontime: 'Czas dystrybucji',
        DistributionPrice: 'Cena dystrybucji',
        Distributionquantity: 'Ilość dystrybucji',
        CopyConnection: 'Skopiuj link',
        Successfullycopied: 'Skopiowano pomyślnie',
        Countryofuse: 'Kraj użytkowania',
        Discounttype: 'Typ oferty',
        Fullreduction: 'zwrot gotówki',
        Isthereafixedcode: 'Czy istnieją stałe kody?',
        Effective: 'Ważne',
        Comingsoon1: 'Ważne wkrótce',
        Expired: 'Wygasł',
        Overlayornot: 'czy jest zgrupowany',
        Istheexchangecodefixed: 'czy naprawić kod realizacji?',
        Received: 'otrzymany',
        Used: 'Użyty',
        Availableamount: 'dostępna ilość',
        Maximumdeductionamount: 'Maksymalna kwota odliczenia',
        Canitbestacked: 'Możliwość zgrupowania?',
        Redemptioncode: 'Kod do realizacji',
        Fullreductionamount: 'kwota zwrotu gotówki',
        Enterredemptioncode: 'Wprowadź kod do realizacji',
        Numberofredemptioncodes: 'Liczba kodów',
        Numberofusesbythesameuser: 'Liczba użyć dla tego samego użytkownika',
        Effectivetime: 'Ważny czas',
        Isitpossibletooverlaydistribution: 'Czy można skumulować z dystrybucją?',
        Canpromotionsbestacked: 'Czy można skumulować z promocją',
        Entercoupondescription: 'Wprowadź instrukcje dotyczące kuponu',
        Pleaseenteradescription: 'Wprowadź opis',
        Pleaseselectadate: 'Wybierz datę',
        Pleaseenterthenumberoftimesthesameuserhasusedit: 'Wprowadź liczbę użyć dla tego samego użytkownika',
        Pleaseenteranavailableamountof0toindicateunlimited: 'Wprowadź dostępną kwotę 0, aby wskazać nieograniczoną liczbę',
        Pleaseentertheavailableamount: 'Wprowadź dostępną ilość',
        Thenumberofexchangecodescanonlybegreaterthantheoriginal: 'Liczba kodów do realizacji może być większa niż liczba oryginalnych kodów.',
        Pleaseenterthenumberofredemptioncodes: 'Wprowadź liczbę kodów do realizacji',
        Applicableclassification: 'Kompatybilne kategorie',
        Applicableproducts: 'Kompatybilne produkty',
        Useordernumber: 'Użyj numeru zamówienia',
        Claimstatus: 'Status odbioru',
        Notclaimed: 'Nieodebrane',
        notused: 'Nieużywany',
        Usagestatus: 'Status użytkowania',
        Collectiontime: 'Czas odbioru',
        Usagetime: 'Czas użytkowania',
        UsingUsers: 'aktywni użytkownicy',
        BannerManagement: 'Zarządzanie banerami',
        position: 'Lokalizacja',
        JumpType: 'Typ skoku',
        Nojump: 'Bez skoku',
        Homepagerecommendationposition: 'Wyróżniona strona główna',
        Homebanner: 'Baner strony głównej',
        Displaytime: 'Wyświetl czas',
        JumpLink: 'Link do skoku',
        Suggestedsize: 'Sugerowany rozmiar',
        Enterjumplink: 'Wprowadź link do skoku',
        Uploadingimagesonlysupports: 'Prześlij obrazy tylko w formacie jpg, png, gif, jpeg',
        Topwithlargenumbers: 'Domyślnie 0, im większa liczba, tym wyższa pozycja w rankingu.',
        Pleaseselectatleastonecountry: 'Wybierz co najmniej jeden kraj',
        Boutiquerecommendationspace: 'Miejsce rekomendacji butików',
        Fullproductrecommendation: 'Pełna rekomendacja produktów',
        popupnotification: 'Wyskakujące okienko',
        Addbanner: 'Dodaj baner',
        subscriptionmanagement: 'Zarządzanie subskrypcją',
        Emailaddress: 'Adres e-mail',
        Subscribing: 'Subskrypcja w toku',
        Canceled: 'Anulowano',
        SubscriptionSource: 'Źródło subskrypcji',
        Unsubscribe: 'anuluj subskrypcję',
        Resubscribe: 'Subskrybuj ponownie',
        Pleaseselectatimerange: 'Wybierz zakres czasu',
        WebsiteManagement: 'Zarządzanie witryną',
        informationservice: 'Usługi informacyjne',
        New: 'Utwórz',
        Isitdisplayedatthebottomofthewebsite: 'Czy wyświetlać na dole strony?',
        Pleaseselectaninformationservice: 'Wybierz usługę informacyjną',
        deleteconfirmation: 'Czy potwierdzić usunięcie węzła i wszystkich informacji pod węzłem?',
        Pleaseenteracolumnname: 'Wprowadź nazwę kolumny',
        Thelengthcannotexceed50characters: 'Długość nie może przekraczać 50 znaków',
        ThelengthcannotexceedXcharacters: 'Długość nie może przekraczać {num} znaków',
        Pleaseentersorting: 'Wprowadź kolejność',
        Selectedcountrycontentisrequired: 'Wymagana jest zawartość wybranego kraju',
        TitleName: 'Nazwa tytułu',
        Chinesetitleisrequired: 'Wymagany jest chiński tytuł',
        Selectedcountrytitleisrequired: 'Nazwa wybranego kraju jest wymagana',
        EditingInformationServices: 'Edytuj usługę informacyjną',
        ColumnName: 'Nazwa kolumny',
        Pleaseenterthecreationtime: 'Wprowadź czas utworzenia',
        Pleaseenterwhethertodisplayatthebottomofthewebsite: 'Wprowadź, jeśli jest wyświetlany na dole strony.',
        ViewInformationServices: 'Wyświetl serwis informacyjny',
        Modificationtime: 'Czas modyfikacji',
        Countrycannotbeempty: 'Kraj nie może być pusty',
        Titlecannotbeempty: 'Tytuł nie może być pusty',
        Richtextcontentonthepagecannotbeempty: 'Pole sformatowanego tekstu nie może być puste',
        Nationalconfiguration: 'Konfiguracja kraju',
        currency: 'Waluta',
        allocation: 'Konfiguracja',
        Statuseditedsuccessfully: 'Status edytowany pomyślnie',
        Areyousuretocontinuedeletingdata: 'Czy na pewno chcesz kontynuować usuwanie danych?',
        WebsiteAddress: 'Adres strony internetowej',
        Contactinformation: 'Informacje kontaktowe',
        Customerserviceemail: 'E-mail obsługi klienta',
        Socialmedia: 'Media społecznościowe',
        Socialmediainformation: 'Informacje o mediach społecznościowych',
        Pleasechooseapaymentmethod: 'Wybierz metodę płatności',
        Lengthgreaterthan20characters: 'Długość większa niż 20 znaków',
        Lengthgreaterthan100characters: 'Długość większa niż 100 znaków',
        Successfullydeletedinformation: 'Pomyślnie usunięto wiadomość',
        Iconmustbeuploaded: 'Ikona musi zostać przesłana',
        Successfullyaddedinformation: 'Pomyślnie dodano wiadomość',
        Successfullyeditedinformation: 'Pomyślnie edytowano wiadomość',
        Pleaseentertheemailcorrectly: 'Wprowadź poprawnie swój adres e-mail',
        Thesizeoftheuploadedimagecannotexceed: 'Rozmiar przesłanego obrazu nie może przekraczać',
        Countryname: 'Nazwa kraju',
        Pleaseuploadpictures: 'Prześlij zdjęcie',
        NewCountryInformation: 'Utwórz nowe informacje o kraju',
        EditCountryInformation: 'Edytuj informacje o kraju',
        Picturesmustbetransmitted: 'Obrazy muszą zostać przesłane',
        NewCountryInformationTableImageAddress: 'Nowo utworzony arkusz informacji o kraju， obraz i adres',
        Operationsuccessful: 'Pomyślna operacja',
        Euro: 'Euro',
        pound: 'Funt',
        Zloti: 'Złoty',
        Brandcannotbeempty: 'Marka nie może być pusta',
        Thewebsiteaddresscannotbeempty: 'Adres strony internetowej nie może być pusty',
        Contactinformationcannotbeempty: 'Dane kontaktowe nie mogą być puste',
        Thecustomerserviceemailaddresscannotbeempty: 'Adres e-mail obsługi klienta nie może być pusty',
        Medianamecannotbeempty: 'Nazwa mediów nie może być pusta',
        Themediaiconcannotbeempty: 'Ikona mediów nie może być pusta',
        Medialinkcannotbeempty: 'Link do mediów nie może być pusty',
        Thecountryiconcannotbeempty: 'Ikona kraju nie może być pusta',
        Languagecannotbeempty: 'Język nie może być pusty',
        Currencycannotbeempty: 'Waluta nie może być pusta',
        Pagedescriptioncannotbeempty: 'Opis strony nie może być pusty',
        Statusenable_disablecannotbeempty: 'Status Włączony/Wyłączony nie może być pusty',
        SEOManagement: 'Zarządzanie SEO',
        Import: 'Import',
        DownloadImportTemplate: 'Pobierz szablon importu',
        PageURL: 'Adres URL strony',
        SEOdata: 'Dane SEO',
        SEOConfigurationTemplate: 'Szablon konfiguracji SEO',
        Downloadsuccessful: 'Pobrano pomyślnie',
        SEOManagementImport: 'Import zarządzania SEO',
        DragtheExcelfilehere_orclickonupload: 'Przeciągnij i upuść plik Excel tutaj lub kliknij przycisk Prześlij.',
        Fileimportedsuccessfully: 'Plik zaimportowany pomyślnie',
        Fileimportfailed_pleasecheckthefilecontent: 'Import pliku nie powiódł się, sprawdź zawartość pliku',
        Pleaseenterthelanguage: 'Wprowadź język',
        Thelengthcannotexceed2characters: 'Długość nie może przekraczać 2 znaków',
        PleaseenterthepageURL: 'Wprowadź adres URL strony',
        Thelengthcannotexceed100characters: 'Długość nie może przekraczać 100 znaków',
        Thelengthcannotexceed20characters: 'Długość nie może przekraczać 20 znaków',
        No_Illegalrowdata: 'Wiersz danych jest niedozwolony',
        Failedtouploadfile_Pleasecheckthefilecontent: 'Przesyłanie pliku nie powiodło się, sprawdź zawartość pliku',
        Namecannotbeempty: 'Nazwa nie może być pusta',
        PageURLcannotbeempty: 'Adres URL strony nie może być pusty',
        Thepagetitlecannotbeempty: 'Tytuł strony nie może być pusty',
        SEOManagementImportTemplate: 'Szablon importu zarządzania SEO',
        Donotmodify: 'Nie modyfikuj pierwszych trzech wierszy tytułu szablonu.',
        Required: 'Pola oznaczone ' * ' są wymagane',
        Brandandlanguagemustbefullycapitalized: 'Marka i język muszą być pisane wielkimi literami',
        SEOManagementExport: 'Eksport zarządzania SEO',
        Pagekeywordscannotbeempty: 'Słowa kluczowe strony nie mogą być puste',
        Prohibitedsalesarea: 'Zabronione obszary sprzedaży',
        Pleaseenterthepostalcodecorrectly: 'Wprowadź poprawnie swój kod pocztowy',
        Pleasefillinthepostalcode: 'Wpisz swój kod pocztowy',
        Countrycodecannotbeempty: 'Kod kraju nie może być pusty',
        Postalcodecannotbeempty: 'Kod pocztowy nie może być pusty',
        Postalcodealreadyexists: 'Kod pocztowy już istnieje',
        Homepagearticle: 'Artykuły na stronie głównej',
        BrandStory: 'Historie marek',
        SciencePopularizationZone: 'Strefa popularyzacji nauki',
        Lightstripzone: 'Strefa Strip Light',
        Pictureandtext: 'obraz i tekst',
        Comingsoon: 'wydanie wkrótce',
        Publishinginprogress: 'wydanie w toku',
        column: 'Kolumna',
        Releasetime: 'Data wydania',
        Pleaseenteratitle: 'Wprowadź tytuł',
        Pleaseselectacolumn: 'Wybierz kolumnę',
        Pleaseselectatype: 'Wybierz typ',
        Patrioticcoinusagelog: 'Historia użycia Aigo Coins',
        income: 'Dochód',
        expenditure: 'Wydatki',
        mode: 'Metoda',
        Orderexpenses: 'Wydatki na zamówienia',
        Orderrewards: 'Nagrody za zamówienia',
        OrderCloseReturn: 'Zamknięcie i zwrot zamówienia',
        other: 'Inne',
        Backendrecharge: 'doładowanie w tle',
        Orderrebate: 'Rabat za zamówienie',
        Numberofchangesinpatrioticcoins: 'Liczba Aigo Coins uległa zmianie',
        Numberofpatrioticcoinsafterchanges: 'Liczba Aigo Coins po zmianie',
        CountryCode: 'Kod kraju',
        Changetime: 'Czas zmiany',
        Pleaseselecttheuser_spatrioticcoinconsumptionrecord: 'Wybierz historię zużycia Aigo Coins użytkownika',
        WebsiteNavigation: 'Nawigacja na stronie',
        HomepageConfiguration: 'Konfiguracja strony głównej',
        Navigationcontent: 'Zawartość nawigacji',
        Recommendedforyou: 'Polecane dla Ciebie',
        SelectNavigation: 'Wybierz nawigację',
        Pleasecheckfirst: 'Zaznacz najpierw',
        Logmanagement: 'Zarządzanie dziennikami',
        UserID_IP_LoginDevice: 'Identyfikator użytkownika/ip/urządzenie logowania',
        logintime: 'Czas logowania',
        Loginmethod: 'Metoda logowania',
        IPaddress: 'Adres IP',
        Logindevice: 'Urządzenie logowania',

        //11-22
        AssociatedOrderNumber: 'Powiązany numer zamówienia',
        recordId: 'Identyfikator rekordu',

        UserAccess: 'Dostęp użytkownika',
        PleaseSelectTheStatisticalType: 'Wybierz typ sortowania danych',
        StatisticalByDate: 'Sortuj dane według daty',
        StatisticalBySource: 'Sortuj dane według źródła',
        Date: 'Data',
        Visits: 'Wyświetlenia',
        NewVisitors: 'Nowi odwiedzający',
        OrderStatistics: 'Statystyki zamówień',
        Sales: 'Sprzedaż',
        TotalOrders: 'Całkowita liczba zamówień',
        AverageOrderAmount: 'Średnia kwota zamówienia',
        SynchronizeEBP: 'Synchronizacja EBP',
        ParameterName: 'Nazwa parametru',
        OnTheShelf: 'Dostępne',
        OfflineShoppingCart: 'Koszyk offline',
        AddToCart: 'Dodaj do koszyka',
        EnterCheckout: 'Przejdź do płatności',
        UserLogin: 'Logowanie użytkownika',
        UserRegistration: 'Rejestracja użytkownika',
        DifferentIPNumbersForAddingItemsToTheShoppingCart: 'Liczba różnych adresów IP, które wykonały operację dodania do koszyka',
        DifferentIPNumbersForEnteringTheOrderConfirmationPage: 'Liczba różnych adresów IP, które przeszły do strony potwierdzenia zamówienia',
        DifferentIPNumbersForWhoCompletedTheLoginProcessAfterBeingRedirectedFromTheOfflineShoppingCartToTheLoginPage: 'Liczba różnych adresów IP, które ukończyły proces logowania po przekierowaniu z koszyka offline na stronę logowania.',
        DifferentIPNumbersForWhoCompletedTheRegistrationProcessAfterBeingRedirectedFromTheOfflineShoppingCartToTheRegisterPage: 'Liczba różnych adresów IP, które ukończyły proces rejestracji po przekierowaniu z koszyka offline na stronę rejestracji.',
        UserBehavior: 'Zachowanie użytkownika',
        Display: 'Wyświetl',
        DoNotDisplay: 'Nie wyświetlaj',
        AddToPresale: 'Dodaj do przedsprzedaży',
        CancelPresale: 'Anuluj przedsprzedaż',
        PresaleConfiguration: 'Konfiguracja przedsprzedaży',
        OffTheShelf: 'Produkt niedostępny',
        PleaseCompleteTheProductInformationBeforeSettingUpThePresale: 'Przed skonfigurowaniem przedsprzedaży należy uzupełnić informacje o produkcie.',
        SynchronizedEBPPrompts: 'Ze względu na potrzebę synchronizacji z EBP masowa synchronizacja może zostać wykonana tylko raz w ciągu X minut, aby uniknąć błędów. Po potwierdzeniu synchronizacja jest wykonywana asynchronicznie, a jednostka SKU jest wyświetlana natychmiast po jej uzyskaniu. Prosimy o cierpliwość.',
        PleaseWait: 'Proszę czekać',
        PleaseSelectATag: 'Wybierz tag',
        Updating: 'Aktualizacja',
        Abortive: 'Nie powiodło się',
        DefaultRule: 'Reguła domyślna',
        BottomInventory: 'Najniższy wynik inwentaryzacji',
        SynchronisedAlertsForGraphicDetails: 'Szczegóły grafiki: Zsynchronizuj [słowa kluczowe produktu] w odpowiednim języku zgodnie z danym językiem.',
        SynchronisedAlertsForLargeProductImages: 'Obraz produktu: Zsynchronizuj obraz odpowiedniego języka zgodnie z danym językiem, odczytaj sortowanie obrazów EBP i automatycznie ustaw pierwszy obraz EBP jako obraz główny.',
        Account: 'Konto',
        Account1: 'Konto',
        EnterUpToNumItems: 'Wprowadź do {num} pozycji',
        SynchronizedSuccessfully: 'Pomyślnie zsynchronizowano',
        SuccessfullyListed: 'Pomyślnie umieszczono na liście',
        SearchSuccessful: 'Wyszukiwanie powiodło się',
        EnterNewPassword: 'Wprowadź nowe hasło',
        OnlyNumbersAndLettersAreAllowed: 'Dozwolone są tylko cyfry i litery',
        PleaseCompleteThePresaleConfigurationBeforeListingThePresaleItem: 'Uzupełnij konfigurację przedsprzedaży przed wystawieniem przedmiotu na sprzedaż.',
        ProductWeight: 'Waga produktu',
        ConfirmExecution: 'Potwierdzić wykonanie?',
        ConfirmBatchListing: 'Potwierdzić wystawienie masowe?',
        Template: 'Szablon',
        PleaseSelectTheProductsToBeSynchronized: 'Wybierz produkty do synchronizacji',
        ForcedSynchronisationDescription: 'Wymuszona synchronizacja spowoduje usunięcie zmodyfikowanych danych i zastąpienie ich danymi zsynchronizowanymi. Zwykła synchronizacja nie zsynchronizuje zmodyfikowanych danych produktów.',
        ConfirmSynchronizationExecution: 'Potwierdź wykonanie synchronizacji',
        SynchronisationHasBeenSentPleaseWait: 'Wiadomość z poleceniem synchronizacji została wysłana, poczekaj cierpliwie na zakończenie synchronizacji danych.',
        ProhibitLogin: 'Zablokuj możliwość logowania',
        IsTheUserLoggedIn: 'Czy użytkownik jest zalogowany?',
        Prohibit: 'Zablokuj',
        ProhibitedSuccessfully: 'Zablokowano pomyślnie',
        EnableLogin: 'Włącz logowanie',
        EnabledSuccessfully: 'Włączono pomyślnie',
        OperationCanceled: 'Operacja anulowana',
        UserData: 'Dane użytkownika.xlsx',
        GraphicDetails: 'Szczegóły grafiki',
        CombinationItemsAreNotAvailableForPresalePleaseRemoveTheCombinationFirst: 'Ten produkt został ustawiony jako produkt łączony i nie można go ustawić do przedsprzedaży. W pierwszej kolejności należy usunąć produkt z połączenia.',
        PromotionalItemsAreNotAvailableForPresalePleaseCancelThePromotionFirst: 'Ten produkt jest produktem promocyjnym i nie można go ustawić do przedsprzedaży. W pierwszej kolejności należy anulować kod promocyjny.',
        DistributedProductsAreNotAvailableForPresalePleaseCancelTheDistributionFirst: 'Ten produkt jest produktem dystrybucyjnym i nie można go ustawić do przedsprzedaży. W pierwszej kolejności należy anulować dystrybucję.',
        ThisProductAlreadyHasAFullReductionActivityAndCannotBeAdded: 'Ten produkt uzyskał już pełną redukcję i nie można go dodać.',
        ESNoPriceNotAvailableForPresale: 'Ten produkt nie ma ceny w {Any} i nie można go dodać do przedsprzedaży. Najpierw usuń odpowiedni kraj lub zsynchronizuj cenę odpowiedniego kraju przed dodaniem produktów w przedsprzedaży!',
        ConfirmSettingThisProductAsAPresaleItem: 'Potwierdzić ustawienie tego produktu jako produktu w przedsprzedaży?',
        PresaleAddedSuccessfully: 'Przedsprzedaż została pomyślnie dodana',
        ConfirmSettingThisProductAsANonpresaleItem: 'Potwierdzić ustawienie tego produktu jako produktu bez przedsprzedaży?',
        CancelPresaleSuccessful: 'Anulowanie przedsprzedaży powiodło się',
        Preview: 'Podgląd',
        TheInventoryOfCurrentProducts0Continue: 'Stan magazynowy aktualnego produktu {stock} wynosi 0, kontynuować?',
        TheInputContentCannotExceedCharacters: 'Wprowadzona treść nie może przekraczać {x} znaków',
        PleaseSelectTheActivity: 'Wybierz aktywność',
        OrderDetails: 'Szczegóły zamówienia',
        LogisticsInformation: 'Informacje logistyczne',
        CancelRemark: 'Uwaga o anulowaniu',
        DepositAmount: 'Kwota depozytu',
        FinalPaymentAmount: 'Kwota płatności końcowej',
        FullReductionDiscount: 'Pełna zniżka',
        YourBrowserDoesNotSupportVideoPlayback: 'Twoja przeglądarka nie obsługuje odtwarzania wideo',
        EditBanner: 'Edytuj baner',
        AddBanner: 'Dodaj baner',
        UpdateTime: 'Czas aktualizacji',
        HomepageRecommendationPosition: 'Pozycja rekomendacji na stronie głównej',
        ActivityID: 'Identyfikator aktywności',
        PleaseSelectThePosition: 'Wybierz pozycję',
        PleaseSelectTheJumpType: 'Wybierz typ skoku',
        PleaseEnterTheAssociatedID: 'Wprowadź powiązany identyfikator',
        TheUploadedImageSizeCannotExceed5MB: 'Rozmiar przesłanego obrazu nie może przekraczać {num} MB!',
        OnlyMp4FormatIsSupportedForVideoUpload: 'Do przesyłania filmów obsługiwany jest tylko format mp4.',
        TheUploadedVideoSizeCannotExceed500MB: 'Rozmiar przesłanego wideo nie może przekraczać {num} MB!',
        PleaseUploadTheVideo: 'Prześlij wideo',
        PleaseEnterTheJumpLink: 'Wprowadź link do skoku',
        PleaseEnterTheCorrectLink: 'Wprowadź poprawny link',
        VerificationFailedPleaseEnterTheRequiredFieldsFirst: 'Weryfikacja nie powiodła się, najpierw wypełnij wymagane pola',
        AddFailed: 'Dodawanie nie powiodło się',
        ItIsAlreadyTheFirstOneAndTheUpwardMovementFailed: 'Jest już pierwszy, a ruch w górę nie powiódł się',
        PleaseEnterTheRebateRatio: 'Podaj wartość procentową rabatu',
        EditCoupon: 'Edytuj kupon',
        AddCoupon: 'Dodaj kupon',
        IDCouponName: 'ID/nazwa kuponu/Kod do realizacji',
        Random: 'Losowo',
        Fixed: 'Naprawiono',
        AboutToTakeeffect: 'Zacznie obowiązywać',
        NotStackable: 'Nie można kumulować',
        Stackable: 'Możliwość kumulacji',
        Frozen: 'Zamrożone',
        PleaseEnterTheMaximumDeductionAmount0MeansUnlimited: 'Wprowadź maksymalną kwotę odliczenia, 0 oznacza nieograniczoną.',
        UseUserID: 'Użyj identyfikatora użytkownika',
        UseOrderNumberExchangeCodeUserID: 'Użyj numeru zamówienia/kodu wymiany/identyfikatora użytkownika',
        PleaseEnterTheDiscount: 'Wprowadź rabat',
        OnlyNumbersFrom0To100CanBeEnteredUpTo1DecimalPlace: 'Można wprowadzić tylko liczby od 0 do 100, do 1 miejsca po przecinku.',
        PleaseEnterTheFullReductionAmount: 'Wprowadź pełną kwotę zniżki',
        PleaseEnterTheExchangeCode: 'Wprowadź kod wymiany',
        OnlyNumbersLettersCanBeEnteredAtLeast6DigitsUpTo20Digits: 'Można wprowadzić tylko cyfry/litery, co najmniej 6 znaków, maksymalnie 20',
        PleaseSelectAFixedExchangeCode: 'Wybierz stały kod wymiany',
        PleaseEnterTheMaximumDeductionAmount: 'Wprowadź maksymalną kwotę odliczenia',
        PleaseSelectWhetherItCanbeStacked: 'Wybierz, czy można kumulować',
        WarehousingTime: 'Czas magazynowania',
        EnterActivityDescription: 'Wprowadź opis działania',
        ActivityTitleCannotBeEmpty: 'Tytuł działania nie może być pusty',
        TheTitleCannotExceed200Characters: 'Tytuł nie może przekraczać {num} znaków',
        PleaseEnterTheActivityDescription: 'Wprowadź opis aktywności',
        ActivityTimeCannotBeEmpty: 'Czas aktywności nie może być pusty',
        ActivityDescriptionLimitOf10000Words: 'Limit opisu aktywności wynosi {num} słów',
        ActivityRecommendation: 'Zalecenie dotyczące aktywności',
        Promotion: 'Promocja',
        EveryFull: 'Co {num} pełnych',
        EnterAmount: 'Wprowadź ilość',
        OnlyPositiveNumbersCanBeEnteredUpTo2DecimalPlacesCannotStartWith0: 'Można wprowadzić tylko liczby dodatnie, do 2 miejsc po przecinku, nie mogą zaczynać się od 0.',
        PleaseEnterTheCorrectAmount: 'Wprowadź prawidłową ilość',
        PleaseSelectApplicableCategoriesOrApplicableProducts: 'Wybierz odpowiednie kategorie lub odpowiednie produkty',
        Rules: 'Zasady',
        CopyLink: 'Kopiuj link',
        DistributionNameProductIDBarcode: 'Nazwa dystrybucji/identyfikator produktu/kod kreskowy',
        ThisSubFunctionIsNotAvailableInThisCountryForTheTimeBeingAndWillBeAvailableLater: 'Ta podfunkcja nie jest obecnie dostępna w tym kraju i będzie dostępna później.',
        PleaseEnterThePrice: 'Wprowadź cenę',
        AutomaticallyCalculateAfterEnteringTheDiscount: 'Oblicz automatycznie po wprowadzeniu rabatu',
        PleaseEnterTheQuantity: 'Wprowadź ilość',
        OrderRemarkLabel: 'Etykieta z uwagami do zamówienia',
        OrdersWorkWithInfluencers: 'Zamówienia z możliwością współpracy z influencerami',
        TheCurrentGrossProfitMarginOfTheCurrentActivityPriceIs: 'Bieżąca marża zysku brutto dla aktualnej ceny aktywności wynosi',
        ThePriceCannotBeLowerThanTheLowestPrice: 'Cena nie może być niższa niż najniższa cena:',
        Distribution: 'Dystrybucja',
        IDName: 'Identyfikator/nazwa',
        ProductSelected: 'Wybrano produkt',
        ProductHasNoPricing: 'Produkt nie ma ceny',
        TheCurrentGrossProfitMarginOfTheCurrentActivityPriceIsvariableAreYouSureToSubmit: 'Bieżąca marża zysku brutto aktualnej ceny aktywności wynosi {rate}%, czy na pewno chcesz przesłać?',
        SubscriptionStatus: 'Status subskrypcji',
        URLClickToCopyAddress: 'Adres URL (kliknij, aby skopiować adres)',
        URLSourceManagement: 'Zarządzanie źródłem adresu URL',
        PleaseEnterTheURL: 'Wprowadź adres URL',
        PleaseEnterEnglishOrNumbers: 'Wprowadź język angielski lub liczby',
        LengthCannotExceed1000Characters: 'Długość nie może przekraczać 1000 znaków',
        URLFixedAddressIsEmpty: 'Stały adres URL jest pusty',
        cover: 'Okładka',
        PleaseEnterOrPasteTheVideoScript: 'Wprowadź lub wklej skrypt wideo',
        VideoScript: 'Skrypt wideo',
        PleaseUploadAVideoFileOrFillInTheVideoScript: 'Prześlij plik wideo lub wypełnij skrypt wideo',
        PleaseEnterTheBrand: 'Wprowadź markę',
        Portuguese: 'portugalski',
        Register: 'Zarejestruj się',
        InviteUsers: 'Zaproś użytkowników',
        OrderPayment: 'Płatność za zamówienie',
        SupportsMP4Forma500MB: 'Obsługuje format mp4, ≤500MB',
        NoProductIsCurrentlySelectedWhetherToCloseIt: 'Żaden produkt nie jest obecnie wybrany, czy zamknąć?',
        ExitFullScreen: 'Zamknij pełny ekran',
        FullScreen: 'Pełny ekran',
        Logout: 'Wyloguj',
        OnlySupportsFilesInFormat: 'Obsługuje tylko pliki w formacie {xxx}',
        PleaseSetAllPromotionalProducts: 'Ustaw wszystkie produkty promocyjne',
        PleaseEnterTheWeight: 'Wprowadź wagę',
        FixedPriceCannotBeLowerThanTheLowestPrice: 'Stała cena nie może być niższa niż najniższa cena',
        CloseOthers: 'Zamknij inne',
        CloseAll: 'Zamknij wszystko',
        ImportSuccessful: 'Import powiódł się',
        BatchRecharge: 'Doładowanie partii',
        RechargeEmail: 'E-mail doładowania',
        RechargeCountry: 'Kraj doładowania',
        RechargeSuccessful: 'Doładowanie powiodło się',
        RechargeInformation: 'Informacje o doładowaniu',
        PleaseDownloadTheRechargeInformationFirst: 'Najpierw pobierz informacje o doładowaniu',
        TheBatchRechargeDataOfTheAIGOCoinCannotBeEmpty: 'Miejsce na dane masowego doładowania monet AGIO nie może być puste',
        TheBatchRechargeDataCannotExceed1000Records: 'Miejsce na dane masowego doładowania nie może przekraczać 1000 pozycji',
        ConfirmSubmission: 'Potwierdź przesłanie',
        ExportFailed: 'Eksport nie powiódł się',
        PackingQuantity: 'Liczba paczek',
        Activity: 'Aktywność',
        PleaseSelectWhetherToEnable: 'Wybierz, czy chcesz włączyć',
        OnlyOneProductCanBeSynchronized: 'Można zsynchronizować tylko jeden produkt',
        SKUWithAQuantityOfNumDoesNotExist: 'SKU z ilością {num} nie istnieje',
        SKUWithAQuantityOf3DoesNotMeetTheSalesMultiple: 'SKU z ilością {num} nie kwalifikują się do mnożnika sprzedaży',
        SalesMultipleDoesNotMatchCannotBeListed: 'Mnożnik sprzedaży nie pasuje, nie może być zatwierdzona',
        SalesMultipleIsEmptyInSpain: 'Nie ma mnożnika sprzedaży dla Hiszpanii',
        SynchronizationOfPackageQuantityIsAbnormal: 'Synchronizacja liczby paczek jest nieprawidłowa',
        CannotContinueToMoveUp: 'Nie można kontynuować przesuwania w górę',
        CannotContinueToMoveDown: 'Nie można kontynuować przesuwania w dół',
        SynchronizeThePackageQuantity: 'Zsynchronizuj liczbę paczek',
        ProductAssociationIDCannotBeEmpty: 'Identyfikator powiązania produktu nie może być pusty',
        PackageQuantityCannotBeEmpty: 'Liczba paczek nie może być pusta',
        PleaseEnterTheCorrectQuantityFormat: 'Wprowadź prawidłowy format ilości',
        PleaseEnterSeparatedBy: 'Wprowadź (oddzielone przez #)',
        PackageQuantity: 'Liczba paczek',
        LengthBetween1And140Characters: 'Długość od 1 do 140 znaków',
        PleaseSelectTheVariantParametersFirst: 'Najpierw wybierz parametry wariantu',
        TheParameterIsAssociatedWithTheVariantPleaseBeCareful: 'Parametr jest powiązany z wariantem, należy zachować ostrożność.',
        PleaseSelectDataOfTheSameCategoryPleaseReselect: 'Podaj dane tej samej kategorii i wybierz ponownie',
        UpTo5VariantsCanBeSelected: 'Można wybrać do 5 wariantów',
        NoVariant: 'Brak wariantu',
        VerificationFailedPleaseSelectTheVariant: 'Weryfikacja nie powiodła się, wybierz wariant',
        VerificationFailedPleaseSelectTheProduct: 'Weryfikacja nie powiodła się, wybierz produkt',
        VerificationFailedTheProductParameterValueIsEmpty: 'Weryfikacja nie powiodła się, wartość parametru produktu jest pusta',
        SetSuccessfully: 'Ustawienie powiodło się',
        UploadingVideo: 'Przesyłanie wideo',
        SearchParameters: 'Parametry wyszukiwania',
        ImportantParameters: 'Ważne parametry',
        Select: 'Wybierz',
        ConfirmSettingThisImageAsTheMainImage: 'Potwierdź ustawienie tego obrazu jako obrazu głównego',
        ConfirmDisplayDoNotDisplay: 'Potwierdź wyświetlanie/nie wyświetlaj',
        TheCurrentLimitIsToSelectNum1FilesThisTimeYouHaveSelectedNum2FilesATotalOfNum3FilesHaveBeenSelected: 'Aktualny limit to wybór {num1} plików, tym razem wybrano {num2} pliki, w sumie wybrano {num3} pliki.',
        ConfirmToRemoveAny: 'Potwierdzić usunięcie {Any}?',
        TheTestEnvironmentCannotUploadFiles: 'Środowisko testowe nie może przesyłać plików',
        PleaseEnterTheEnglishTitle: 'Wprowadź tytuł w języku angielskim',
        CancelSettingAsTheMainImage: 'Anulować ustawienie jako głównego obrazu?',
        SetAsTheMainImage: 'Ustawić jako główny obraz?',
        CannotSubmitImagesOrVideosThatAlreadyExist: 'Nie można przesłać obrazów lub filmów, które już istnieją',
        SynchronizeAnyImagesToOtherLanguages: 'Zsynchronizować obrazy {Any} z innymi językami?',
        PleaseSelectTheProductToBeSynchronizedFirst: 'Najpierw wybierz produkt do synchronizacji',
        PleaseSelectTheProductFirst: 'Najpierw wybierz produkt',
        ConfirmWhetherToDeleteTheSelectedImageVideo: 'Czy usunąć wybrany obraz/wideo?',
        TheMainPictureCannotBeTurnedOff: 'Nie można wyłączyć głównego obrazu',
        CancelDisplay: 'Anulować wyświetlanie?',
        ConfirmWhetherToDelete: 'Czy na pewno usunąć?',
        PleaseUploadTheEnglishImage: 'Prześlij obraz w języku angielskim',
        FailedPleaseEnterTheProductTitleForAllSalesCountries: 'Nie udało się, wprowadź tytuł produktu dla wszystkich krajów sprzedaży',
        PresaleTime: 'Czas przedsprzedaży',
        PresalePrice: 'Cena w przedsprzedaży',
        PleaseEnter: 'Wprowadź',
        PresaleQuantity: 'Liczba w przedsprzedaży',
        DepositRatio: 'Współczynnik depozytu',
        AssociatedAGBSOnthewayOrders: 'Powiązane zamówienia tranzytowe AGBS',
        EstimatedDeliveryTime: 'Szacowany czas dostawy',
        FinalPaymentDeadline: 'Ostateczny termin płatności',
        SelectTime: 'Czas wyboru',
        FixedPrice: 'Stała cena',
        ByProportion: 'Proporcjonalnie',
        ByFullPayment: 'Pełna płatność',
        OnlyNumbersGreaterThan0CanBeEnteredUpToTwoDecimalPlaces: 'Można wprowadzić tylko liczby większe od 0, do dwóch miejsc po przecinku.',
        PresaleTimeCannotBeEmpty: 'Czas przedsprzedaży nie może być pusty',
        PresalePriceTypeCannotBeEmpty: 'Typ ceny w przedsprzedaży nie może być pusty',
        PresaleQuantityCannotBeEmpty: 'Liczba w przedsprzedaży nie może być pusta',
        OnlyPositiveIntegersCanBeEnteredCannotStartWith0: 'Można wprowadzić tylko dodatnie liczby całkowite, nie mogą zaczynać się od 0',
        DepositRatioTypeCannotBeEmpty: 'Typ współczynnika depozytu nie może być pusty',
        DepositRatioCannotBeEmpty: 'Współczynnik depozytu nie może być pusty',
        OnlyIntegersBetween0And100CanBeEnteredExcluding0And100: 'Można wprowadzić tylko liczby całkowite od 0 do 100, z wyłączeniem 0 i 100',
        EstimatedDeliveryTimeCannotBeEmpty: 'Szacowany czas dostawy nie może być pusty',
        FinalPaymentDeadlineCannotBeEmpty: 'Ostateczny termin płatności nie może być pusty ',
        FinalPaymentShouldBeDoneBefore5HoursBeforeTheEndOfPresalePromotion: 'Płatności końcowej należy dokonać przed upływem 5 godzin przed zakończeniem promocji przedsprzedażowej.',
        TheRemainingAmount: 'Pozostała kwota',
        PresaleStatus: 'Status przedsprzedaży',
        PresaleStartTime: 'Czas rozpoczęcia przedsprzedaży',
        PresaleEndTime: 'Czas zakończenia przedsprzedaży',
        OnPresale: 'W przedsprzedaży',
        DirectVisit: 'Bezpośrednia wizyta',
        BackendManagementSystem: 'System zarządzania backendem',
        Login: 'Logowanie',
        TipsFillInTheUsernameAndPasswordCasually: 'Wskazówki: wpisz dowolną nazwę użytkownika i hasło.',
        PleaseEnterUserName: 'Wprowadź nazwę użytkownika',
        PleaseEnterPassword: 'Wprowadź hasło',
        LoginSuccessful: 'Logowanie powiodło się',
        PleaseEnterYourAccountNumberAndPassword: 'Wprowadź numer konta i hasło',
        TheSameProductCanOnlyBeReturnedOrExchanged: 'Ten sam produkt może być zwrócony lub wymieniony tylko raz.',
        B2CBrandWebsite: 'Strona marki B2C',
        CommentManagement: 'Zarządzanie komentarzami',
        AIGOCoins: 'Monety AIGO',
        PleaseEnterThePackageQuantity: 'cover',
        PresalePriceCannotBeEmpty: 'Cena przedsprzedażowa nie może być pusta',
        Product: 'Produkt',
        HomePageColumn: 'Kolumna strony głównej',
        ColumnDescription: 'Opis kolumny',
        FlashSaleArea: 'Obszar wyprzedaży błyskawicznej',

        //1.2.8之后
        SoldOutOrNot: 'Wyprzedane czy nie',
        VirtualQuantity: 'Ilość wirtualna',
        VirtualQuantityPackageQuantity: 'Ilość wirtualna/ilość w opakowaniu',
        CombinationIcon: 'Ikona połączenia',
        UploadFailedFileSizeShouldBe3MB: 'Przesyłanie nie powiodło się, rozmiar pliku powinien wynosić <3 MB',
        PromotionQuantityPackageQuantity: 'Ilość promocyjna/ilość w opakowaniu',
        RemainingTotal: 'Pozostało/Ogółem',
        RecommendedSize100: 'Zalecany rozmiar: 100*100, ≤ 20 KB',
        TotalRemainingTotal: 'Razem - pozostało/ogółem',
        Verification: 'Weryfikacja',
        VirtualSalesVolume: 'Wirtualna wielkość sprzedaży',
        VirtualSalesVolumePackageQuantity: 'Wirtualna wielkość sprzedaży/ilość w opakowaniu',
        DeleteOrNot: 'Usunąć czy nie?',
        EnabledOrNot: 'Aktywowano czy nie',
        Cancel: 'Anuluj',
        Code: 'Kod',
        Confirm: 'Potwierdź',
        RebateDimension: 'Wymiar rabatu',
        InProgress: 'W toku',
        ActivityStatus: 'Status aktywności',
        RebateRatioManagement: 'Zarządzanie współczynnikiem rabatu',
        StartTime: 'Czas rozpoczęcia',
        EndTime: 'Czas zakończenia',
        RebateAmount: 'Kwota rabatu',
        NewUser: 'Nowy użytkownik',
        RebateObjectNumber: 'Numer obiektu rabatu',
        NumberOfCompletedOrders: 'Liczba zrealizowanych zamówień',
        RebateObject: 'Obiekt rabatu',
        Operation: 'Operacja',
        RecentOperator: 'Ostatni operator',
        Country: 'Kraj',
        Prompt: 'Podpowiedź',
        RebateProducts: 'Produkty objęte rabatem',
        NewUserPromotion: 'Promocja dla nowych użytkowników',
        RegularUserPromotion: 'Promocja dla stałych użytkowników',
        Upcoming: 'Nadchodzące',
        RebateRatio: 'Współczynnik rabatu',
        RebateOrNot: 'Rabat czy nie',
        Rebate: 'Rabat',
        PleaseSelectARebateDimension: 'Wybierz wymiar rabatu',
        PleaseSelectARebateObject: 'Wybierz obiekt rabatu',
        PleaseSelectACountry: 'Wybierz kraj',
        PleaseSelectARebateRatio: 'Wybierz współczynnik rabatu',
        RecentOperationTime: 'Czas ostatniej operacji',
        PromotionObjectType: 'Typ obiektu promocji',
        ProductPromotionType: 'Typ promocji produktu',
        UserType: 'Typ użytkownika',
        ApplicableUserType: 'Obowiązujący typ użytkownika',
        RegularUser: 'Stały użytkownik',
        UseAigoCoinsOrNot: 'Użyć monet Aigo czy nie?',
        EndDateOfMembership: 'data zakończenia członkostwa',
        StartDateOfMembership: 'data rozpoczęcia członkostwa',
        memberOrNot: 'członek czy nie',
        No: 'Nie',
        Yes: 'Tak',
        LowInventory: 'Niski poziom zapasów',
        RechargeEmailbox: 'Doładuj skrzynkę e-mail',
        SubmittedSuccessfully: 'Przesłano pomyślnie',
        Importing: 'importowanie',
        InformationOfTheRechargeFailed: 'Informacje o nieudanym doładowaniu',
        RechargeFailed: 'Doładowanie nie powiodło się',
        RechargeInBatch: 'Doładuj w partii',
        RechargeSuccessfully: 'Doładowanie powiodło się',
        RechargeAigoCoins: 'Doładuj monety Aigo',
        RequiredField: 'Wymagane pole',
        PDFFormat: 'Format PDF',
        FileSize3MB: 'Rozmiar pliku <3 MB',
        UploadFailedPDFFormatRequired: 'Przesyłanie nie powiodło się, wymagany format PDF',
        AuditFailedPleaseUploadInvoiceAttachment: 'Audyt nie powiódł się, prześlij załącznik do faktury',
        PleaseUploadInvoiceAttachment: 'Prześlij załącznik do faktury',
        InvoiceAttachment: 'Załącznik faktury',
        Upload: 'Przesyłanie',
        UploadFailed: 'Przesyłanie nie powiodło się',

        HaveAnAccount: 'Nie masz konta? Kliknij tutaj, aby się zarejestrować',
        SoldOut: 'Wyprzedane',
        HaveAnInvitationCode: 'Opcjonalnie, pozostaw puste, jeśli nie masz kodu z zaproszeniem.',
        RestockedItems: 'Uzupełnione artykuły',

        NotMeetPleaseEnterAgain: 'Pseudonim nie spełnia wymagań, wprowadź go ponownie.',
        ThisCouponIsOnlyAvailableForNewUsers: 'Ten kupon jest dostępny tylko dla nowych użytkowników.',
        ThiscouponIsOnlyAvailableForExistingUsers: 'Ten kupon jest dostępny tylko dla istniejących użytkowników.',
        OnlyReturnsOrExchangesOfTheSameProductAreAllowed: 'Dozwolone są tylko zwroty lub wymiany tego samego produktu.',
        With365DaysRemaining: '（Expires on 9/ 25/2024） with 365 days remaining',

        TheNicknameDoesNotMeet: 'The nickname does not meet the requirements, please enter a new one.',

        GoToView: 'Przejdź do widoku',
        DoNotRegisterRepeatedly: 'Nie rejestruj się wielokrotnie',
        ValidityPeriod: 'Okres ważności',
        RegisterSuccessfully: 'Rejestracja powiodła się',
        MemberRegistration: 'Rejestracja członków',
        RegistrationFailed: 'Rejestracja nie powiodła się',
        OneYear: 'Jeden rok',
        AddToShoppingCart: 'Dodaj do koszyka',
        AddANewAddress: 'Dodaj nowy adres',
        ZIPCode: 'Kod pocztowy* (Wybierz)',

        EditYourAddress: 'Edytuj swój adres',
        Remove: 'Usuń',
        SetAsDefault: 'Ustaw jako domyślny',
        FailedChangeDefaultAddress: 'Nie udało się zmienić domyślnego adresu, spróbuj ponownie później.',
        Fullname: 'Imię i nazwisko*',
        PhoneNumber: 'Numer telefonu',
        DefaultAddressHasBeenChanged: 'Domyślny adres został zmieniony',
        ViewComments: 'Wyświetl komentarze',
        ClickToCheckLogisticsInfo: 'Kliknij, aby sprawdzić informacje logistyczne.',
        RestockingOfOutOfStockItems: 'Uzupełnianie zapasów produktów niedostępnych',
        ThisWeekRestockedItems: 'Artykuły uzupełnione w tym tygodniu',
        ProductListInTheShoppingCart: 'lista produktów w koszyku',
        WarmReminderVideoResources: 'Uprzejme przypomnienie: Zasoby wideo są dostępne tylko do pojedynczego pobrania.',
        PleaseLeaveYourMessage: 'Prosimy o pozostawienie wiadomości',
        InvoiceManagement: 'Zarządzanie fakturami',

        ReadmeFile: 'Plik Readme',
        HelpCenter: 'Centrum pomocy',
        FileUpload: 'Przesyłanie plików',
        Globalization: 'Globalizacja',
        SalespersonProfile: 'Profil sprzedawcy',
        BatchSyncPage: 'Strona synchronizacji masowej',
        CountryConfigurationPage: 'Strona konfiguracji kraju',
        UserConsultation: 'Konsultacje z użytkownikami',
        PreSaleItems: 'Pozycje przedsprzedażowe',
        EventManagement: 'Zarządzanie wydarzeniami',
        BlankPage: 'Pusta strona',
        ThereAreCurrentlyNoProductPromotions: 'Obecnie nie ma żadnych produktów na promocji',
        EditInventoryBottomLine: 'Edytuj dolną granicę zapasów',
        SetAFixedPrice: 'Ustaw stałą cenę',
        EnterUpTo500Entries: 'Wprowadź do 500 wpisów',
        CantRelateToYourself: 'Nie można odnieść do siebie',
        PleaseSelectTheApplicableUserType: 'Wybierz odpowiedni typ użytkownika',
        TheActivityDescriptionLimitIs10000Words: 'Limit opisu aktywności wynosi 10 000 słów',
        EnabledStatus: 'Status włączony',
        Enable: 'Włącz',
        OperatingTime: 'Czas działania',
        NewUserPromotionEnabled: 'Promocja dla nowych użytkowników włączona',
        NewUserPromotionDisabled: 'Promocja dla nowych użytkowników wyłączona',
        OldUserPromotionEnabled: 'Promocja dla stałych klientów włączona',
        OldUserPromotionDisabled: 'Promocja dla stałych klientów wyłączona',
        ThePromotionalPriceCannotBeLowerThanTheLowestPrice: 'Cena promocyjna nie może być niższa niż najniższa cena',
        AutomaticallyCalculateAfterEnteringPrice: 'Oblicz automatycznie po wprowadzeniu ceny',
        ThePromotionalPriceForNewUsersIsGreaterThanThePromotionalPriceForOldUsersDoYouWantToContinue: 'Cena promocyjna dla nowych użytkowników jest wyższa niż cena promocyjna dla stałych użytkowników. Czy chcesz kontynuować?',
        PleaseSelectPromotionCountry: 'Wybierz kraj promocji',
        PromotionDiscount: 'Rabat promocyjny',
        PleaseEnterThePromotionalQuantity: 'Wprowadź liczbę promocji',
        PleaseEnterVirtualQuantity: 'Wprowadź wirtualną ilość',
        NumberOfUserPurchases: 'Liczba zakupów użytkownika',
        PleaseEnterThePurchaseLimitForTheSameUser: 'Wprowadź limit zakupów dla tego samego użytkownika',
        PleaseEnterThePurchaseLimitForASingleOrder: 'Wprowadź limit zakupów dla pojedynczego zamówienia',
        QuantityGreaterThan0: 'Liczba większa niż 0',
        GrossProfitMarginCalculationResults: 'Wyniki obliczeń marży zysku brutto',
        FixedPriceGrossMargin: 'Marża zysku brutto po stałej cenie',
        SellingPriceGrossProfitMargin: 'Marża zysku brutto ceny sprzedaży',
        PromotionalPriceGrossProfitMargin: 'Marża zysku brutto promocyjnej ceny',
        PromotionStartTime: 'Czas rozpoczęcia promocji',
        PromotionEndTime: 'Czas zakończenia promocji',
        PromotionType: 'Typ promocji',
        PleaseSetTheSKUToPromotionalProducts: 'Ustaw SKU na ${xxx} produkty promocyjne',
        DistributionOffers: 'Oferty dystrybucji',
        AfterEnteringTheQuantitytWillBeAutomaticallyCalculatedBasedOnTheQuantityOfTheProduct: 'Po wprowadzeniu ilości {Any} zostanie ona automatycznie obliczona na podstawie ilości produktu.',
        TheNumberOfUserPurchasesMustBeGreaterThan0: 'Liczba zakupów użytkownika musi być większa niż 0.',
        PleaseSelectCountry: 'Wybierz kraj ${typeName}',

        AfterEnteringThePromotionalQuantityItWillBeAutomaticallyCalculatedBasedOnTheQuantityOfGoods: 'Po wprowadzeniu ilości promocyjnej zostanie ona automatycznie obliczona na podstawie ilości towarów.',

        PleaseCancelTheAssociationInRebateProductPoolFirst: 'Najpierw anuluj powiązanie w [Puli rabatów].',
        EditBottomLineInventory: 'Edytuj dolną linię zapasów',
        PleaseConfigureFixedPrice: 'Skonfiguruj stałą cenę',
        BalancePayment: 'Płatność salda',
        Deposit: 'Wpłata',

        EndofValidity: 'Wprowadź datę wygaśnięcia',
        Year: 'Rok',
        Month: 'Miesiąc',
        Day: 'Dzień',
        UnitOfValidity: 'Jednostka ważności',
        AIGOCoinDetails: 'Szczegóły monety AIGO',
        AIGOCoinsAreCloseToExpiring: 'Monety AIGO są bliskie wygaśnięcia (Dzień)',
        CountryOfShipmentAddress: 'Kraj adresu wysyłki',

        // V1.3.3 start
        EstimatedTimeOfDelivery: 'Szacowany czas dostawy',
        DataCorrespondingToTheSource: 'Dane odpowiadające źródłu',
        AccountStatus: 'Status konta',
        OrderID: 'Identyfikator zamówienia',
        RecipientName: 'Imię odbiorcy',
        RecipientLastName: 'Nazwisko odbiorcy',
        MailsOfUser: 'Adres e-mail użytkownika',
        StateProvince: 'Kraj/województwo',
        CityTown: 'Miejscowość',
        County: 'Powiat/gmina',
        ReceivingCompany: 'Firma odbierająca',
        BatchInput: 'Wprowadzanie seryjne',
        InputManually: 'Wprowadzanie ręczne',
        ProductCommentsInputTemplate: 'Szablon wprowadzania komentarzy dotyczących produktu',
        UserName: 'Nazwa użytkownika',
        Comments: 'Komentarze',
        StarRatingOfTheComment: 'Ocena komentarza w gwiazdkach',
        CommentTime: 'Data komentarza',
        UploadAttachment: 'Prześlij załącznik',
        NumberOfAttachments: 'Liczba załączników',
        FileName: 'Nazwa pliku',
        Appendix: 'Załącznik',
        BatchInputOfProductComments: 'Seryjne wprowadzanie komentarzy dotyczących produktów',
        SoldOut1: 'Wyprzedane',
        PreSalePromotionID: 'Identyfikator promocji przedsprzedażowej',
        PromotionAbnormal: 'Promocja nienormalna',
        Updater: 'Aktualizator',
        AddPreSalePromotion: 'Dodaj promocję przedsprzedażową',
        EditPreSalePromotion: 'Edytuj promocję przedsprzedażową',
        Barcode: 'Kod kreskowy',
        PreSaleCountries: 'Kraje przedsprzedaży',
        PreSalePrice: 'Cena przedsprzedaży',
        PreSaleDeposit: 'Depozyt przedsprzedaży',
        PleaseEnterTheDocumentCode: 'Wprowadź kod dokumentu',
        BalancePaymentDeadline: 'Termin płatności salda',
        FixedShippingFee: 'Stała opłata za wysyłkę',
        PreSaleShippingFee: 'Opłata za wysyłkę w przedsprzedaży',
        FreeShippingSubjectToConditions: 'Bezpłatna wysyłka z zastrzeżeniem warunków',
        OrderPrice: 'Cena zamówienia',
        PleaseEnterPriceConditions: 'Wprowadź warunki cenowe',
        TheEndTimeNeedsToBeLaterThanTheStartTime: 'Czas zakończenia musi być późniejszy niż czas rozpoczęcia',
        ThereAreAlreadyPartiallyOverlappingPromotionInThisCountryPleaseModify: 'W tym kraju istnieją już częściowo pokrywające się promocje, prosimy o modyfikację.',
        ThePreSaleQuantityCanOnlyBeAPositiveIntegerGreaterThan0: 'Ilość w przedsprzedaży może być tylko liczbą całkowitą większą niż 0.',
        TheEstimatedDeliveryTimeNeedsToBeLaterThanTheEventEndTime: 'Szacowany czas dostawy musi być późniejszy niż czas zakończenia wydarzenia.',
        TheBalancePaymentDeadlineNeedsToBeEarlierThanTheEstimatedDeliveryTime: 'Termin płatności salda musi być wcześniejszy niż szacowany czas dostawy.',
        PromotionID: 'Identyfikator promocji',
        DistributionPromotionID: 'Identyfikator promocji dystrybucji',
        CouponID: 'Identyfikator kuponu',
        PromotionManagementID: 'Identyfikator zarządzania promocją',
        PortfolioManagementID: 'Identyfikator zarządzania portfelem',
        ExportForFiltering: 'Eksportuj do filtrowania',
        ListDisplay: 'Wyświetlanie listy',
        InventoryItems: 'Pozycje magazynowe',
        InStock: 'W magazynie',
        OutOfStock: 'Brak w magazynie',
        FieldName: 'Nazwa pola',
        PleaseSelectInventory: 'Proszę wybrać magazyn',
        PreSaleEvent: 'Wyprzedaż przedpremierowa',
        PerPage: 'Każda strona pokazuje',

        // V1.3.3 end

        // V1.3.4 start

        StatisticsOfGiveawayActivities: 'Statystyki akcji darmowych',
        PleaseEnterTheNameOfTheEvent: 'Proszę wpisać nazwę wydarzenia.',
        PleaseSelectTheActivityType: 'Proszę wybrać rodzaj aktywności.',
        PleaseCompleteTheActivityRating: 'Proszę wypełnić ocenę działalności.',
        PleaseSetUpThePreSaleCampaignBeforeSettingUpTheCountryWhereThePreSaleItemsWillBeDisplayed: 'Przed skonfigurowaniem kraju, w którym zostaną wyeksponowane produkty pre-sale, skonfiguruj kampanię pre-sale {Any}.',
        TheOrderAmountAndNumberOfFreebiesCannotBeLowerThanThePreviousTier: 'Wartość zamówienia i liczba darmowych prezentów nie może być niższa niż poprzedni poziom.',
        SelectImage: 'Wybierz obraz',
        GiftInformation: 'Informacje o prezencie',
        Gifts: 'Prezenty',
        GiftsOfEndOfEvent: 'Prezenty na zakończenie wydarzenia',
        CheckOutTheGifts: 'Sprawdź prezenty',
        GiveawayEvent: 'Losowanie nagród',
        SelectAGift: 'Wybierz prezent',
        YouHaveNotSelectedAGift: 'Nie wybrałeś prezentu',
        NoNeedForGifts: 'Nie potrzebujesz prezentów',
        SomeGiftsAreOutOfStockPleaseReselectTheGifts: 'Niektóre prezenty są niedostępne, wybierz ponownie',
        ReplaceAGift: 'Wymień prezent',
        ThereAreCurrently3FreeGiftsAvailable: 'Obecnie dostępne są 3 darmowe prezenty',
        Currently3FreeGiftsAreAvailableAnd4MoreGiftsAreAvailableForBuyingOther99: 'Obecnie dostępne są 3 darmowe prezenty, a 4 kolejne prezenty są dostępne przy zakupie za 99 €.',
        GiftList: 'Lista prezentów',
        GiftID: 'Identyfikator prezentu',
        CurrentInventory: 'Aktualny stan magazynowy',
        NotEnabled: 'Nieaktywne',
        GoodsForFree: 'Towary za darmo',
        ConfirmToDeleteThisGiftInformation: 'Potwierdzić usunięcie informacji o prezencie?',
        GiftsSortingManagement: 'Zarządzanie sortowaniem prezentów',
        GiftsTopicManagement: 'Zarządzanie tematami prezentów',
        GiveawayEventID: 'Identyfikator losowania nagród',
        GiveawayEventName: 'Nazwa losowania nagród',
        ProductCategory: 'Kategoria produktu',
        ActivityMode: 'Tryb aktywności',
        TotalNumberOfActivities: 'Całkowita liczba aktywności',
        GiftsGivenQuantity: 'Liczba rozdanych prezentów ',
        ThemeConfiguration: 'Konfiguracja motywu',
        GiftSorting: 'Sortowanie prezentów',
        GlobalConfiguration: 'Konfiguracja globalna',
        ActiveCountry: 'Aktywny kraj',
        PromotionalPpicture: 'Obrazek promocyjny',
        RecommendedSizeX: 'Zalecane wymiary: {XXX*XXX}',
        FileSize1MB: 'Rozmiar pliku: ≤1 MB',
        ActivitySorting: 'Sortowanie aktywności',
        DragToSort: 'Przeciągnij, aby posortować',
        ActivityCode: 'Kod aktywności',
        GiftsForOrderAmount: 'Prezenty za kwotę zamówienia',
        ActivityRating: 'Ocena aktywności',
        GiftsQuantity: 'Liczba prezentów',
        EventGiftsSorting: 'Sortowanie prezentów z wydarzeń',
        DefaultSelectionOfUsers: 'Domyślny wybór użytkowników',
        LandingPageDisplay: 'Wyświetlanie strony docelowej',
        LandingPageActivityName: 'Nazwa aktywności na stronie docelowej',
        ConfirmDeleteThisGiftEvent: 'Potwierdzić usunięcie tego wydarzenia z prezentami?',
        IsThereAGiftEvent: 'Czy istnieje wydarzenie z prezentami?',
        OrderQuantity: 'Ilość zamówienia',
        NumberOfGiftsIssued: 'Liczba wydanych prezentów',
        CustomerPrice: 'Cena dla klienta',
        TheVirtualEventQuantityFunctionCanOnlyBeSetIfThePreSaleActivityTimeIsAtLeastMoreThan2DaysAndTheNumberOfEventsIsGreaterThan50: 'Funkcję liczby wydarzeń wirtualnych można ustawić tylko wtedy, gdy czas aktywności przedsprzedaży wynosi co najmniej 2 dni, a liczba wydarzeń jest większa niż 50.',
        PreSaleSwitch: 'Przełącznik przedsprzedaży',
        PreSaleActivity: 'Aktywność przedsprzedażowa',
        GiftManagement: "Zarządzanie prezentami",

        // V1.3.4 end

        //V1.3.5 start
        OrderSource: "Źródło zamówienia",
        NumberOfNewUserRegistrations: "Liczba rejestracji nowych użytkowników",
        RegisteredUsersWhoVsitTheWebsite: "Zarejestrowani użytkownicy odwiedzający witrynę",
        SoldDistributionQuantity: "Ilość sprzedana w dystrybucji",
        DistributionQuantityLock: "Blokada ilości w dystrybucji",
        InsufficientDstributionQuantityRemaining: "Pozostała niewystarczająca ilość w dystrybucji",
        Previous: "Poprzedni",
        Next: "Następny",

        //V1.3.5 end

        //V1.3.6 start 
        RecipeManagement: "Zarządzanie recepturami",
        RecipeDisplayImage: "Obraz wyświetlania receptury",
        NewRecipeDisplayImage: "Edycja/tworzenie nowego obrazu wyświetlania receptury",
        CoverPicture: "Obraz okładki",
        PicturesVideos: "Można przesłać do 10 zdjęć + filmów, każde zdjęcie powinno mieć rozmiar ≤5 MB, a wideo musi być w formacie.mp4.",
        AddPicturesVideos: "Dodawanie zdjęć/filmów",
        CommenOnPicturesVideos: "Komentowanie zdjęć/filmów",
        FileSize: "Rozmiar pliku",
        BarcodeAssociated: "Powiązany kod kreskowy",
        FirstUploadTime: "Czas pierwszego przesłania",
        EditTime: "Czas edycji",
        TagType: "Typ znacznika",
        FileName1: "Nazwa pliku",
        NameLengthISTooLong: "Nazwa jest zbyt długa",
        ProductDoesNoExist: "Produkt nie istnieje",
        PleaseUploadImages: "Prześlij obrazy jpg/png/jpeg poniżej 5MB",
        PleaseUploadTheVideoMP4: "Prześlij film w formacie mp4",
        PictureOrVideo: "Obraz lub film nie może być pusty",
        allowed: "Zezwól na wszystkie",
        cookie1: "Zarządzanie plikami cookie",
        collectsordinances: "Aigostar używa plików cookie, aby poprawić komfort przeglądania i do celów marketingowych. Poniżej można wyświetlić ustawienia plików cookie i zarządzać nimi. Aby dowiedzieć się więcej o tym, jak używamy plików cookie, zapoznaj się z naszą Polityką prywatności.",
        RequiredCookies: "Niezbędne pliki cookie",
        cookie2: "Te pliki cookie są wymagane do podstawowych funkcji, takich jak logowanie, korzystanie z koszyka i dokonywanie płatności. Niezbędnych plików cookie nie można wyłączyć. Ale bez obaw, te pliki cookie nie służą gromadzeniu żadnych informacji o użytkowniku.",
        AnalyticsCookies: "Marketingowe i analityczne pliki cookie",
        cookie3: "Te pliki cookie zbierają informacje takie jak liczba wizyt na witrynie lub które strony są popularne, aby pomóc nam poprawić jakość obsługi klienta. Możesz wyłączyć te pliki cookie, ale wtedy nie będziemy w stanie zbierać informacji potrzebnych do poprawy komfortu korzystania z naszej witryny.",
        StoragePreferences: "Zapisz preferencje",
        BuyItNow: "Kup teraz",
        PictureVideoSize: "Rozmiar lub format obrazu lub wideo jest nieprawidłowy",
        RecipeConfiguration: "Konfiguracja przepisu",

        //V1.3.6 end

        //V1.3.7 start 
        ConfirmSynchronizationSubBarcodes: "Potwierdź synchronizację subkodów kreskowych?",
        SynchronizationEbpEanSUB: "Ze względu na konieczność połączenia z EBP, synchronizacja zbiorcza powinna zostać ukończona w ciągu 3 minut. Po potwierdzeniu synchronizacja będzie przebiegać asynchronicznie, a podkody kreskowe zostaną wyświetlone natychmiast po otrzymaniu. Proszę o cierpliwość.",
        Keywords: "Słowa kluczowe",
        SystemAutoUpdate: "Automatyczna aktualizacja systemu",
        IPAddress: "Adres IP",
        SearchKeywords: "Wyszukaj słowa kluczowe",
        LoginEmail: "Email logowania",
        SearchTime: "Czas wyszukiwania",
        NumbeOfTimes: "Liczba razy",
        MyBrowsing: "Moje przeglądanie",
        LoggingOuOfYouAccount: "Wylogowanie się z konta będzie traktowane jako dobrowolne zrzeczenie się i nie będziesz już mógł kontynuować korzystania z tego konta.",
        RequestLogout: "Złóż wniosek o wylogowanie",
        ProductsYet: "Nie przeglądałeś jeszcze żadnych produktów",
        clearall: "Wyczyść historię",
        CancellTip1: "Listy koszyków zakupowych, rekordy zakupu zamówień i rekordy ulubionych zostaną trwale usunięte i nie będą mogły być odzyskane.",
        CancellTip2: "Wszystkie informacje o koncie, rekordy usług, dane o konsumpcji itp., na platformie zostaną trwale usunięte i nie będą mogły być odzyskane.",
        CancellTip3: "Nie będziesz mógł się zalogować ani korzystać z tego konta AigoSmart.",
        CancellTip4: "Informacje osobiste i wiadomości historyczne (w tym awatar, pseudonim, moja waluta Aiguo, powiadomienia platformy, moje komentarze, itp.) związane z twoim kontem AigoSmart nie będą odzyskiwalne.",
        CancellTip5: "Musisz odpowiednio zarządzać funduszami na koncie (punkty, poziomy członkostwa, kwalifikacje, zamówienia, kupony, rekwizyty i inne cenne usługi) przed wylogowaniem, ponieważ anulowanie konta zostanie uznane za dobrowolne zrzeczenie się i nie będzie można go cofnąć. Rozumiesz i zgadzasz się, że AigoSmart nie może pomóc Ci w przywróceniu wymienionych powyżej usług.",
        SynchronizeSubBarcodes: "Synchronizuj podkody kreskowe",
        PleaseEnterKeywords: "Proszę wprowadzić słowa kluczowe",

        //V1.3.7 end

        //V1.3.8 start 
        HourlyStatistics: "Statystyki godzinowe",
        NumbeOfUsers: "Liczba użytkowników, którzy złożyli zamówienia",
        NumberOfCanceled: "Liczba anulowanych zamówień",
        RefundAmount: "Kwota zwrotu",
        UsersWhoHavePaid: "Użytkownicy, którzy zapłacili (zamówienia do wysyłki, wysłane, zakończone)",
        TotalNumber: "Łączna liczba zamówień anulowanych przez platformę",
        AmountOfCanceled: "Kwota anulowanych zamówień, które zostały opłacone, ale nie wysłane, kwota zwróconych zamówień, które zostały opłacone (w tranzycie, dostarczone)",
        RedemptionCode: "ID/Nazwa kuponu/Kod wymiany",
        DeleteAll: "Usuń",
        AccountIsUnderReview: "Bieżące konto jest w trakcie przeglądu dezaktywacji. Kontynuowanie logowania anuluje proces dezaktywacji. Czy chcesz kontynuować korzystanie z niego?",

        //V1.3.8 end



        //V1.3.9 start 

        TimeCannotBeEmpty: "Czas nie może być pusty",
        ProductImporTemplate: "Szablon importu produktów",
        NormalOrdersExceed: "Zamówienia normalne przekraczają:",
        OrderSautomaticallyClosed: "Nieopłacone zamówienia zostaną automatycznie zamknięte",
        OrdeIsShipped: "Po wysyłce zamówienia:",
        AutomaticallyComplete: "Automatyczne zakończenie zamówienia",
        Minutes: "Minuty",
        Days: "Dni",
        OrderSettings: "Ustawienia zamówienia",
        ClearCache: "Wyczyść pamięć podręczną",
        PleaseEnterNavigationContent: "Proszę wprowadzić treść nawigacji",
        WebsiteNavigationConfiguration: "Konfiguracja nawigacji na stronie",

        //V1.3.9 end

        //V1.4.0 start

        PopupDelayTime: "Opóźnienie wyskakującego okienka",
        SubscriptionPopup: "Wyskakujące okienko subskrypcji",
        MeansNoPopupDelay: "Wprowadź czas opóźnienia okna wyskakującego",

        //V1.4.0 end

        //V1.5.0 start
        UGCZone: "Obszar UGC",

        //V1.5.0 end
        //V1.6.0 start 
        uploaFilesInJpg: "Przesyłaj tylko pliki w formacie jpg/png o rozmiarze mniejszym niż 2 MB",
        SynchronizeEbpPrice: "Synchronizuj cenę EBP",
        ConfirmToSynchronizeEbp: "Potwierdź synchronizację ceny EBP",
        AsynchronousEbp: "Po potwierdzeniu synchronizacja jest asynchroniczna. Cena zostanie wyświetlona natychmiast po jej uzyskaniu. Należy cierpliwie poczekać.",
        CopywritingDisplay: "Wyświetlanie copywritingu",
        HomepageCopywriting: "Copywriting na stronie głównej",
        EditHomepageCopywriting: "Edytuj copywriting na stronie głównej",
        SuperimposedEvents: "Czy nakładać wydarzenia?",
        GiftsEvents: "(Prezenty z tego wydarzenia nie mogą być zbierane razem z innymi wydarzeniami)",
        SmallGiftEvent: "Wydarzenie z drobnym upominkiem",
        SpecialProductEvent: "Wydarzenie z produktem specjalnym",
        SpecialGiftEvent: "Darmowy prezent w ramach wydarzenia z drobnym upominkiem i z produktem specjalnym nie może łączony.",
        ProductsWithSKU: "Wybierz produkty z jednostką SKU",
        LimitWereSelected: "Obecny limit wynosi {limitNum} plików. Tym razem wybrano {selectNum} pliki.",
        FileUploadFailed: "Przesyłanie pliku nie powiodło się!",
        CompleteMultiLanguage: "Dokończ konfigurację wielojęzyczną",
        FirstSynchronizeSKU: "Proszę najpierw zsynchronizować SKU tego produktu.",

        //V1.6.0 end
    }
};

export default pl;