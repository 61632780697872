import httpV2 from '@/utils/httpV2';

const state = {
	
};
const getters = {};

const mutations = {};

const actions = {
	//获取List
	getVarAdVariant({ state, commit }, params) {
	    let res = httpV2.get2('/b2c/v1.0/variant/page/search', params, this);
	    return res;
	},
	getVarAdVariantId({ state, commit }, params) {
	    let res = httpV2.get2(`/b2c/v1.0/variant/${params.id}`, params, this);
	    return res;
	},
	//类目
	postVarAdSearchClassify({ state, commit }, params) {
	    let res = httpV2.post(`/b2c/v1.0/goods/classify/list/search`, params, this);
	    return res;
	},
	//新增弹窗参数组左
	getVarAdGroupleft({ state, commit }, params) {
	    let res = httpV2.get2(`/b2c/v1.0/parameter/group/list`, params, this);
	    return res;
	},
	//新增弹窗参数组右
	getVarAdGroupRight({ state, commit }, params) {
	    let res = httpV2.post(`/b2c/v1.0/goods/parameter/list`, params, this);
	    return res;
	},
	//新增弹窗参数组子集
	getVarAdGroupitem({ state, commit }, params) {
	    let res = httpV2.get(`/b2c/v1.0/parameter/item/list`, params, this);
	    return res;
	},
	//新增
	postVarAdput({ state, commit }, params) {
	    let res = httpV2.post(`/b2c/v1.0/variant`, params, this);
	    return res;
	},
	//变体表
	getVarAdvariantList({ state, commit }, params) {
	    let res = httpV2.get(`/b2c/v1.0/variant/parameter/variantId/${params.variantId}	`, params, this);
	    return res;
	},
	//自定义名称保存
	postVarAdVariantName({ state, commit }, params) {
		let variantParameterId=params[0].variantParameterId
	    let res = httpV2.post(`/b2c/v1.0/variant/parameter/translate/${variantParameterId}`, params, this);
	    return res;
	},
	//自定义名称get
	getVarAdVariantName({ state, commit }, params) {
	    let res = httpV2.get(`/b2c/v1.0/variant/parameter/translate/variantParameterId/${params.variantParameterId}`, params, this);
	    return res;
	},
	//
	getGoodsVariantList({ state, commit }, params) {
	    let res = httpV2.get(`/b2c/v1.0/goods/variant/${params.goodsVariantId}/goods/list`, params, this);
	    return res;
	},
	
		
};

export default {
    getters,
    state,
    mutations,
    actions
};
