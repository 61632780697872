import httpV2 from '@/utils/httpV2';

const state = {};
const getters = {};

const mutations = {};

const postSaleUrl = '/b2c/v1.0/post/sale';

const actions = {
    getPostSalePage({ state, commit }, params) {
        let promise = httpV2.get2(postSaleUrl + `/page/search`, params, this);
        return promise;
    },

    insertPostSale({ state, commit }, params) {
        let promise = httpV2.post(postSaleUrl, params, this);
        return promise;
    },

    checkPostSale({ state, commit }, params) {
        let promise = httpV2.put(postSaleUrl + `/check`, params, this);
        return promise;
    },
    deletePostSale({ state, commit }, params) {
        let promise = httpV2.delete(postSaleUrl + '/' + params.recomandId, params, this);
        return promise;
    },
    getPostSaleDetail({ state, commit }, params) {
        let promise = httpV2.get(postSaleUrl + `/detail`, params, this);
        return promise;
    },
    getPostSaleRecord({ state, commit }, params) {
        let promise = httpV2.get(postSaleUrl + `/record/postSaleId/${params.id}`, params, this);
        return promise;
    }
};

export default {
    getters,
    state,
    mutations,
    actions
};
